import React from 'react';
import './App.scss';
import { ThemeProvider } from '@material-ui/core';
import { dataManager } from '@ferrero/comon';
import firebase from 'firebase/app';
import { SnackProvider } from './hooks/useSnack';
import { ApiProvider } from './hooks/useApi';
import theme from './components/ui/theme';
import Router from './Router';
import 'firebase/firestore';
import 'firebase/auth';
import { ConfirmationDialogProvider } from './hooks/ConfirmDialog';
import { ErrorBoundary } from './components/ErrorBoundary';

require('dotenv').config();

const { DataProvider } = dataManager;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// eslint-disable-next-line no-extend-native, func-names
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
firebase.auth().languageCode = 'fr';
dataManager.firestore = firebase.firestore();
dataManager.firebaseAuth = firebase.auth();

/**
 * Front end application structutre
 * @returns {render} The entire app structure
 */
console.log('ttt');
const App = () => (
  <div className="App">
    <DataProvider>
      <ErrorBoundary>
        <ThemeProvider theme={ theme }>
          <SnackProvider>
            <ConfirmationDialogProvider>
              <ApiProvider>
                <Router />
              </ApiProvider>
            </ConfirmationDialogProvider>
          </SnackProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </DataProvider>
  </div>
);

export default App;

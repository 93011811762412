/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Fade, Button } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { format } from 'date-fns';
import TopMenu from '../components/TopMenu';
import './AdditionalHours.scss';
import DataList from '../components/ui/List';
import './Notifications.scss';

/**
 * AdditionalHours react component
 * @returns AdditionalHours react component
 */
function AdditionalHours() {
  const [show, setSow] = useState(false);
  const [filters, setFilters] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const {
    data: [notifications],
    setRead,
  } = dm.useNotifications();
  const {
    data: [users],
  } = dm.useUsers();

  useEffect(() => {
    const list = (notifications || []).map((curr) => ({
      id: curr.id,
      ref_user: `user/${curr.ref_user.id}`,
      body: curr.body,
      read: curr.read,
      user_name: `${
        users.find((elt) => elt.id === curr.ref_user.id)?.first_name
      } ${users.find((elt) => elt.id === curr.ref_user.id)?.last_name}`,
      date: curr.date,
      date_formatted: `${format(curr.date.toDate(), 'dd/MM/yyyy HH:mm:ss')}`,
    }));

    setDataList(list);
  }, [notifications, users]);

  useEffect(() => {
    setSow(true);
    return () => {
      setSow(false);
    };
  }, []);

  const onFilterChange = (f) => {
    setFilters(f);
  };

  useEffect(() => {
    if (filters != null) {
      const filtered = dataList.filter((elt) => {
        let isValid = true;
        if (filters.yes_no !== null && filters.yes_no !== 2) {
          isValid
            &= filters.yes_no === 1 ? elt.read === true : elt.read === false;
        }
        if (filters.date_start !== null) {
          filters.date_start.setHours(0);
          filters.date_start.setMinutes(0);
          isValid &= filters.date_start.getTime() <= elt.date.seconds * 1000;
        }
        if (filters.date_end !== null) {
          filters.date_end.setHours(23);
          filters.date_end.setMinutes(59);
          isValid &= filters.date_end.getTime() >= elt.date.seconds * 1000;
        }
        if (filters.employee !== null && filters.employee !== '') {
          isValid &= filters.employee === elt.ref_user;
        }
        return isValid;
      });
      setFilteredList(filtered);
    } else setFilteredList(dataList);
  }, [filters, dataList]);

  const handleRowClick = (rowData) => {
    const curr = filteredList.find((elt) => elt.id === rowData.id);
    if (!curr.read) setRead(curr);
  };

  const handleReadAll = () => {
    filteredList.filter(({ read }) => !read).map((n) => setRead(n));
  };

  return (
    <Fade in={ show }>
      <div className="page referential-page">
        <TopMenu
          title="Notifications"
          subtitle="Liste des evènement"
          yesnoTitle="Lues"
          onFilterChange={ onFilterChange }
        />
        <div className="page-content">
          <Button
            className="notification-reset-all"
            onClick={ handleReadAll }
            color="primary"
            variant="contained"
            disabled={ filteredList.filter(({ read }) => !read).length === 0 }
          >
            Marquer tout comme lu
          </Button>
          <DataList
            data={ filteredList }
            className="notification-list"
            getRowStyle={ ({ read }) => ({
              fontWeight: read ? 'unset' : 'bold',
            }) }
            onRowClick={ handleRowClick }
            columns={ [
              {
                title: 'Employé',
                key: 'user_name',
                style: { flex: '1', minWidth: 200 },
                dataType: 'user',
              },
              {
                title: 'Date',
                key: 'date_formatted',
                picto: 'icCalendar',
                style: { width: 250 },
              },
              {
                title: 'Text',
                key: 'body',
                picto: 'icLabel',
                style: { flex: 1, minWidth: 250 },
              },
            ] }
          />
        </div>
      </div>
    </Fade>
  );
}

AdditionalHours.propTypes = {};

export default AdditionalHours;

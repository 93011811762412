import React, { useEffect, useState } from 'react';
import { dataManager as dm } from '@ferrero/comon';
import './styles.scss';
import { Modal } from '@material-ui/core';
import { Button, DateField, Select } from '../../components/ui';
import Picto from '../../components/ui/Picto';
import UserSelect from '../../components/UserSelect';
import { useSnack } from '../../hooks/useSnack';

/**
 * NewHolidayButton react component
 * @returns NewHolidayButton react component
 */
function NewHolidayButton() {
  const { upsert } = dm.useHolidays();
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState('');
  const [reason, setReason] = useState('');
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const { addSnack } = useSnack();
  const {
    data: [params = {}],
  } = dm.useParams();

  useEffect(() => {
    setEmployee('');
    setReason('');
    setStart(new Date());
    setEnd(new Date());
  }, [open]);

  useEffect(() => {
    if (start > end) setEnd(start);
  }, [start]);

  useEffect(() => {
    if (start > end) setStart(end);
  }, [end]);

  /**
   * Insert new holiday in db
   */
  const save = async () => {
    const dataToSave = {
      date_request: new Date(),
      ts_deleted: null,
      accepted: null,
      date_start: new Date(start.setSeconds(0, 0)),
      date_end: new Date(end.setSeconds(0, 0)),
      reason,
      ref_user: `${employee}`,
    };
    await upsert(dataToSave.id, dataToSave);
    addSnack({
      message: 'demande de congé enregistrée',
      severity: 'success',
    });
    setOpen(false);
  };

  /**
   * Check if can save holiday
   * @returns {boolean} true when can save holiday, else false
   */
  const canSave = () => start && end && reason && employee;

  return (
    <>
      <Button color="primary" variant="contained" onClick={ () => setOpen(true) }>
        Nouveau
      </Button>
      <Modal open={ open } disableBackdropClick onClose={ () => setOpen(false) }>
        <div className="new-holiday-Container">
          <div className="editEvent-title">
            <h2>Ajouter un congé</h2>
            <div
              id="close-modal"
              role="button"
              tabIndex={ 0 }
              onClick={ () => setOpen(false) }
            >
              <Picto size={ 16 } type="icClose" />
            </div>
          </div>
          <div className="new-holiday-content">
            <UserSelect
              label="Employé *"
              value={ employee }
              variant="filled"
              onChange={ setEmployee }
            />
            <Select
              variant="filled"
              label="Motif *"
              onChange={ setReason }
              options={ (params?.vacancy?.leave_reason || []).map((r) => ({
                value: r.label,
                label: r.label,
              })) }
            />
            <div className="new-holiday-row">
              <DateField
                variant="filled"
                label="Date débu *"
                value={ start }
                onChange={ setStart }
                disablePast
              />
              <DateField
                variant="filled"
                label="Date fin *"
                value={ end }
                onChange={ setEnd }
                disablePast
              />
            </div>
            <span className="new-holiday-actions">
              <Button
                color="default"
                variant="contained"
                onClick={ () => setOpen(false) }
              >
                Annuler
              </Button>
              <Button
                color="primary"
                disabled={ !canSave() }
                variant="contained"
                onClick={ save }
              >
                Enregistrer
              </Button>
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
}

NewHolidayButton.propTypes = {};

export default NewHolidayButton;

import React from 'react';
import PropTypes from 'prop-types';
import { constants } from '@ferrero/comon';
import format from 'date-fns/format';
import './styles.scss';
import { Tooltip } from '@material-ui/core';

/**
 * Retrieves the current event status
 * @param {object} event current event 
 * @returns {HOLIDAY_STATUS} event holiday status
 */
const eventStatus = (event) => {
  if (event?.job) return constants.JOB_STATUS[event?.job?.status || 0];
  if (event?.holiday) {
    return constants.HOLIDAY_STATUS[event?.holiday?.accepted ?? -1];
  }
  return {};
};

/**
 * React MonthEvent component 
 * @param {object} event the event 
 * @returns {render} MonthEvent react component
 */
export function MonthEvent({ event }) {
  const status = eventStatus(event);

  const eventContent = (
    // <div className="calendar-month-event">
    <>
      <span
        className="calendar-month-event-bullet"
        style={ { backgroundColor: status.color } }
      />

      <span
        className="calendar-month-event-label"
        style={ { color: event.job ? event.user.color : 'white' } }
      >
        { `${event.job ? format(event.start, 'HH:mm') : ''} - ${
          event?.user?.first_name || ''
        } ${event?.user?.last_name || ''} ${event?.job?.zipcode_end || ''} ${
          event?.job?.city_end || ''
        } ${event.title}` }
      </span>
    </>
  );

  return (
    <Tooltip
      title={ eventContent }
      placement="top"
      className="calendar-month-event-tooltip"
    >
      <span className="calendar-month-event" style={ { color: event.color } }>
        { eventContent }
      </span>
    </Tooltip>
  );
}

MonthEvent.propTypes = {
  event: PropTypes.object,
};

/**
 * React WeekEvent component 
 * @param {object} event the event 
 * @returns {render} WeekEvent react component
 */
export function WeekEvent({ event }) {
  return (
    <span className="calendar-week-event">
      <strong>{ event.title }</strong>
      { event.desc && `:  ${event.desc}` }
    </span>
  );
}

WeekEvent.propTypes = {
  event: PropTypes.object,
};

/**
 * React defaultEvent component 
 * @param {object} event the event 
 * @returns {render} defaultEvent react component
 */
export const defaultEvent = ({ event }) => {
  const status = eventStatus(event);

  const { start, end } = event;

  const timeLabel = event.job
    ? `${format(start, 'HH:mm')}-${format(end, 'HH:mm')}`
    : '';
  const eventContent = (
    <div>
      <div className="calendar-default-event-header">
        <span>
          <span
            className="calendar-default-event-status"
            style={ { backgroundColor: status.color } }
          />
          { ` ${timeLabel} ${event?.user?.first_name || ''} ${
            event?.user?.last_name || ''
          }\n${event?.job?.zipcode_end || ''} ${event?.job?.city_end || ''}` }
        </span>
      </div>
      { event?.title }
      { event?.desc && `:  ${event?.desc}` }
    </div>
  );
  return (
    <Tooltip
      title={ eventContent }
      placement="top"
      className="calendar-default-event-tooltip"
    >
      <span className="calendar-default-event">{ eventContent }</span>
    </Tooltip>
  );
};

export default {
  month: MonthEvent,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { constants } from '@ferrero/comon';
import Typography from '@material-ui/core/Typography';
import './styles.scss';


/**
 * React StatusRow component 
 * @param {object} param component mandatory parameters
 * @returns {render} StatusRow react component
 */
const StatusRow = ({ status, onClick, selected }) => (
  <div
    className="calendar-userList-item"
    role="button"
    tabIndex={ 0 }
    onClick={ () => onClick(status) }
  >
    <div
      className="calendar-userList-color"
      style={ {
        backgroundColor: selected ? status.color : 'white',
        borderColor: status.color,
      } }
    />
    { `${status.label}` }
  </div>
);

StatusRow.propTypes = {
  status: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};


/**
 * React StatusSelect component 
 * @param {function} onChange callback to call when status changes 
 * @returns {render} StatusSelect react component
 */
function StatusSelect({ onChange }) {
  const data = Object.entries(constants.JOB_STATUS).map(([key, value]) => ({ ...value, id: parseInt(key, 10) }));
  const [selected, setSelecetd] = useState(data.map((s) => s.id));
  const handleClick = (s) => {
    const newValue = selected.find((i) => i === s.id) !== undefined
      ? selected.filter((i) => i !== s.id)
      : [...selected, s.id];

    setSelecetd(newValue);
    onChange(newValue);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Statut
      </Typography>
      <div className="calendar-userList-container">
        { data.map((s) => (
          <StatusRow
            key={ s.id }
            status={ s }
            onClick={ handleClick }
            selected={ selected.find((i) => i === s.id) !== undefined }
          />
        )) }
      </div>
    </>
  );
}

StatusSelect.propTypes = {
  onChange: PropTypes.func,
};

export default StatusSelect;

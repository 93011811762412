import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Picto from '../../Picto';
import colors from '../../../styles/colors.scss';

/**
 * User react component
 * @param {blob} cellData current table cell data 
 * @param {object} row table row 
 * @returns User react component
 */
function User(cellData, row) {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  return (
    <Link
      to={ `accounts/${row.ref_user ? row.ref_user.split('/')[1] : row.id}` }
      style={ { display: 'flex', alignItems: 'center', flexDirection: 'row' } }
      onClick={ (e) => e.stopPropagation() }
    >
      <Picto
        size={ isMobile ? 15 : 30 }
        type="icUser"
        fill={ colors.textGreyColor }
        style={ { marginRight: 10 } }
      />
      <p className="noselect">{ cellData }</p>
    </Link>
  );
}
export default User;

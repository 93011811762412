/**
 * Safely call a function
 * @param {function} fn the function to execute 
 * @param {blob} defaultValue returned default value 
 * @returns {blob} function result or default value passed as parameter
 */
export function getSafe(fn, defaultValue) {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
}

/**
 * Debounce a function call with a timer
 * @param {function} func the function to execute 
 * @param {integer} wait time to wait between every call 
 * @param {boolean} immediate if sets to true doesn wait  
 * @returns {blob} function call
 */
export const debounce = (func, wait, immediate) => {
  let timeout = null;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !(timeout === null);
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

export default getSafe;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _useLoadingValues2 = _interopRequireDefault(require("../hooks/useLoadingValues"));

var _index = _interopRequireDefault(require("../index"));

var _authProvider = _interopRequireDefault(require("./authProvider"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Provides allowed lessors data from firebase
 */
var _default = function _default(_ref) {
  var user = _ref.user;
  if (!_index["default"].firestore) return [[], true];

  var _useLoadingValues = (0, _useLoadingValues2["default"])(),
      error = _useLoadingValues.error,
      loading = _useLoadingValues.loading,
      setError = _useLoadingValues.setError,
      setValue = _useLoadingValues.setValue,
      value = _useLoadingValues.value;

  var ref = (0, _react.useRef)(_index["default"].firestore.collection('lessor'));

  var _AuthProvider = (0, _authProvider["default"])(),
      initializing = _AuthProvider.initializing;

  (0, _react.useEffect)(function () {
    if (initializing || !ref.current) {
      setValue(undefined);
      return null;
    }

    var listener = ref.current.onSnapshot(function (querySnapshot) {
      var values = querySnapshot.docs.map(function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data ? doc.data() : doc);
      });
      setValue(values);
    }, setError);
    return function () {
      listener();
    };
  }, [ref.current, user, initializing]);
  var resArray = [value, loading, error];
  return (0, _react.useMemo)(function () {
    return resArray;
  }, resArray);
};

exports["default"] = _default;
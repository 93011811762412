import { dataManager as dm } from '@ferrero/comon';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import { useNotification } from './useNotification';
import { useConfirmationDialog } from './ConfirmDialog';
import { useSnack } from './useSnack';

/**
 * Hook to manage holidays
 * @returns {object} holidays data and functions
 */
export const useHolidays = () => {
  const comonHook = dm.useHolidays();
  const { sendNotification } = useNotification();
  const { getConfirmation } = useConfirmationDialog();
  const { addSnack } = useSnack();
  const {
    data: [jobs],
  } = dm.useJobs();
  const {
    data: [users],
    upsert: upsertUser,
  } = dm.useUsers();

  /**
   * Delete an holiday
   * @param {object} data holiday data
   * @returns {object} update result
   */
  const deleteHoliday = async (data) => {
    const holiday = comonHook.data[0].find((h) => h.id === data.id);
    const userId = holiday.userId || holiday.ref_user.id;
    if (holiday.effective_days_count) {
      const user = users.find((u) => u.id === userId);

      upsertUser(userId, {
        days_of_leave_taken:
          (user.days_of_leave_taken ?? 0) - (holiday.effective_days_count ?? 0),
      });
    }
    await comonHook.upsert(holiday.id, { ts_deleted: Date.now() });
  };

  /**
   * Validate a holiday
   * @param {object} holiday current holiday data
   * @param {boolean} isAccepted true when accepted, else false
   * @param {string} refuseReason when not accepted, the refuse reason
   * @param {integer} daysTaken number of days taken as holiday
   * @returns {object} update result
   */
  const acceptHoliday = async (
    holiday,
    isAccepted,
    refuseReason,
    daysTaken,
  ) => {
    try {
      const userId = holiday.userId || holiday.ref_user.id;
      const start = startOfDay(holiday.date_start.toDate());
      const end = endOfDay(holiday.date_end.toDate());
      const unassignJob = jobs.filter((j) => {
        if (j?.ref_user?.id !== userId) return false;
        if (j.date_start.toDate() > end) return false;
        if (j.date_end.toDate() < start) return false;
        return true;
      });
      if (isAccepted && unassignJob.length) {
        const messages = {
          1: '1 mission ne sera plus assignée, vous devrez la réassigner à un autre ouvrier\nValider ces congés ?',
          // eslint-disable-next-line max-len
          n: `${unassignJob.length} missions ne seront plus assignées, vous devrez les réassigner à un autre ouvrier\nValider ces congés ?`,
        };
        const confirmed = await getConfirmation({
          title: 'Missions Désassignées',
          message: messages[unassignJob.length] || messages.n,
        });
        if (!confirmed) return false;
      }
      const user = users.find((u) => u.id === userId);
      upsertUser(userId, {
        days_of_leave_taken: (user.days_of_leave_taken ?? 0) + (daysTaken ?? 0),
      });
      const formatedData = {
        accepted: isAccepted,
        refuse_reason: isAccepted ? null : refuseReason,
        accepted_date: new Date(),
        effective_days_count: daysTaken,
      };
      await comonHook.upsert(holiday.id, formatedData);
      await Promise.all(
        unassignJob.map((j) => dm.firestore.collection('event').doc(j.id).update({ ref_user: null })),
      );
      await sendNotification(
        { userId },
        `votre demande de congé du ${format(start, 'dd/MM/yyyy')} au ${format(
          end,
          'dd/MM/yyyy',
        )} a été ${isAccepted ? 'accéptée' : 'refusée'}`,
      );
      addSnack({
        message: `congé ${isAccepted ? 'accepté' : 'refusé'}`,
        severity: 'success',
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      addSnack({
        message: 'erreur',
        severity: 'error',
      });
      return false;
    }
  };
  return {
    ...comonHook,
    acceptHoliday,
    deleteHoliday,
  };
};

export default useHolidays;

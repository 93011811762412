import { format } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';


/**
 * DateField react component
 * @param {object} DateField parameters
 * @returns DateField react component
 */
function DateField({ variant, ...props }) {
  /**
   * Format a date for display
   * @param {date} d date to get display label 
   * @returns {string} formatted date
   */
  const displayDateLabel = (d) => {
    if (d != null) {
      try {
        return format(d, 'dd/MM/yyyy');
      } catch (e) {
        return d;
      }
    } else return '';
  };

  return (
    <div className="container">
      <MuiPickersUtilsProvider
        locale={ fr }
        utils={ DateFnsUtils }
      >
        <KeyboardDatePicker
          format="dd/mm/yyyy"
          size="small"
          autoOk
          labelFunc={ displayDateLabel }
          inputVariant={ variant }
          label="Date picker inline"
          { ...props }
          variant="inline"
          KeyboardButtonProps={ {
            'aria-label': 'change date',
          } }
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

DateField.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
};
DateField.defaultProps = {
  color: 'secondary',
  variant: 'filled',
};
export default DateField;

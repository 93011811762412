import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Close from '@material-ui/icons/Close';
import { InputAdornment, IconButton } from '@material-ui/core';
import TextField from './TextField';

/**
 * Select react component
 * @param {object} Select parameters
 * @returns Select react component
 */
function Select({ options, ...other }) {
  return (
    <TextField
      InputProps={ {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="start"
              size="small"
              style={ { marginRight: 20 } }
              onClick={ () => other.onChange('') }
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      } }
      { ...other }
      select
    >
      { options.map((option) => (
        <MenuItem disabled={ option.disabled } key={ option.value } value={ option.value }>
          { option.label }
        </MenuItem>
      )) }
    </TextField>
  );
}

Select.propTypes = {
  options: PropTypes.array,
};

export default Select;

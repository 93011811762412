import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import renderer from './Renderer';
import Picto from '../Picto';
import colors from '../../styles/colors.scss';

/**
 * ListRow react component
 * @param {object} ListRow parameters
 * @returns ListRow react component
 */
function ListRow({
  data, columns, renderDetail, getStyle, onClick,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const defaultRenderCell = (cellData, row, column) => (
    <>
      { column.picto && (
        <Picto
          size={ isMobile ? 15 : 30 }
          type={ column.picto }
          fill={ colors.textGreyColor }
          className="list-row-element-picto"
        />
      ) }
      <p key={ column.title } className="noselect">
        { cellData }
      </p>
    </>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={ classNames('list-row-container', {
        'list-row-container-clickable': !!onClick,
      }) }
      onClick={ () => onClick && onClick(data) }
    >
      <div className="list-row-content" style={ getStyle && getStyle(data) }>
        { columns.map((col) => {
          const render = col.render || renderer[col.dataType] || defaultRenderCell;
          return (
            <div key={ col.title } className="list-row-element" style={ col.style }>
              <span style={ { marginRight: 5 } }>
                { isMobile && `${col.title} : ` }
              </span>
              { render(data[col.key], data, col) }
            </div>
          );
        }) }
      </div>
      { renderDetail && renderDetail(data) }
    </div>
  );
}

ListRow.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  renderDetail: PropTypes.func,
  getStyle: PropTypes.func,
  onClick: PropTypes.func,
};

export default ListRow;

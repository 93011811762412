import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FaInfoCircle } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import './TextField.scss';
import colors from '../styles/colors.scss';

/**
 * InfoIcon react component
 * @param {props} InfoIcon props
 * @returns InfoIcon react component
 */
function InfoIcon(props) {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <HtmlTooltip { ...props }>
      <span id="info-icon">
        <FaInfoCircle fill={ colors.white } />
      </span>
    </HtmlTooltip>
  );
}

export default InfoIcon;

/* eslint-disable spaced-comment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-bitwise */
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { dataManager as dm, constants } from '@ferrero/comon';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash.get';
import JobForm from './JobForm';
import MediaManagment from './MediaManagment';
import FurnitureManagment from './FurnitureManagment';
import { useSnack } from '../../hooks/useSnack';
import AddImage from '../AddImage';
import icPDF from '../../assets/ic_pdf.svg';
import icMediaNotFound from '../../assets/ic_media_not_found.svg';
import Picto from '../ui/Picto';
import colors from '../styles/colors.scss';
import { Button } from '../ui';
import { config } from '../../config';
import { useApi } from '../../hooks/useApi';
import Quitus from './Quitus';
import QuitusButton from '../Quitus';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    minHeight: '35px',
    height: '35px',
  },
}));

/**
 * TabPanel react component
 * @param {props} props react props for TabPanel react component
 * @returns TabPanel react component
 */
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `simple-tabpanel-${index}` }
      aria-labelledby={ `simple-tab-${index}` }
      { ...other }
    >
      { value === index && <Box p={ 3 }>{ children }</Box> }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  index: PropTypes.number,
  value: PropTypes.number,
};

/**
 * Read a media file data
 * @param {file} file media file to read
 * @returns {promise} promise containing the file data as url
 */
const readMediaFile = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result);
  };
  reader.readAsDataURL(file);
});

/**
 * React EditJob component
 * @returns {render} EditJob react component
 */
const EditJob = React.forwardRef(({ job, onClose }, ref) => {
  /* =====================
    STATE GETTERS / SETTERS
  ======================== */
  const [tabIndex, setTabIndex] = useState(0);
  const [eventData, setEventData] = useState(job);
  const [isFirstFormatted, setIsFirstFormatted] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [mediaSelected, setMediaSelected] = useState(null);
  const { addSnack } = useSnack();
  const { upsert, getNewId } = dm.useJobs();
  const {
    data: [users],
  } = dm.useUsers();
  const { uploadWithProgress } = useApi();
  const classes = useStyles();

  /* =====================
      FORMAT EVENT KEYS
  ======================== */

  useLayoutEffect(() => {
    formatData(job);
  }, [job]);

  /**
   * Format an event
   * @param {object} ev the current event object
   */
  const formatData = (ev) => {
    const formatedData = {
      interventionAddress: {
        display_name: _get(ev, 'address_end'),
        address: {
          postcode: _get(ev, 'zipcode_end'),
          municipality: _get(ev, 'city_end'),
        },
      },
      startAddress: {
        display_name: _get(ev, 'address_start'),
        address: {
          postcode: _get(ev, 'zipcode_start'),
          municipality: _get(ev, 'city_start'),
        },
      },
      interventionMunicipality: _get(ev, 'city_end'),
      startMunicipality: _get(ev, 'city_start'),
      endDate: _get(ev, 'date_end'),
      startDate: _get(ev, 'date_start'),
      description: _get(ev, 'description'),
      id: _get(ev, 'id'),
      interventionId: _get(ev, 'intervention_no'),
      workOrderId: _get(ev, 'lessor_document_no'),
      occupantName: _get(ev, 'occupant_name'),
      occupantPhone: _get(ev, 'occupant_phone'),
      lessor:
        _get(ev, 'ref_lessor.id') && `lessor/${_get(ev, 'ref_lessor.id')}`,
      assigned: _get(ev, 'ref_user.id') && `user/${_get(ev, 'ref_user.id')}`,
      report: _get(ev, 'report_detail'),
      title: _get(ev, 'title') || 'Dépannage',
      ts_deleted: null,
      interventionPostcode: _get(ev, 'zipcode_end'),
      startPostcode: _get(ev, 'zipcode_start'),
      latitude: _get(ev, 'latitude'),
      longitude: _get(ev, 'longitude'),
      medias: _get(ev, 'medias'),
      furnitures: _get(ev, 'furnitures') || [],
      status: _get(ev, 'status') || 0,
      program: _get(ev, 'program'),
      tranche: _get(ev, 'tranche'),
      ensemble: _get(ev, 'ensemble'),
      escalier: _get(ev, 'escalier'),
      logement: _get(ev, 'logement'),
      etage: _get(ev, 'etage'),
      module: _get(ev, 'module'),
      resolved: _get(ev, 'resolved'),
      quotationDetail: _get(ev, 'quotation_detail'),
    };
    if (formatedData.medias) {
      formatedData.medias.forEach((media) => {
        if (typeof media.ts_inserted === 'object') {
          try {
            const secs = media.ts_inserted.seconds * 1000;
            const dt = new Date(secs).getTime();
            media.ts_inserted = dt;
          } catch (e) {
            media.ts_inserted = new Date().getTime();
          }
        }
      });
    }

    setIsFirstFormatted(true);
    setEventData(formatedData);
  };

  /* =====================
        TABS MANAGMENT
  ======================== */
  /**
   * Change tab index when tab change
   * @param {*} _ empty
   * @param {integer} newValue index value of new tab
   */
  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  /**
   * Change tab index when tab change
   * @param {integer} index index value of new tab
   */
  const handleChangeIndex = (index) => {
    setTabIndex(index);
  };

  /* =====================
            SAVE
  ======================== */

  /**
   * Check if the event form is correctly filled
   * @returns {boolea} true when every mandatory fields are completed, else false
   */
  const isMandatoryCompleted = () => {
    let isCompleted = true;

    isCompleted
      &= _get(eventData, 'startDate') !== undefined
      && _get(eventData, 'startDate') !== '';
    isCompleted
      &= _get(eventData, 'assigned') !== undefined
      && _get(eventData, 'assigned') !== '';
    isCompleted
      &= _get(eventData, 'description') !== undefined
      && _get(eventData, 'description') !== '';
    isCompleted
      &= _get(eventData, 'interventionAddress.display_name') !== undefined
      && _get(eventData, 'interventionAddress.display_name') !== '';
    isCompleted
      &= _get(eventData, 'interventionMunicipality') !== undefined
      && _get(eventData, 'interventionMunicipality') !== '';
    isCompleted
      &= _get(eventData, 'interventionPostcode') !== undefined
      && _get(eventData, 'interventionPostcode') !== '';
    isCompleted
      &= _get(eventData, 'occupantName') !== undefined
      && _get(eventData, 'occupantName') !== '';
    isCompleted
      &= _get(eventData, 'occupantPhone') !== undefined
      && _get(eventData, 'occupantPhone') !== '';

    return isCompleted;
  };

  /**
   * Check if the event dates are valid
   * @returns {boolean} true when the dates are valid
   */
  const isDateValid = () => _get(eventData, 'startDate') === undefined
    || new Date(_get(eventData, 'startDate')).getTime()
      < new Date(_get(eventData, 'endDate')).getTime();

  /**
   * Check if can save form
   * @returns {boolean} true when can save form
   */
  const canSave = () => isMandatoryCompleted() && isDateValid();

  /**
   * Save event form in DB
   * @returns {callback} callback function call
   */
  const save = async () => {
    if (!canSave()) {
      return addSnack({
        message: 'Veuillez remplir les champs obligatoires.',
        severity: 'error',
      });
    }

    if (
      !_get(eventData, 'latitude')
      || !_get(eventData, 'latitude') === ''
      || !_get(eventData, 'longitude')
      || !_get(eventData, 'longitude') === ''
    ) {
      addSnack({
        message: 'Attention, les coordonnées GPS ne sont pas saisies.',
        severity: 'warning',
      });
    }
    setIsSaveModalOpen(true);
    try {
      const newMedias = [
        ...(eventData.medias || []).filter((m) => !m.file_path),
      ];
      console.log('newMedias', newMedias);
      await Promise.all(
        newMedias.map((m) => uploadWithProgress('media', m, null)),
      )
        .then((res) => {
          console.log(res);
          return res.forEach((insertedFilepath, index) => {
            newMedias[index].file_path = insertedFilepath;
            newMedias[index].id = uuidv4();
          });
        })
        .catch(() => addSnack({
          message: "Impossible d'enregistrer.",
          severity: 'error',
        }));
      const allMedia = eventData.medias || [];
      const formatedData = {
        address_end:
          _get(eventData, 'interventionAddress.display_name') || null,
        address_start: _get(eventData, 'startAddress.display_name') || null,
        city_end: _get(eventData, 'interventionMunicipality') || null,
        city_start: _get(eventData, 'startMunicipality') || null,
        date_end: _get(eventData, 'endDate') || null,
        date_start: _get(eventData, 'startDate') || null,
        description: _get(eventData, 'description') || null,
        intervention_no:
          _get(eventData, 'interventionId') || getNewId() || null,
        lessor_document_no: _get(eventData, 'workOrderId') || null,
        occupant_name: _get(eventData, 'occupantName') || null,
        occupant_phone: _get(eventData, 'occupantPhone') || null,
        ref_lessor: _get(eventData, 'lessor') || null,
        ref_user: _get(eventData, 'assigned') || null,
        report_detail: _get(eventData, 'report') || null,
        title: _get(eventData, 'title') || null,
        ts_deleted: null || null,
        zipcode_end: _get(eventData, 'interventionPostcode') || null,
        zipcode_start: _get(eventData, 'startPostcode') || null,
        latitude: _get(eventData, 'latitude') || null,
        longitude: _get(eventData, 'longitude') || null,
        furnitures: _get(eventData, 'furnitures') || null,
        program: _get(eventData, 'program') || null,
        tranche: _get(eventData, 'tranche') || null,
        ensemble: _get(eventData, 'ensemble') || null,
        escalier: _get(eventData, 'escalier') || null,
        logement: _get(eventData, 'logement') || null,
        etage: _get(eventData, 'etage') || null,
        module: _get(eventData, 'module') || null,
        status: _get(eventData, 'status') || 0,
        quotation_detail: _get(eventData, 'quotationDetail') || null,
        resolved: _get(eventData, 'resolved') || null,
        medias: allMedia.map((media) => ({
          id: media.id || null,
          extension: media.extension || null,
          file_path: media.file_path || null,
          gps: media.gps || null,
          is_scan: media.is_scan || null,
          is_signature: media.is_signature || null,
          is_quotation: media.is_quotation || null,
          name: media.name || null,
          size: media.size || null,
          ts_deleted: media.ts_deleted || null,
          ts_inserted: media.ts_inserted || null,
        })),
      };
      await upsert(job.id, formatedData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      addSnack({
        message: "Impossible d'enregistrer.",
        severity: 'error',
      });
      return setIsSaveModalOpen(false);
    }
    setIsSaveModalOpen(false);
    return onClose();
  };

  /**
   * Delete current event from db
   */
  const handleDeleteEvent = () => {
    if (job.id !== undefined) {
      const formatedData = {
        address_end: _get(eventData, 'interventionAddress.display_name'),
        address_start: _get(eventData, 'startAddress.display_name'),
        city_end: _get(eventData, 'interventionMunicipality'),
        city_start: _get(eventData, 'startMunicipality'),
        date_end: _get(eventData, 'endDate'),
        date_start: _get(eventData, 'startDate'),
        description: _get(eventData, 'description'),
        id: _get(eventData, 'id'),
        intervention_no: _get(eventData, 'interventionId'),
        lessor_document_no: _get(eventData, 'workOrderId'),
        occupant_name: _get(eventData, 'occupantName'),
        occupant_phone: _get(eventData, 'occupantPhone'),
        ref_lessor: _get(eventData, 'lessor'),
        ref_user: _get(eventData, 'assigned'),
        report_detail: _get(eventData, 'report'),
        title: _get(eventData, 'title'),
        ts_deleted: new Date().getTime(),
        zipcode_end: _get(eventData, 'interventionPostcode'),
        zipcode_start: _get(eventData, 'startPostcode'),
        latitude: _get(eventData, 'latitude'),
        longitude: _get(eventData, 'longitude'),
      };
      upsert(job.id, formatedData);
    }
    onClose();
  };

  /* =====================
            MEDIA
  ======================== */

  /**
   * Add media to the current event
   * @param {array} newImages images to save
   */
  const handleAddMedia = async (newImages) => {
    let eventMedias = eventData.medias;
    if (eventMedias === undefined) {
      eventMedias = [];
    }
    for (let i = 0; i < newImages.length; i++) {
      newImages[i].internalId = uuidv4();
      newImages[i].file_path = null;
      // eslint-disable-next-line prefer-destructuring
      newImages[i].extension = newImages[i].type.split('/')[1];
      if (
        newImages[i].extension.toLowerCase() === 'pdf'
        && eventData.medias !== undefined
      ) {
        for (let j = 0; j < eventData.medias.length; j++) {
          if (eventData.medias[j].extension.toLowerCase() === 'pdf') {
            eventData.medias.splice(j, 1);
          }
        }
      }
      newImages[i].gps = null;
      newImages[i].is_scan = false;
      newImages[i].ts_inserted = new Date().getTime();
      newImages[i].source = await readMediaFile(newImages[i]);
      eventMedias.push(newImages[i]);
    }
    setEventData((currentEventData) => ({
      ...currentEventData,
      medias: eventMedias,
    }));
  };

  /**
   * Delete event medias
   * @param {array} mediaToDelete list of medias to delete
   */
  const deleteEventMedia = (mediaToDelete) => {
    const eventWithMedias = eventData;
    console.log('del', mediaToDelete);
    for (let i = 0; i < eventWithMedias.medias.length; i++) {
      if (
        mediaToDelete.file_path !== null
        && mediaToDelete.file_path === eventWithMedias.medias[i].file_path
      ) {
        eventWithMedias.medias[i].ts_deleted = new Date().getTime();
        break;
      } else if (
        mediaToDelete.internalId
        && mediaToDelete.internalId === eventWithMedias.medias[i].internalId
      ) {
        eventWithMedias.medias.splice(i, 1);
        break;
      }
    }
    setEventData({ ...eventWithMedias });
  };

  /**
   * Close the media modal
   */
  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
    setMediaSelected(null);
  };

  /**
   * Show the media modal
   * @param {object} mediaToShow the media to display
   */
  const showMediaMoal = async (mediaToShow) => {
    if (mediaToShow.extension.toLowerCase() === 'pdf') {
      if (!mediaToShow.source) {
        const newWindow = window.open(
          `${config.baseURLApi}/media/${mediaToShow.file_path}`,
          '_blank',
          'noopener,noreferrer',
        );
        if (newWindow) newWindow.opener = null;
      } else {
        const a = document.createElement('a');
        a.href = `data:application/octet-stream;base64,${
          mediaToShow.source.split('data:application/pdf;base64,')[1]
        }`;
        a.download = mediaToShow.name;
        a.click();
      }
    } else {
      setIsMediaModalOpen(true);
      setMediaSelected(mediaToShow);
    }
  };

  /**
   * Find media file src
   * @param {object} media the media to use
   * @returns {string} media path
   */
  const getMediaFileSrc = (media) => {
    if (media?.extension?.toLowerCase() === 'pdf') return icPDF;
    if (media?.file_path) {
      return `${config.baseURLApi}/media/${media?.file_path}`;
    }
    return media?.source;
  };

  /**
   * Add furnitures to an event
   * @param {array} furnitures
   */
  const setEventFurnitures = (furnitures) => {
    setEventData({ ...eventData, furnitures });
  };

  /**
   * Updates quitus data to the event
   * @param {object} data event data with quitus
   */
  const setQuitusData = (data) => {
    setEventData(data);
  };

  /**
   * Add furnitures to the event
   */
  const addFurniture = () => {
    setEventData({
      ...eventData,
      furnitures: [...(eventData.furnitures || []), {}],
    });
  };

  /* =====================
            RENDER
  ======================== */
  const status = constants.JOB_STATUS[eventData.status || 0];
  return (
    <div className="editEvent-Container" ref={ ref }>
      { isMediaModalOpen && (
        <Dialog className="image-dialog" open onClick={ closeMediaModal }>
          <div className="overlay" role="button" tabIndex={ 0 }>
            <Picto size={ 22 } type="icClose" fill={ colors.red } />
          </div>
          <img
            className="image-full-size"
            src={ getMediaFileSrc(mediaSelected) }
            alt="no media"
            onError={ (e) => {
              e.target.onerror = null;
              e.target.src = icMediaNotFound;
            } }
          />
        </Dialog>
      ) }
      { isSaveModalOpen && (
        <Dialog className="save-dialog" open>
          <div>Enregistrement en cours ...</div>
        </Dialog>
      ) }
      <div className="editEvent-title">
        <h2>
          { job.id !== undefined
            ? 'Modification intervention'
            : 'Création intervention' }
        </h2>
        { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions  */ }
        <div id="close-modal" onClick={ onClose }>
          <Picto size={ 16 } type="icClose" />
        </div>
      </div>

      <span
        className="editEvent-state"
        style={ {
          backgroundColor: status.color,
        } }
      >
        { status.label }
      </span>

      <div className={ classes.root }>
        <AppBar
          position="static"
          color="primary"
          className="event-tabs"
          classes={ {
            root: classes.tabsRoot,
          } }
        >
          <Tabs
            value={ tabIndex }
            onChange={ handleChange }
            classes={ {
              root: classes.tabsRoot,
            } }
          >
            <Tab
              label="Détail intervention"
              classes={ {
                root: classes.tabsRoot,
              } }
            />
            <Tab
              label="Médias"
              classes={ {
                root: classes.tabsRoot,
              } }
            />
            <Tab
              label="Fournitures"
              classes={ {
                root: classes.tabsRoot,
              } }
            />
            <Tab
              label="Quitus"
              disabled={ eventData.status !== 2 }
              classes={ {
                root: classes.tabsRoot,
              } }
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis="x"
          index={ tabIndex }
          onChangeIndex={ handleChangeIndex }
        >
          <TabPanel value={ tabIndex } index={ 0 }>
            <JobForm
              values={ isFirstFormatted ? eventData : formatData(eventData) }
              onChange={ setEventData }
            />
          </TabPanel>
          <TabPanel value={ tabIndex } index={ 1 }>
            <MediaManagment
              medias={ eventData.medias }
              onChange={ deleteEventMedia }
              showFullSize={ showMediaMoal }
            />
          </TabPanel>
          <TabPanel value={ tabIndex } index={ 2 }>
            <FurnitureManagment
              furnitures={ eventData.furnitures }
              onChange={ setEventFurnitures }
            />
          </TabPanel>
          <TabPanel value={ tabIndex } index={ 3 }>
            <Quitus
              values={ isFirstFormatted ? eventData : formatData(eventData) }
              onChange={ setQuitusData }
              showFullSize={ showMediaMoal }
            />
          </TabPanel>
        </SwipeableViews>
      </div>
      <div className="editEvent-button-row">
        { tabIndex === 0 && (
          <Button
            style={ {
              backgroundColor:
                eventData.status === 2 ? colors.grey : colors.red,
              color: colors.white,
              height: 35,
            } }
            disabled={ eventData.status === 2 }
            variant="contained"
            onClick={ () => handleDeleteEvent() }
          >
            Supprimer
          </Button>
        ) }
        { tabIndex === 1 && <AddImage onAddImages={ handleAddMedia } /> }
        { tabIndex === 2 && (
          <Button color="primary" variant="contained" onClick={ addFurniture }>
            Ajouter
          </Button>
        ) }
        { tabIndex === 3 && eventData.status === 2 && (
          <QuitusButton data={ eventData } users={ users } />
        ) }
        <span className="actions">
          <Button color="default" variant="contained" onClick={ onClose }>
            Annuler
          </Button>
          <Button
            color="primary"
            disabled={ !canSave() }
            variant="contained"
            onClick={ save }
          >
            Enregistrer
          </Button>
        </span>
      </div>
    </div>
  );
});

EditJob.propTypes = {
  job: PropTypes.object,
  onClose: PropTypes.func,
};

export default EditJob;

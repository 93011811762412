import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import DateField from '../ui/DateField';
import UserSelect from '../UserSelect';
import './styles.scss';
import colors from '../styles/colors.scss';
import PageHeader from '../PageHeader';

const drawerWidth = 272;
const useStyles = makeStyles((theme) => ({
  layout: {
    position: 'absolute',
    minWidth: 0,
    top: 0,
    right: 0,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    height: '80%',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
    background: colors.white,
  },
  pageContent: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    minHeight: 0,
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2rem',
    },
  },
}));

/**
 * TopMenu react component
 * @param {object} TopMenu parameters
 * @returns TopMenu react component
 */
function TopMenu({
  title, subtitle, yesnoTitle, onFilterChange, children,
}) {
  const YESNO = {
    NO: 0,
    YES: 1,
    NONE: 2,
  };
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [filterEmployee, setFilterEmployee] = useState('');
  const [filterYesNo, setFilterYesNo] = useState(YESNO.NONE);
  const [mounted, setMounted] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  /*===========================
          STATE MANAGMENT
  ============================*/
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleFilterDateStartChange = (dateStart) => {
    setFilterDateStart(dateStart);
  };
  const handleFilterDateEndChange = (dateEnd) => {
    setFilterDateEnd(dateEnd);
  };
  const handleFilterEmployeeChange = (employee) => {
    setFilterEmployee(employee);
  };
  const handleFilterYes = () => {
    const value = filterYesNo === YESNO.YES ? YESNO.NONE : YESNO.YES;
    setFilterYesNo(value);
  };
  const handleFilterNo = () => {
    const value = filterYesNo === YESNO.NO ? YESNO.NONE : YESNO.NO;
    setFilterYesNo(value);
  };

  /*===========================
          END MANAGMENT
  ============================*/

  useEffect(() => {
    const retFilters = {
      date_start: filterDateStart,
      date_end: filterDateEnd,
      employee: filterEmployee,
      yes_no: filterYesNo,
    };
    onFilterChange(retFilters);
  }, [filterDateStart, filterDateEnd, filterEmployee, filterYesNo]);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  return (
    <>
      { isMobile && (
        <div className={ classes.layout }>
          <nav className={ classes.drawer } aria-label="menu">
            <Drawer
              variant="temporary"
              anchor="right"
              open={ mobileOpen }
              onClose={ handleDrawerToggle }
              classes={ {
                paper: classes.drawerPaper,
              } }
              ModalProps={ {
                keepMounted: true,
              } }
            >
              <Slide direction="left" in={ mounted }>
                <div className="menuFilter">
                  <div><h2>Filtres : </h2></div>
                  <div>
                    <p>Entre le :</p>
                    <DateField
                      variant="filled"
                      label="Entre le"
                      value={ filterDateStart }
                      onChange={ handleFilterDateStartChange }
                    />
                  </div>
                  <div>
                    <p>Et le :</p>
                    <DateField
                      variant="filled"
                      label="Et le"
                      value={ filterDateEnd }
                      onChange={ handleFilterDateEndChange }
                    />
                  </div>
                  <div>
                    <p>Employés :</p>
                    <UserSelect
                      id="assigned"
                      label="Choisir..."
                      variant="filled"
                      value={ filterEmployee }
                      onChange={ handleFilterEmployeeChange }
                    />
                  </div>
                  <div>
                    <p>{ `${yesnoTitle} :` }</p>
                    <Button
                      className="top-menu-yes"
                      style={ {
                        backgroundColor:
                          filterYesNo === YESNO.YES
                            ? colors.blueDark
                            : colors.white,
                        color:
                          filterYesNo === YESNO.YES
                            ? colors.white
                            : colors.black,
                      } }
                      variant="contained"
                      onClick={ handleFilterYes }
                    >
                      OUI
                    </Button>
                    <Button
                      className="top-menu-no"
                      style={ {
                        backgroundColor:
                          filterYesNo === YESNO.NO
                            ? colors.blueDark
                            : colors.white,
                        color:
                          filterYesNo === YESNO.NO
                            ? colors.white
                            : colors.black,
                      } }
                      variant="contained"
                      onClick={ handleFilterNo }
                    >
                      NON
                    </Button>
                  </div>
                  { children }
                </div>
              </Slide>
            </Drawer>
          </nav>
          <div className={ classes.content }>
            <div className={ classes.appBar }>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={ handleDrawerToggle }
                className={ classes.menuButton }
              >
                <FilterListIcon />
              </IconButton>
            </div>
          
          </div>

        </div>
      ) }
      <PageHeader title={ title } subtitle={ subtitle }>
        <div className="top-menu-filters">
          <div className="top-menu-filters-filter">
            <div className="top-menu-filters-label">Dates: </div>
            <div className="top-menu-filters-filter-content">
              <DateField
                className="top-menu-date-start"
                variant="outlined"
                label="Entre le"
                value={ filterDateStart }
                onChange={ handleFilterDateStartChange }
              />
              <DateField
                className="top-menu-date-end"
                variant="outlined"
                label="Et le"
                value={ filterDateEnd }
                onChange={ handleFilterDateEndChange }
              />
            </div>
          </div>
          <div className="top-menu-filters-filter">
            <div className="top-menu-filters-label">Employés : </div>
            <div className="top-menu-filters-filter-content">
              <UserSelect
                className="top-menu-employee"
                id="assigned"
                label="Choisir..."
                variant="outlined"
                value={ filterEmployee }
                onChange={ handleFilterEmployeeChange }
              />
            </div>
          </div>
          <div className="top-menu-filters-filter">
            <div className="top-menu-filters-label">{ `${yesnoTitle} :` }</div>
            <div className="top-menu-filters-filter-content">
              <div>
                <Button
                  className="top-menu-yes"
                  style={ {
                    backgroundColor:
                      filterYesNo === YESNO.YES
                        ? colors.blueDark
                        : colors.white,
                    color:
                      filterYesNo === YESNO.YES ? colors.white : colors.black,
                  } }
                  variant="contained"
                  onClick={ handleFilterYes }
                >
                  OUI
                </Button>
                <Button
                  className="top-menu-no"
                  style={ {
                    backgroundColor:
                      filterYesNo === YESNO.NO ? colors.blueDark : colors.white,
                    color:
                      filterYesNo === YESNO.NO ? colors.white : colors.black,
                  } }
                  variant="contained"
                  onClick={ handleFilterNo }
                >
                  NON
                </Button>
              </div>
            </div>
          </div>
        { children }
        </div>
      </PageHeader>
    </>
  );
}

TopMenu.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onFilterChange: PropTypes.func,
  yesnoTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TopMenu;

import { dataManager as dm } from '@ferrero/comon';
import { useApi } from './useApi';

/**
 * Hook to manage notifications
 * @returns {object} notifications data and functions
 */
export const useNotification = () => {
  const {
    data: [users],
  } = dm.useUsers();
  const { post } = useApi();

  /**
   * Get the user token
   * @param {object} dest user token 
   * @returns {string} user token
   */
  const getToken = (dest) => {
    if (dest.token) return dest.token;
    if (dest.userId) {
      return users.find((u) => u.id === dest.userId)?.device_token?.token;
    }
    return null;
  };

  /**
   * Send a notification message to a destination
   * @param {json} dest destination 
   * @param {string} message message to send through the notification 
   * @returns void
   */
  const sendNotification = (dest, message) => {
    const token = getToken(dest);
    if (!token) {
      // eslint-disable-next-line no-console
      console.error(`cant send notification to ${JSON.stringify(dest)}. No token provided*`);
      return;
    }

    const FBmessage = {
      notification: {
        title: 'Ferrero',
        body: message,
      },
      token,
    };
    post('notification', { message: FBmessage });
  };
  return {
    sendNotification,
  };
};

export default useNotification;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Button } from './ui';

/**
 * ButtonWithModal react component
 * @param {object} ButtonWithModal parameters
 * @returns ButtonWithModal react component
 */
function ButtonWithModal({
  onClick,
  onClose,
  DialogContent,
  children,
  ...otherProps
}) {
  const [modalVisible, setModlaVisible] = useState(false);

  /**
   * Show/hide modal callback
   */
  const handleClick = () => {
    setModlaVisible(true);
  };
  /**
   * Close modal callback
   */
  const handleClose = () => {
    setModlaVisible(false);
    if (onClose) onClose();
  };

  /**
   * Validate modal
   * @param  {...any} p params
   */
  const handleValidatee = (...p) => {
    setModlaVisible(false);
    if (onClick) onClick(...p);
  };
  return (
    <>
      <Dialog
        open={ modalVisible }
        onClose={ handleClose }
      >
        <DialogContent
          valideDialog={ handleValidatee }
          closeDialog={ handleClose }
        />
      </Dialog>
      <Button { ...otherProps } onClick={ handleClick }>
        { children }
      </Button>
    </>
  );
}

ButtonWithModal.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  DialogContent: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonWithModal;

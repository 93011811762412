import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, makeStyles } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { differenceInDays } from 'date-fns';
import { Button, TextField } from '../ui';
import LeaveSumary from '../LeaveSumary';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem',
  },
  title: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirections: 'row',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    justifyContent: 'space-around',
  },
  element: {
    fontSize: 'x-large',
  },
  buttonRow: {
    display: 'flex',
    flexDirections: 'row',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
}));
/**
 * LeaveValidationModal react component
 * @param {object} LeaveValidationModal parameters
 * @returns LeaveValidationModal react component
 */
function LeaveValidationModal({ valideDialog, closeDialog, holiday }) {
  const [user, setUser] = useState({});
  const [daysCount, setDaysCount] = useState({});
  const [duration, setDuration] = useState({});
  const {
    data: [users],
  } = dm.useUsers();

  useEffect(() => {
    setUser(
      users.find((u) => u.id === (holiday.userId || holiday?.ref_user?.id))
        ?? {},
    );
  }, [users, holiday.userId]);

  useEffect(() => {
    const d = differenceInDays(holiday.date_end.toDate(), holiday.date_start.toDate())
      + 1;
    setDuration(d);
    setDaysCount(d);
  }, [holiday]);

  const classes = useStyles();

  const save = () => {
    valideDialog(daysCount);
  };

  return (
    <div className={ classes.container }>
      <DialogTitle className={ classes.title }>
        Validation et décompte des jours
      </DialogTitle>
      <div className={ classes.content }>
        <div className={ classes.column }>
          <LeaveSumary userId={ holiday.userId || holiday?.ref_user?.id } />
        </div>
        <div className={ classes.column }>
          <div className={ classes.element }>
            { `Capital total jours : ${user.days_off_count}` }
          </div>
          <div
            className={ classes.element }
          >
            { `Jours demandés : ${duration}` }
          </div>
          <div className={ classes.element }>
            { `Total jours restant : ${
              user.days_off_count - user.days_of_leave_taken
            }` }
          </div>
          <div className={ classes.element }>
            <TextField
              variant="filled"
              value={ daysCount }
              type="number"
              onChange={ setDaysCount }
              label="Jours à décompter"
              error={ Number.isNaN(parseInt(daysCount, 10)) }
            />
          </div>
        </div>
      </div>
      <div className={ classes.buttonRow }>
        <Button
          color="primary"
          variant="contained"
          onClick={ save }
          disabled={ Number.isNaN(parseInt(daysCount, 10)) }
        >
          Valider
        </Button>
        <Button color="default" variant="contained" onClick={ closeDialog }>
          Annuler
        </Button>
      </div>
    </div>
  );
}

LeaveValidationModal.propTypes = {
  valideDialog: PropTypes.func,
  closeDialog: PropTypes.func,
  holiday: PropTypes.object,
};

export default LeaveValidationModal;

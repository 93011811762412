import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from './TextField';

/**
 * PasswordField react component
 * @param {object} PasswordField parameters
 * @returns PasswordField react component
 */
function PasswordField({ InputProps, ...props }) {
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  /**
   * Mask displayed password
   * @param {boolean} isMasked true when masked 
   */
  const maskPassword = (isMasked) => {
    setPasswordIsMasked(isMasked);
  };

  return (
    <div className="container">
      <TextField
        autoComplete="current-password"
        InputProps={ {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onMouseEnter={ () => maskPassword(false) }
                onMouseLeave={ () => maskPassword(true) }
                edge="end"
              >
                { passwordIsMasked ? <Visibility /> : <VisibilityOff /> }
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        } }
        { ...props }
        type={ passwordIsMasked ? 'password' : 'text' }
      />
    </div>
  );
}
PasswordField.propTypes = {
  InputProps: PropTypes.object,
};
export default PasswordField;

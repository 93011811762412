import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

/**
 * Card react component
 * @param {object} Card parameters
 * @returns Card react component
 */
function Card({ children, className }) {
  return (
    <Paper className={ classNames('card-container', className) }>
      { children }
    </Paper>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Card.defaultProps = {
  children: null,
};

export default Card;

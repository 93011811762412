import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { dataManager as dm } from '@ferrero/comon';
import Typography from '@material-ui/core/Typography';
import './styles.scss';
import { Checkbox } from '@material-ui/core';

const NOT_ASSIGNED = {
  id: 'not_assigned',
  color: 'red',
  first_name: 'NON ASSIGNÉ',
  last_name: '',
};

/**
 * React UserRow component 
 * @param {object} param component mandatory parameters
 * @returns {render} UserRow react component
 */
const UserRow = ({ user, onClick, selected }) => (
  <div
    className="calendar-userList-item"
    role="button"
    tabIndex={ 0 }
    onClick={ () => onClick(user) }
  >
    <div
      className="calendar-userList-color"
      style={ {
        backgroundColor: selected ? user.color : 'white',
        borderColor: user.color,
      } }
    />
    { `${user.first_name} ${user.last_name}` }
  </div>
);
UserRow.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
/**
 * React UserSelect component 
 * @param {function} onChange callback to call when changes 
 * @returns {render} UserSelect react component
 */
function UserSelect({ onChange }) {
  const {
    data: [users],
  } = dm.useUsers();
  const [selected, setSelecetd] = useState([
    NOT_ASSIGNED.id,
    ...users.map((u) => u.id),
  ]);

  /**
   * Handle click to a user, add it to the selection list
   * @param {object} u user clicked on
   */
  const handleClickUser = (u) => {
    const newValue = selected.find((i) => i === u.id)
      ? selected.filter((i) => i !== u.id)
      : [...selected, u.id];

    setSelecetd(newValue);
  };

  const allSelected = [NOT_ASSIGNED, ...users].every((u) => selected.find((i) => i === u.id));
  const indeterminate = !allSelected && selected.length > 0;

  /**
   * Updates the selected users when selection changes
   * @returns {array} selected users
   */
  const handleSelectAllChange = () => {
    if (allSelected) return setSelecetd([]);
    return setSelecetd([NOT_ASSIGNED.id, ...users.map((u) => u.id)]);
  };

  useEffect(() => {
    onChange(selected);
  }, [selected]);
  return (
    <>
      <div className="calendar-userList-header">
        <Checkbox
          checked={ allSelected }
          indeterminate={ indeterminate }
          onChange={ handleSelectAllChange }
        />
        <Typography variant="h5">Calendriers</Typography>
      </div>
      <div className="calendar-userList-container">
        <>
          <UserRow
            key="not-assigned"
            user={ NOT_ASSIGNED }
            onClick={ handleClickUser }
            selected={ !!selected.find((i) => i === NOT_ASSIGNED.id) }
          />
          { users.map((u) => (
            <UserRow
              key={ u.id }
              user={ u }
              onClick={ handleClickUser }
              selected={ !!selected.find((i) => i === u.id) }
            />
          )) }
        </>
      </div>
    </>
  );
}

UserSelect.propTypes = {
  onChange: PropTypes.func,
};

export default UserSelect;

import React from 'react';
import PropTypes from 'prop-types';
import icPDF from '../../assets/ic_pdf.svg';
import icMediaNotFound from '../../assets/ic_media_not_found.svg';
import Picto from '../ui/Picto';
import colors from '../styles/colors.scss';
import { config } from '../../config';


/**
 * Find media file src
 * @param {object} media the media to use
 * @returns {string} media path
 */
const getMediaFileSrc = (media) => {
  if (media?.extension?.toLowerCase() === 'pdf') return icPDF;
  if (media?.file_path) {
    return `${config.baseURLApi}/media/${media?.file_path}`;
  }
  return media?.source;
};

/**
 * Generates display media name
 * @param {string} str current name
 * @param {string} fileType media file type
 * @returns {string} final display media name
 */
const displayMediaName = (str = '', fileType = '') => (str?.length > 20 ? `${str.substr(0, 12)}... .${fileType}` : str || '');

/**
 * Format a timestamp to a date
 * @param {timestamp} ts the current timestamp to format 
 * @returns {string} formatted date
 */
const tsToDateFormatted = (ts) => {
  const a = new Date(ts);
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Avr',
    'Mai',
    'Jui',
    'Jul',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Déc',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return `${date} ${month} ${year} ${hour}:${min}:${sec}`;
};

/**
 * Media react component
 * @param {object} params parameters
 * @returns Media react component
 */
function Media({ media, onDelete, onClick }) {
  return (
    <span
      key={ media.file_path ? media.file_path : media.internalId }
      className="event-media-item"
    >
      <div className="event-media-item-image-container">
        <div className="overlay" role="button" tabIndex={ 0 } onClick={ onDelete }>
          <Picto size={ 22 } type="icClose" fill={ colors.red } />
        </div>
        <img
          alt={ media.name }
          src={ getMediaFileSrc(media) }
          onClick={ onClick }
          onError={ (e) => {
            e.target.onerror = null;
            e.target.src = icMediaNotFound;
          } }
        />
      </div>
      <span>{ displayMediaName(media.name, media.extension) }</span>
      <span>{ tsToDateFormatted(media.ts_inserted) }</span>
    </span>
  );
}

Media.propTypes = {
  media: PropTypes.object,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

export default Media;

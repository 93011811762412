import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm } from '@ferrero/comon';
import { format, differenceInDays } from 'date-fns';
import colors from '../styles/colors.scss';
import Picto from '../ui/Picto';
import { Button } from '../ui';
import { useHolidays } from '../../hooks/useHolidays';
import LeaveValidator from '../LeaveValidator';

/**
 * React EditHoliday component 
 * @param {object} param component mandatory parameters
 * @returns {render} EditHoliday react component
 */
function EditHoliday({ holiday, onClose }) {
  const [data, setData] = useState();
  const [user, setUser] = useState({});

  const {
    data: [users],
  } = dm.useUsers();
  const {
    data: [holidays],
  } = useHolidays();
  useEffect(() => {
    setData(holidays.find((h) => h.id === holiday.id));
  }, [holidays, holiday]);
  useEffect(() => {
    setUser(users.find((elt) => elt.id === data?.ref_user?.id));
  }, [data, users]);

  if (!data) return null;
  return (
    <div className="editHoliday-Container">
      <div className="editEvent-title">
        <h2>Congé</h2>
        { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions  */ }
        <div id="close-modal" onClick={ onClose }>
          <Picto size={ 16 } type="icClose" />
        </div>
      </div>
      <div className="editEvent-content">
        <div className="editHoliday-row">
          { `demande de congé du ${format(
            new Date(data.date_request.seconds * 1000),
            'dd/MM/yyyy',
          )}` }
        </div>
        <div className="editHoliday-row">
          <Picto
            size={ 30 }
            type="icUser"
            fill={ colors.textGreyColor }
            style={ { marginRight: 10 } }
          />
          <p className="noselect">{ `${user?.first_name} ${user?.last_name}` }</p>
        </div>
        <div className="editHoliday-row">
          <Picto
            size={ 30 }
            type="icCalendar"
            fill={ colors.textGreyColor }
            style={ { marginRight: 10 } }
          />
          { `Du ${format(
            new Date(data.date_start.seconds * 1000),
            'dd/MM/yyyy',
          )} au ${format(
            new Date(data.date_end.seconds * 1000),
            'dd/MM/yyyy',
          )} (${
            differenceInDays(data.date_end.toDate(), data.date_start.toDate())
            + 1
          } jours)` }
        </div>
        <div className="editHoliday-row">
          <Picto
            size={ 30 }
            type="icLabel"
            fill={ colors.textGreyColor }
            style={ { marginRight: 10 } }
          />
          { data.reason }
        </div>
        <div
          style={ {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: 10,
          } }
        >
          Accepter ?
          <LeaveValidator holiday={ data } />
        </div>
        <div className="editHoliday-row">
          { data.accepted_date
            && data.accepted != null
            && `${data.accepted ? 'accepté ' : 'refusé'} le ${format(
              data.accepted_date.toDate(),
              'dd/MM/yyyy',
            )}` }
        </div>
      </div>
      <div className="editEvent-button-row">
        <span />
        <span className="actions">
          <Button color="default" variant="contained" onClick={ onClose }>
            Fermer
          </Button>
          { /* <Button
            color="primary"
            disabled={ !canSave() || holiday.accepted != undefined }
            variant="contained"
            onClick={ save }
          >
            Enregistrer
          </Button> */ }
        </span>
      </div>
    </div>
  );
}

EditHoliday.propTypes = {
  holiday: PropTypes.object,
  onClose: PropTypes.func,
};

export default EditHoliday;

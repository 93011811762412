import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Loader.scss';
import { ReactComponent as LoaderSvg } from '../../assets/animated_leak.svg';

/**
 * Loader react component
 * @param {object} Loader parameters
 * @returns Loader react component
 */
function Loader({ className, ...props }) {
  return (
    <LoaderSvg className={ classNames('lds-ripple', className) } { ...props } />
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default Loader;

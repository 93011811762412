import React from 'react';
import { Alert as MatAlert } from '@material-ui/lab';
/**
 * Alert react component
 * @param {props} Alert props
 * @returns Alert react component
 */
function Alert(props) {
  return (
    <MatAlert { ...props } />
  );
}

Alert.propTypes = {

};

export default Alert;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useUsers = void 0;

var _react = require("react");

var _Provider = require("../Provider");

var _index = _interopRequireDefault(require("../index"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle

/**
 * Hook to manage firebase users
 * @returns {object} firebase users data and functions
 */
var useUsers = function useUsers() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  if (!ctx) {
    throw Error('The `useUsers` hook must be called from a descendent of the `DataProvider`.');
  }
  /**
  * Insert or update (when existing) a firebase user
  * @param {integer} id the firebase user id in DB 
  * @param {object} data firebase user data 
  * @returns {object} insert or update result
  */


  var upsert = function upsert(id, data) {
    var noNullData = data;
    Object.keys(noNullData).forEach(function (key) {
      return noNullData[key] === undefined && delete noNullData[key];
    });

    if (id) {
      return _index["default"].firestore.collection('user').doc(id).update(data);
    }

    return _index["default"].firestore.collection('user').doc(data.id).set(noNullData);
  };
  /**
   * Reser every users holidays
   * @returns {object} firebase update result
   */


  var resetAllVacancies = function resetAllVacancies() {
    return Promise.all(ctx.users[0].map(function (u) {
      return _index["default"].firestore.collection('user').doc(u.id).update({
        days_of_leave_taken: 0
      });
    }));
  };

  return {
    data: ctx.users,
    upsert: upsert,
    resetAllVacancies: resetAllVacancies
  };
};

exports.useUsers = useUsers;
var _default = useUsers;
exports["default"] = _default;
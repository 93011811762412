import React from 'react';
import PropTypes from 'prop-types';
import EditJob from './EditJob';
import EditHoliday from './EditHoliday';


/**
 * EditEvent react component
 * @param {object} EditEvent parameters
 * @returns EditEvent react component
 */
function EditEvent({ event, onClose }) {
  if (event.job) return <EditJob job={ event.job } onClose={ onClose } />;
  if (event.holiday) {
    return <EditHoliday holiday={ event.holiday } onClose={ onClose } />;
  }
}

EditEvent.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
};

export default EditEvent;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import XLSX from 'xlsx';
import { useApi } from '../../hooks/useApi';
import colors from '../styles/colors.scss';
import './styles.scss';
import Picto from '../ui/Picto';
import { useSnack } from '../../hooks/useSnack';

/**
 * CircularProgressWithLabel react component
 * @param {object} CircularProgressWithLabel parameters
 * @returns CircularProgressWithLabel react component
 */
function CircularProgressWithLabel({ value, ...props }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" { ...props } />
      <Box
        top={ 0 }
        left={ 0 }
        bottom={ 0 }
        right={ 0 }
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          { `${Math.round(value)}%` }
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

/**
 * Format the current time to string display
 * @returns {string} formatted time
 */
const getFormattedTime = () => {
  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const h = today.getHours();
  const mi = today.getMinutes();
  const s = today.getSeconds();
  return `${y}-${m}-${d}-${h}-${mi}-${s}`;
};

/**
 * Save a XLS file
 * @param {object} data file data to save
 */
const saveFile = (data) => {
  const header = [
    'Facture',
    'Fournisseur',
    'référence',
    'prix facturé',
    'prix référence',
    'diférence',
  ];
  const ws = XLSX.utils.aoa_to_sheet([
    header,
    ...data.map((r) => [
      r.invoice,
      r.supplier,
      r.reference,
      r.invoicePrice,
      r.refPrice,
      r.diffPrice,
    ]),
  ]);
  const wb = XLSX.utils.book_new();

  const fmt = '0.00 €';
  for (let R = 1; R <= data.length + 1; ++R) {
    for (let C = 3; C <= 5; C++) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
      // eslint-disable-next-line no-continue
      if (!cell) continue;
      cell.z = fmt;
    }
  }

  XLSX.utils.book_append_sheet(wb, ws, 'factures');
  XLSX.writeFile(wb, `rapport factures_${getFormattedTime()}.xlsx`);
};

/**
 * UploadFile react component
 * @returns UploadFile react component
 */
function UploadFile() {
  const { uploadWithProgress } = useApi();
  const [progress, setProgress] = useState();
  const [isAddingFile, setIsAddingFile] = useState(false);
  const { addSnack } = useSnack();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) return;

      try {
        setIsAddingFile(true);
        setProgress(0);
        const zip = new JSZip();
        acceptedFiles.forEach((file) => zip.file(file.name, file));

        const content = await zip.generateAsync({ type: 'blob' });
        const zipped = new File([content], 'name.zip', {
          type: 'application/zip',
        });
        const result = await uploadWithProgress(
          'invoice/upload',
          zipped,
          setProgress,
        );
        saveFile(result);
        setIsAddingFile(false);
        addSnack({
          message: 'analyse terminée',
          severity: 'success',
        });
      } catch (_) {
        setIsAddingFile(false);
        addSnack({
          message: "Une erreur s'est produite",
          severity: 'error',
        });
      }
    },
    [uploadWithProgress, addSnack],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });
  return (
    <div { ...getRootProps() } className="add-invoice-container">
      <input className="add-invoice-dropzone-input" { ...getInputProps() } />
      <div id="add-invoice-add-icon">
        { !isAddingFile ? (
          <div className="global-center">
            <Picto
              size={ 90 }
              type="icDragAndDrop"
              color={ isDragActive ? colors.blue : colors.white }
            />
            <p>Déposer les fichiers pdf</p>
          </div>
        ) : (
          <div>
            { progress && progress < 100 ? (
              <CircularProgressWithLabel value={ progress } />
            ) : (
              <CircularProgress />
            ) }
          </div>
        ) }
      </div>
    </div>
  );
}

UploadFile.propTypes = {};

export default UploadFile;

import PropTypes from 'prop-types';
import React from 'react';
import { constants } from '@ferrero/comon';

import * as dates from 'react-big-calendar/lib/utils/dates';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';
import './styles.scss';

/**
 * React agenda component
 * @param {object} param object containing the agenda requirements 
 * @returns {render} agenda react component
 */
function Agenda({
  accessors, localizer, length, date, events, onSelectEvent,
}) {
  const { messages } = localizer;

  const end = dates.add(date, length, 'day');

  events = events.filter((event) => inRange(event, date, end, accessors));

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  /**
   * Time labeling an event
   * @param {object} event the event to label 
   * @returns {string} labeled event
   */
  const timeLabel = (event) => `${localizer.format(event.start, 'agendaTimeFormat')} - ${localizer.format(
    event.end,
    'agendaTimeFormat',
  )}`;

  return (
    <div className="rbc-agenda-view">
      { events.length !== 0 ? (
        <div className="eventListContainer">
          { events.map((ev) => {
            const status = constants.JOB_STATUS[ev?.job?.status || 0];

            return (
              <div
                role="button"
                tabIndex={ 0 }
                className="eventContainer"
                onClick={ () => onSelectEvent(ev) }
                style={ { color: ev?.user?.color } }
              >
                <div
                  style={ { backgroundColor: status.color } }
                  className="eventColor"
                />

                <div>{ timeLabel(ev) }</div>
                <div>
                  { `${ev?.user?.first_name || ''} ${ev?.user?.last_name || ''} ${
                    ev?.job?.zipcode_end ?? ''
                  } ${ev?.job?.city_end ?? ''} ${ev?.title ?? ''} ` }
                </div>
              </div>
            );
          }) }
        </div>
      ) : (
        <span className="rbc-agenda-empty">{ messages.noEventsInRange }</span>
      ) }
    </div>
  );
}

Agenda.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number.isRequired,
  onSelectEvent: PropTypes.func,
  accessors: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
};

/**
 * Sets a range to the agenda
 * @param {date} start 
 * @param {object} param1  range length
 * @returns {object} start and end range dates
 */
Agenda.range = (start, { length = Agenda.defaultProps.length }) => {
  const end = dates.add(start, length, 'day');
  return { start, end };
};

/**
 * Navigate in the agenda
 * @param {date} date 
 * @param {navigate} action 
 * @param {object} param2 range length 
 * @returns {date} the next date in the navigation 
 */
Agenda.navigate = (date, action, { length = Agenda.defaultProps.length }) => {
  switch (action) {
      case navigate.PREVIOUS:
        return dates.add(date, -length, 'day');

      case navigate.NEXT:
        return dates.add(date, length, 'day');

      default:
        return date;
  }
};

/**
 * Defines the agenda current title
 * @param {date} start start date 
 * @param {object} param1 range length
 * @returns {string} current agenda title
 */
Agenda.title = (start, { length = Agenda.defaultProps.length, localizer }) => {
  const end = dates.add(start, length, 'day');
  return localizer.format({ start, end }, 'agendaHeaderFormat');
};

export default Agenda;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _useLoadingValues2 = _interopRequireDefault(require("../hooks/useLoadingValues"));

var _index = _interopRequireDefault(require("../index"));

var _authProvider = _interopRequireDefault(require("./authProvider"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Provides allowed expenses data from firebase
 */
var _default = function _default(_ref) {
  var restrictedData = _ref.restrictedData,
      user = _ref.user;
  if (!_index["default"].firestore) return [[], true];

  var _useLoadingValues = (0, _useLoadingValues2["default"])(),
      error = _useLoadingValues.error,
      loading = _useLoadingValues.loading,
      setError = _useLoadingValues.setError,
      setValue = _useLoadingValues.setValue,
      value = _useLoadingValues.value;

  var ref = (0, _react.useRef)(_index["default"].firestore.collection('expense').orderBy('date', 'desc'));

  var _AuthProvider = (0, _authProvider["default"])(),
      initializing = _AuthProvider.initializing;

  (0, _react.useEffect)(function () {
    if (initializing || !ref.current) {
      setValue(undefined);
      return function () {
        return null;
      };
    }

    if (restrictedData && !(user !== null && user !== void 0 && user.id)) {
      setValue(undefined);
      return function () {
        return null;
      };
    }

    var listener = ref.current;

    if (restrictedData) {
      var userRef = _index["default"].firestore.collection('user').doc(user.id);

      listener = listener.where('ref_user', '==', userRef);
    }

    listener = listener.onSnapshot(function (querySnapshot) {
      var values = querySnapshot.docs.map(function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data ? doc.data() : doc);
      });

      for (var i = values.length - 1; i >= 0; i--) {
        if ('ts_deleted' in values[i] && values[i].ts_deleted != null && values[i].ts_deleted !== '') {
          values.splice(i, 1);
        }
      }

      setValue(_toConsumableArray(values));
    }, setError);
    return function () {
      listener();
    };
  }, [ref.current, user, initializing]);
  var resArray = [value, loading, error];
  return (0, _react.useMemo)(function () {
    return resArray;
  }, resArray);
};

exports["default"] = _default;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ListHeader from './Header';
import ListRow from './Row';

import './styles.scss';
/**
 * DataList react component
 * @param {object} DataList parameters
 * @returns DataList react component
 */
function DataList({
  data,
  columns,
  className,
  renderDetail,
  getRowStyle,
  onRowClick,
  ...other
}) {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  return (
    <div className={ classnames('list_container', className) } { ...other }>
      { !isMobile && <ListHeader columns={ columns } /> }
      <div className="list-rows-container">
        { data.map((row) => (
          <ListRow
            key={ row.id }
            data={ row }
            columns={ columns }
            renderDetail={ renderDetail }
            onClick={ onRowClick }
            getStyle={ getRowStyle }
          />
        )) }
      </div>
    </div>
  );
}

DataList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
  renderDetail: PropTypes.func,
  getRowStyle: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default DataList;

import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MatTextField } from '@material-ui/core';
import './TextField.scss';

/**
 * TextField react component
 * @param {object} TextField parameters
 * @returns TextField react component
 */
const TextField = React.forwardRef((props, ref) => (
  <div className="container">
    <MatTextField
      inputRef={ ref }
      size="small"
      { ...props }
      onChange={ ({ target: { value } }) => props.onChange(value) }
    />
  </div>
));

TextField.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};
TextField.defaultProps = {
  color: 'primary',
};
export default TextField;

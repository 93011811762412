import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { useUsers } from '@ferrero/comon/dataManager/hooks/useUsers';
import { Button } from '../../components/ui';
import Picto from '../../components/ui/Picto';
import './styles.scss';
import EditableList from './EditableList';
import { useConfirmationDialog } from '../../hooks/ConfirmDialog';
import { useSnack } from '../../hooks/useSnack';

/**
 * HolidayParamsButton react component
 * @returns HolidayParamsButton react component
 */
function HolidayParamsButton() {
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const {
    data: [params],
    upsert,
  } = dm.useParams();
  const { resetAllVacancies } = useUsers();
  const { getConfirmation } = useConfirmationDialog();
  const { addSnack } = useSnack();

  useEffect(() => {
    setReasons(params?.vacancy?.leave_reason ?? []);
  }, [params]);

  /**
   * Save holiday params
   */
  const save = () => {
    const data = {
      id: 'vacancy',
      ...params?.vacancy,
      leave_reason: reasons,
    };
    upsert(data)
      .then(() => {
        addSnack({
          message: 'Paramètres enregistrés.',
          severity: 'success',
        });
        setOpen(false);
      })
      .catch(() => addSnack({
        message: "Erreur d'enregistrement.",
        severity: 'error',
      }));
  };

  /**
   * Reset holidays settings
   * @returns void
   */
  const resetVacancies = async () => {
    const confirmed = await getConfirmation({
      title: 'réinitialiser les congés',
      message:
        'Voulez-vous réinitialiser les congés de tous les ouvriers ? Cette action ne peut pas etre annulée',
    });
    if (!confirmed) return;
    try {
      resetAllVacancies()
        .then(() => {
          addSnack({
            message: 'congés réinitialisés.',
            severity: 'success',
          });
        })
        .catch(() => addSnack({
          message: 'Erreur',
          severity: 'error',
        }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={ () => setOpen(true) }>
        Parametrage
      </Button>
      <Modal open={ open } disableBackdropClick onClose={ () => setOpen(false) }>
        <div className="new-holiday-Container">
          <div className="editEvent-title">
            <h2>Paramétrage</h2>
            <div
              id="close-modal"
              role="button"
              tabIndex={ 0 }
              onClick={ () => setOpen(false) }
            >
              <Picto size={ 16 } type="icClose" />
            </div>
          </div>
          <div className="new-holiday-content">
            <div className="holiday-params-reasons-header">
              <div>Motif</div>
              <div>Visible mobile</div>
              <div />
            </div>
            <EditableList value={ reasons } onChange={ setReasons } />

            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={ resetVacancies }
              >
                Réinitialiser solde congés
              </Button>
            </div>
          </div>
          <span className="new-holiday-actions">
            <Button
              color="default"
              variant="contained"
              onClick={ () => setOpen(false) }
            >
              Annuler
            </Button>
            <Button color="primary" variant="contained" onClick={ save }>
              Enregistrer
            </Button>
          </span>
        </div>
      </Modal>
    </>
  );
}

HolidayParamsButton.propTypes = {};

export default HolidayParamsButton;

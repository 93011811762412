import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import './AutocompleteTextField.scss';

/**
 * AddressInput react component
 * @param {object} AddressInput parameters
 * @returns AddressInput react component
 */
function AddressInput({ initialValue, onChange, ...other }) {
  const [userInput, setUserInput] = useState('');
  const [initial, setInitial] = useState(initialValue);

  useEffect(() => {
    setUserInput(initialValue.display_name);
  }, [initialValue, initial]);

  /**
   * Callback when address input text change
   * updates state
   */
  const onTextChange = useCallback(
    (txt) => {
      setInitial(null);
      const startAddress = {};
      startAddress.display_name = txt;
      startAddress.address = {
        postcode: '',
        municipality: '',
      };
      onChange(startAddress);
    },
    [onChange],
  );

  return (
    <div className="autocomplete-container">
      <div className="search">
        <TextField
          type="text"
          className="search-box"
          onChange={ onTextChange }
          value={ userInput }
          inputProps={ { autoComplete: 'off' } }
          { ...other }
        />
      </div>
    </div>
  );
}

AddressInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
};

AddressInput.defaultProps = {
  initialValue: '',
  onChange: null,
};

export default AddressInput;

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useAdditionalHours = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Provider = require("../Provider");

var _useNotifications2 = require("./useNotifications");

var _index = _interopRequireDefault(require("../index"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Hook to manage additional hours
 * @returns {object} additional hours data and functions
 */
var useAdditionalHours = function useAdditionalHours() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  var _useNotifications = (0, _useNotifications2.useNotifications)(),
      addNotification = _useNotifications.add;

  if (!ctx) {
    throw Error('The `useAdditionalHours` hook must be called from a descendent of the `DataProvider`.');
  }
  /**
   * Delete an additional hour
   * @param {integer} id the additional hour id in DB 
   * @returns {object} update result
   */


  var del = function del(id) {
    return _index["default"].firestore.collection('additional_hour').doc(id).update({
      ts_deleted: new Date()
    });
  };
  /**
   * Insert or update (when existing) an additional hour
   * @param {integer} id the additional hour id in DB 
   * @param {object} data additional hour data 
   * @returns {object} insert or update result
   */


  var upsert = function upsert(id, data) {
    var dataWithRefs = _objectSpread(_objectSpread({}, data), {}, {
      id: undefined,
      ref_user: _index["default"].firestore.collection('user').doc(data.ref_user.split('/')[1])
    });

    var noNullData = dataWithRefs;
    Object.keys(noNullData).forEach(function (key) {
      return noNullData[key] === undefined && delete noNullData[key];
    });

    if (id) {
      return _index["default"].firestore.collection('additional_hour').doc(id).update(dataWithRefs);
    }

    return _index["default"].firestore.collection('additional_hour').add(noNullData).then(function (res) {
      addNotification({
        body: 'Heure supplémentaire',
        ref: "additional_hour/".concat(res.id),
        ref_user: data.ref_user
      });
    });
  };

  return {
    data: ctx.additionatHours,
    upsert: upsert,
    del: del
  };
};

exports.useAdditionalHours = useAdditionalHours;
var _default = useAdditionalHours;
exports["default"] = _default;
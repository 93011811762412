import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Switch, TextField } from '../../components/ui';
import Picto from '../../components/ui/Picto';
import colors from '../../components/styles/colors.scss';

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    '> *': {
      minWidth: 58,
    },
    '&>:first-child': {
      flex: 1,
    },
  },
  container: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    overflow: 'auto',
    minHeight: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '1rem',
    flex: 1,
  },
}));

/**
 * ListElement react component
 * @param {object} ListElement parameters
 * @returns ListElement react component
 */
const ListElement = React.forwardRef(
  (
    {
      value, onChange, onClick, picto = 'icTrash', fill, KeyboardClick,
    },
    ref,
  ) => {
    const classes = useStyles();

    /**
     * Callback when list labek changes
     * @param {string} t label 
     */
    const handleChange = (t) => {
      onChange({ ...value, label: t });
    };

    /**
     * Onclick when enter key pressed
     * @param {event} e keyboard event 
     */
    const handleKeyUp = (e) => {
      if (KeyboardClick && e.nativeEvent.key === 'Enter') onClick();
    };

    return (
      <div className={ classes.rowContainer }>
        <TextField
          ref={ ref }
          variant="filled"
          size="small"
          label="Nouveau motif"
          value={ value.label }
          onChange={ handleChange }
          inputProps={ {
            onKeyUp: handleKeyUp,
          } }
        />
        <Switch
          checked={ value.visibleMobile }
          onChange={ ({ target: { checked } }) => onChange({ ...value, visibleMobile: checked }) }
        />
        <div style={ { width: 58 } }>
          <Picto size={ 30 } type={ picto } fill={ fill } onClick={ onClick } />
        </div>
      </div>
    );
  },
);

ListElement.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  picto: PropTypes.string,
  fill: PropTypes.string,
  KeyboardClick: PropTypes.bool,
};

/**
 * EditableList react component
 * @param {object} EditableList parameters
 * @returns EditableList react component
 */
function EditableList({ value, onChange, className }) {
  const [items, setItems] = useState(value || []);
  const [newValue, setNewValue] = useState({ label: '', visibleMobile: true });
  const classes = useStyles();
  const addRef = useRef();

  useEffect(() => {
    onChange(items);
  }, [items]);

  /**
   * Changes the array values in list
   * @param {integer} index current value change index in array
   * @param {blob} v new value to update 
   * @returns {array} new array
   */
  const handleChange = (index, v) => setItems((cur) => {
    const val = cur;
    val[index] = v;
    return [...val];
  });

  /**
   * Add new item with its value
   */
  const handleAdd = () => {
    setItems((cur) => [...cur, { ...newValue }]);
    setNewValue({ label: '', visibleMobile: true });
    if (addRef.current) addRef.current.focus();
  };

  /**
   * Delete an item from the list
   * @param {integer} index current index in array
   */
  const handleDelete = (index) => {
    setItems((cur) => [...cur.filter((_, i) => i !== index)]);
  };
  return (
    <div className={ classNames(classes.container, className) }>
      <ListElement
        ref={ addRef }
        value={ newValue }
        onChange={ setNewValue }
        onClick={ handleAdd }
        picto="icPlus"
        fill={ colors.blue }
        KeyboardClick
      />
      <div className={ classes.listContainer }>
        { items.map((item, i) => (
          <ListElement
            key={ item.label }
            value={ item }
            onChange={ (v) => handleChange(i, v) }
            onClick={ () => handleDelete(i) }
            picto="icTrash"
            fill={ colors.red }
          />
        )) }
      </div>
    </div>
  );
}

EditableList.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default EditableList;

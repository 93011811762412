/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Fade, Button } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { format } from 'date-fns';
import TopMenu from '../components/TopMenu';
import './AdditionalHours.scss';
import DataList from '../components/ui/List';
import colors from '../components/styles/colors.scss';
import { useNotification } from '../hooks/useNotification';

/**
 * AdditionalHours react component
 * @returns AdditionalHours react component
 */
function AdditionalHours() {
  const [show, setSow] = useState(false);
  const [lastFilter, setLastFilter] = useState(null);
  const [additionalHoursList, setAdditionalHoursList] = useState([]);
  const [initialList, setInitialList] = useState([]);
  const { upsert } = dm.useAdditionalHours();
  const { sendNotification } = useNotification();

  // GET VALUES FROM DB
  const {
    data: [additionalHours],
  } = dm.useAdditionalHours();
  const {
    data: [users],
  } = dm.useUsers();

  // INITIALIZE LISTS
  useEffect(() => {
    const list = (additionalHours || []).map((curr) => ({
      id: `additional_hour/${curr.id}`,
      ref_user: `user/${curr.ref_user.id}`,
      userId: curr.ref_user.id,
      user_name: `${
        users.find((elt) => elt.id === curr.ref_user.id)?.first_name
      } ${users.find((elt) => elt.id === curr.ref_user.id)?.last_name}`,
      date_request_formatted: `${format(
        new Date(curr.date_request.seconds * 1000),
        'dd/MM/yyyy',
      )}`,
      date_formatted: `${format(
        new Date(curr.date_start.seconds * 1000),
        'dd/MM/yyyy',
      )}`,
      date_start_formatted: `${format(
        new Date(curr.date_start.seconds * 1000),
        'HH:mm',
      )}`,
      date_end_formatted: `${format(
        new Date(curr.date_end.seconds * 1000),
        'HH:mm',
      )}`,
      date_request: curr.date_request,
      date_start: curr.date_start,
      date_end: curr.date_end,
      duration: secondsToHm(
        new Date(curr.date_end.seconds).getTime()
          - new Date(curr.date_start.seconds).getTime(),
      ),
      status: curr.is_validated,
    }));

    setInitialList(list);
    setAdditionalHoursList(list);
  }, [additionalHours, users]);

  useEffect(() => {
    setSow(true);
    return () => {
      setSow(false);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  /**
   * Converts seconds to hours and minutes for display
   * @param {integer} d duration in seconds 
   * @returns {string} formatted duration
   */
  const secondsToHm = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const hDisplay = h > 0 ? `${h}H` : '0H';
    const mDisplay = m > 0 ? m : '00';
    return hDisplay + mDisplay;
  };

  /**
   * ON FILTER CHANGE, FILTER DISPLAY LIST
   * @param {object} filters filters 
   */
  const onFilterChange = (filters) => {
    if (filters != null) {
      setLastFilter(filters);
      const filteredList = [];
      initialList.forEach((elt) => {
        let isValid = true;
        if (filters.yes_no !== null && filters.yes_no !== 2) {
          isValid &= filters.yes_no === 1 ? elt.status === 1 : elt.status === 0;
        }
        if (filters.date_start !== null) {
          filters.date_start.setHours(0);
          filters.date_start.setMinutes(0);
          isValid
            &= filters.date_start.getTime() <= elt.date_request.seconds * 1000;
        }
        if (filters.date_end !== null) {
          filters.date_end.setHours(23);
          filters.date_end.setMinutes(59);
          isValid
            &= filters.date_end.getTime() >= elt.date_request.seconds * 1000;
        }
        if (filters.employee !== null && filters.employee !== '') {
          isValid &= filters.employee === elt.ref_user;
        }
        if (isValid) filteredList.push(elt);
      });
      setAdditionalHoursList(filteredList);
    }
  };

  /**
   * DATABASE UPDATE status
   * @param {integer} id item id in db
   * @param {integer} status new status 
   */
  const onItemStatusChange = (id, status) => {
    const curr = additionalHoursList.find((elt) => elt.id === id);
    const formatedData = {
      ref_user: curr.ref_user,
      date_request: curr.date_request,
      date_start: curr.date_start,
      date_end: curr.date_end,
      is_validated: status,
    };
    upsert(id.split('/')[1], formatedData).then(async () => {
      const filteredList = [];
      initialList.forEach((elt) => {
        if (elt.id === id) {
          elt.status = status;
        }
        filteredList.push(elt);
      });
      sendNotification(
        { userId: curr.userId },
        `vos heures supplèmentaires du ${curr.date_formatted} ont été ${
          status ? 'accéptée' : 'refusée'
        }`,
      );
      setAdditionalHoursList(filteredList);
      setInitialList(filteredList);
      onFilterChange(lastFilter);
    });
  };

  const renderStatus = (status, row) => (
    <>
      <Button
        className="addhours-row-item-btn-yes"
        style={ {
          backgroundColor: status === 1 ? colors.green : colors.white,
          color: status === 1 ? colors.white : colors.black,
        } }
        variant="contained"
        onClick={ () => onItemStatusChange(row.id, 1) }
      >
        OUI
      </Button>
      <Button
        className="addhours-row-item-btn-no"
        style={ {
          backgroundColor: status === 0 ? colors.red : colors.white,
          color: status === 0 ? colors.white : colors.black,
        } }
        variant="contained"
        onClick={ () => onItemStatusChange(row.id, 0) }
      >
        NON
      </Button>
    </>
  );


  // RENDER PAGE
  return (
    <Fade in={ show }>
      <div className="page referential-page">
        <TopMenu
          title="Heures supplémentaires"
          subtitle="Liste des heures supplémentaires déclarées par les employés"
          yesnoTitle="Validée"
          onFilterChange={ onFilterChange }
        />
        <div className="page-content">
          <DataList
            data={ additionalHoursList }
            className="additionalhours-list"
            columns={ [
              {
                title: 'Employé',
                key: 'user_name',
                style: { flex: 1 },
                dataType: 'user',
              },
              {
                title: 'Date demande',
                key: 'date_request_formatted',
                picto: 'icCalendar',
                style: { width: isMobile ? 200 : 140 },
              },
              {
                title: 'Date heure supp',
                key: 'date_formatted',
                picto: 'icCalendar',
                style: { width: isMobile ? 200 : 140 },
              },
              {
                title: 'Heure début',
                key: 'date_start_formatted',
                picto: 'icTime',
                style: { width: isMobile ? 200 : 130 },
              },
              {
                title: 'Heure fin',
                key: 'date_end_formatted',
                picto: 'icTime',
                style: { width: isMobile ? 200 : 130 },
              },
              {
                title: 'Durée',
                key: 'duration',
                picto: 'icClock',
                style: { width: isMobile ? 200 : 100 },
              },
              {
                title: 'Validée',
                style: { width: isMobile ? 200 : 130 },
                key: 'status',
                render: renderStatus,
              },
            ] }
          />
        </div>
      </div>
    </Fade>
  );
}

AdditionalHours.propTypes = {};

export default AdditionalHours;

import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import LeaveSumary from '../../components/LeaveSumary';

/**
 * VacationDetails react component
 * @returns VacationDetails react component
 */
function VacationDetails() {
  const params = useParams();
  const [mounted, setMounted] = useState(false);
  const {
    data: [users],
  } = dm.useUsers();
  const [data, setData] = useState({});

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const user = users.find((u) => u.id === params.id) || {};
    setData(user);
  }, [users, params.id]);

  return (
    <Fade in={ mounted }>
      <div className="page account-page">
        <PageHeader
          title="Demandes de congés"
          subtitle="Détail des congés d'un salarié sur l'année N"
          backButton
        >
          <h1>{ `${data.first_name} ${data.last_name}` }</h1>
        </PageHeader>
        <div className="page-content">
          <div
            style={ {
              display: 'flex',
              flexDirection: 'row',
              minWidth: 0,
              alignSelf: 'stretch',
              padding: '2rem',
            } }
          >
            <LeaveSumary style={ { flex: 1 } } userId={ data.id } />

            <div
              style={ {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              } }
            >
              <div style={ { fontSize: 'x-large' } }>
                <div>Total jours pris</div>
                <div style={ { color: 'red' } }>{ data.days_of_leave_taken }</div>
              </div>
              <div style={ { fontSize: 'x-large' } }>
                <div>Total jours restant</div>
                <div style={ { color: 'green' } }>
                  { (data.days_off_count || 0) - (data.days_of_leave_taken || 0) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

VacationDetails.propTypes = {};

export default VacationDetails;

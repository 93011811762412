import React, {useEffect, useState} from 'react';
import {
  pdf,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { CircularProgress } from '@material-ui/core';
import { config } from '../../config';
import { Button } from '../ui';
import loadImage from 'blueimp-load-image'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  sectionTitle: {
    fontSize: 18,

  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  mediaContainer: {
    margin: 10,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'lightgrey',
    //maxWidth: 220,
    maxHeight: 240,
  },
  imageList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  image: {
    backgroundColor: 'lightgrey',
    borderRadius: 0,
    height: 200,
  },
  footer: {
    height: 100,
    margin: 10,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  endormentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  endorsment: {
    backgroundColor: 'grey',
    maxHeight: 200,
    maxWidth: 180,
  },
});

/**
 * ImageList react component
 * @param {object} medias to list
 * @returns ImageList react component
 */
const ImageList = ({ medias }) => {
  return (
  <View style={ styles.imageList }>
    { medias?.map((media) => {
      return (
      <View key={ media.id } style={ styles.mediaContainer } wrap={false}>
        <Image
          style={ styles.image }
          cache={false}
          allowDangerousPath 
          src={ () => loadImage(`${config.baseURLApi}/media/${media.file_path}`, {canvas: true ,orientationtion: true,crossOrigin: "Anonymous" }).then(res=> {
            const {image: canvas, originalWidth, originalHeight} = res
            const uri = canvas.toDataURL("image/jpeg");
            return uri
            return fetch(uri).then(response => response.arrayBuffer()
            ).then(buffer => {
              const data = new Uint8Array(buffer);
              console.log('dara', data)
              return {data}
            })
          
          })
        }
        />
        <Text>{ `${media.name || ''}` }</Text>
      </View>
    
    )}) }
  </View>
)};

// Create Document Component
/**
 * QuitusDocument react component
 * @param {object} QuitusDocument parameters
 * @returns QuitusDocument react component
 */
const QuitusDocument = ({ data, users }) => {
  const assignedUser = users?.find((u) => `user/${u.id}` === data.assigned) || {};
  const endorsment = data?.medias?.filter((m) => m.is_signature)[0];
  return (
    <Document>
      <Page size="A4" style={ styles.page }>
        <Text style={ styles.title }>QUITUS INTERVENTION</Text>
        <View style={ styles.section }>
          <View style={ styles.row }>
            <View style={ styles.column }>
              <Text style={ styles.text }>
                { `INTERVENTION N° ${
                  data.interventionId || '-'
                }` }
              </Text>
              <Text style={ styles.text }>
                { `${assignedUser.first_name || ''} ${
                  assignedUser.last_name || ''
                }` }
              </Text>
            </View>
            <View style={ styles.column }>
              <Text style={ styles.text }>
                { `${
                  data.interventionAddress.display_name || ''
                }\n${data.interventionPostcode || ''} ${
                  data.interventionMunicipality || ''
                }` }
              </Text>
              <Text style={ styles.text }>
                { `${data.occupantName || ''}\n${
                  data.occupantPhone || ''
                } ` }
              </Text>
            </View>
          </View>
              <View style={ {...styles.row, marginTop: 10} }>
            <View style={ styles.column }>
                <Text>{`Tranche : ${data.tranche}`}</Text>
                <Text>{`Logement : ${data.logement}`}</Text>
                </View>
            <View style={ styles.column }>
                <Text>{`Ensemble : ${data.ensemble}`}</Text>
                <Text>{`Etage : ${data.etage}`}</Text>
                </View>
            <View style={ styles.column }>
                <Text>{`Escalier : ${data.escalier}`}</Text>
                <Text>{`Module : ${data.module}`}</Text>
                </View>
              </View>
        </View>
        <View style={ styles.section }>
          <View style={ styles.row }>
            <Text style = {styles.sectionTitle}>{`Travaux réalisés le ${format(data.endDate, 'dd/MM/yyyy')}`}</Text>
            {/* <Text style = {styles.sectionTitle}>{format(data.endDate, 'dd/mm/yyyy')}</Text> */}
            <Text>{ `Problème résolu: ${data.resolved ? 'OUI' : 'NON'}` }</Text>
          </View>
         
          <Text>{ data.report }</Text>
        </View>
        <ImageList
          medias={ data?.medias?.filter(
            (m) => !m.is_signature && !m.is_scan && !m.is_quotation,
          ) }
        />

        
            <View style={ styles.section }>
              <Text style = {styles.sectionTitle}>Devis</Text>
              <Text>{ data.quotationDetail }</Text>
            </View>
            <ImageList
              medias={ data?.medias?.filter(
                (m) => m.is_quotation,
              ) }
            />
         
        <View style={ styles.footer } wrap={false}>
          <Text style={ styles.text }>{ `BT N° ${data.workOrderId || ''}` }</Text>
          <View style={ styles.endormentContainer }>
            <Text>Signature client</Text>
            <Image
              style={ styles.endorsment }
              src={ `${config.baseURLApi}/media/${endorsment?.file_path}` }
            />
          </View>
          <View style={ styles.endormentContainer }>
            <Text>Signature technicien</Text>
            <Image
              style={ styles.endorsment }
              src={ `${config.baseURLApi}/media/${assignedUser?.endorsment}` }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

/**
 * QuitusButton react component
 * @param {object} QuitusButton parameters
 * @returns QuitusButton react component
 */
const QuitusButton = ({ data, users }) => {
  const [loading, setLoading] = useState(false)

  /**
   * Callback when click un button to download generated quitus
   */
  const handleClick = async () => {
    setLoading(true)
    const blob = await pdf(<QuitusDocument data={ data } users={ users } />).toBlob();

            const fileURL = URL.createObjectURL(blob);
            setLoading(false)

            window.open(fileURL, "_blank");
  }

  return (
    <Button
        endIcon={<PictureAsPdfOutlinedIcon />}
        onClick = {handleClick}
        color="primary"
        variant="contained"
      >
        Generer quitus
      </Button>

  )
      }

export default QuitusButton;

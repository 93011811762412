import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Switch,
} from '../components/ui';
import './Configuration.scss';
import { useSnack } from '../hooks/useSnack';
import { useApi } from '../hooks/useApi';
/**
 * Configuration react component
 * @returns Configuration react component
 */
function Configuration() {
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [secure, setSecure] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');

  const { addSnack } = useSnack();
  const { get, post } = useApi();

  useEffect(async () => {
    let res = await get('smtp');
    setHost(res.smtp_host);
    setPort(res.smtp_port);
    setSecure(res.smtp_secure);
    setUser(res.smtp_user);
    setPass(res.smtp_pass);
  }, []);

  /**
   * Submit and save now configuration for SMTP
   */
  const handleSubmit = async () => {
    try {
      
      const dataToSave = {
        host,
        port,
        secure,
        user,
        pass,
      };

      await post('smtp', { dataToSave });

      addSnack({
        message: 'configuration enregistrée',
        severity: 'success',
      });
    } catch (e) {
      addSnack({
        message: 'erreur',
        severity: 'error',
      });
    }
  };

  return (
    <div className="account-form">
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="HOST"
          onChange={ setHost }
          value={ host }
        />
      </div>
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="PORT"
          onChange={ setPort }
          value={ port }
        />
        <Switch
          className="account-form-row-item"
          checked={ secure }
          label="SECURE"
          onChange={ ({ target: { checked } }) => setSecure(checked) }
        />
      </div>
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="USER"
          onChange={ setUser }
          value={ user }
        />
        <TextField
          variant="filled"
          label="PASS"
          onChange={ setPass }
          value={ pass }
        />
      </div>
      <div className="account-form-row">
        <Button variant="contained" color="primary" onClick={ handleSubmit }>
          Enregistrer
        </Button>
      </div>
    </div>
  );
}

export default Configuration;

import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import AccountForm from './AccountForm';

/**
 * Account react component
 * @returns Account react component
 */
function Account() {
  const params = useParams();
  const [mounted, setMounted] = useState(false);
  const {
    data: [users],
  } = dm.useUsers();
  const [data, setData] = useState({});

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const user = users.find((u) => u.id === params.id) || {};
    setData(user);
  }, [users, params.id,
  ]);


  return (
    <Fade in={ mounted }>
      <div className="page account-page">
        <PageHeader
          title={ data.id ? `${data.first_name} ${data.last_name}` : 'Nouvel utilisateur' }
          subtitle="Créer, modifier, supprimer le compte"
          backButton
        />
        <div className="page-content">
          <AccountForm data={ data } />
        </div>
      </div>
    </Fade>
  );
}

Account.propTypes = {

};

export default Account;

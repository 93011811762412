"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _index = _interopRequireDefault(require("../index"));

var _authProvider = _interopRequireDefault(require("./authProvider"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
 * Provides allowed jobs data from firebase
 */
var _default = function _default(_ref) {
  var restrictedData = _ref.restrictedData,
      user = _ref.user,
      minDate = _ref.minDate;

  var _useState = (0, _react.useState)(),
      _useState2 = _slicedToArray(_useState, 2),
      error = _useState2[0],
      setError = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = _slicedToArray(_useState3, 2),
      value = _useState4[0],
      setValue = _useState4[1];

  var _useState5 = (0, _react.useState)(true),
      _useState6 = _slicedToArray(_useState5, 2),
      loading = _useState6[0],
      setLoading = _useState6[1];

  if (!_index["default"].firestore) return [[], true];
  var ref = (0, _react.useRef)(_index["default"].firestore.collection('event').where('ts_deleted', '==', null));

  var _AuthProvider = (0, _authProvider["default"])(),
      initializing = _AuthProvider.initializing;

  (0, _react.useEffect)(function () {
    setError(null);

    if (initializing || !ref.current) {
      setValue([]);
      return function () {
        return null;
      };
    }

    console.log('get job', minDate);

    if (restrictedData && !(user !== null && user !== void 0 && user.id)) {
      setValue(undefined);
      return function () {
        return null;
      };
    }

    var listener = ref.current;

    if (restrictedData) {
      var userRef = _index["default"].firestore.collection('user').doc(user.id);

      var limitDate = new Date();
      limitDate.setDate(limitDate.getDate() - 7);
      listener = listener.where('ref_user', '==', userRef).where('date_end', '>', limitDate);
    } else {
      listener = listener.where('date_end', '>', minDate);
    }

    listener = listener.onSnapshot(function (querySnapshot) {
      var values = querySnapshot.docs.map(function (doc) {
        var docData = doc.data ? doc.data() : doc;

        var data = _objectSpread(_objectSpread({}, docData), {}, {
          medias: (docData.medias || []).filter(function (m) {
            return !m.ts_deleted;
          }),
          id: doc.id
        });

        return data;
      }).filter(function (d) {
        return !d.ts_deleted;
      });
      setError(null);
      setValue(_toConsumableArray(values));
      setLoading(false);
    }, function (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError(err);
    });
    return function () {
      listener();
    };
  }, [ref.current, user === null || user === void 0 ? void 0 : user.id, minDate, initializing]);
  var resArray = [value, loading, error];
  return (0, _react.useMemo)(function () {
    return resArray;
  }, resArray);
};

exports["default"] = _default;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Link, Redirect,
} from 'react-router-dom';
import {
  Typography,
  Zoom,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import { dataManager } from '@ferrero/comon';
import { Button, InfoIcon } from '../../components/ui';
import '../Login/Login.scss';
import Picto from '../../components/ui/Picto';
import PasswordInput from '../Login/PasswordInput';
import colors from '../../styles/colors.scss';
import { useSnack } from '../../hooks/useSnack';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        'border-radius': 41,
        height: 41,
        margin: 20,
        width: 350,
      },
      containedPrimary: {
        backgroundColor: 'white',
        color: '#007CC0',
        '&:hover': {
          backgroundColor: '#007CC0',
          color: 'white',
        },
      },
      text: {
        color: 'white',
      },
    },
  },
});

const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#=?_.,+;€{}/\\-$%^&*])(?=.{6,})',
);

/**
 * ResetPassword react component
 * @param {object} ResetPassword parameters
 * @returns ResetPassword react component
 */
function ResetPassword({ code }) {
  const [mounted, setMounted] = useState(false);
  const [unMounted, setUnMounted] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const { addSnack } = useSnack();

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  /**
   * Validate a reset password
   * @param {event} e validate event 
   */
  const handleValidate = async (e) => {
    e.preventDefault();
    dataManager.firebaseAuth
      .confirmPasswordReset(code, password)
      .then(() => {
        setTimeout(() => setUnMounted(true), 500);
        addSnack({
          message: 'mot de passe modifié',
          severity: 'success',
        });
      })
      .catch(() => {
        addSnack({
          message: 'code invalide',
          severity: 'error',
        });
      });
  };

  if (unMounted) {
    return <Redirect to="/" />;
  }

  const valid = password
    && password === password2
    && password
    && passwordRegex.test(password);

  return (
    <ThemeProvider theme={ theme }>
      <Zoom in={ mounted }>
        <div className="login-card-container">
          <form className="login-form">
            <div className="login-picto-group">
              <Picto size={ 64 } type="icLeak" />
              <Picto size={ 128 } type="icWorker" />
              <Picto size={ 64 } type="icRadiator" />
            </div>
            <InfoIcon
              color={ colors.white }
              title={ (
                <>
                  <Typography color="inherit">
                    Sécurité du mot de passe
                  </Typography>
                  <ul>
                    <li>6 caractères minimum</li>
                    <li>Un caractère en majuscule</li>
                    <li>Un caractère en minuscule</li>
                    <li>Un caractère spécial</li>
                  </ul>
                </>
              ) }
            />
            <PasswordInput
              id="password"
              type="password"
              placeholder="NOUVEAU MOT DE PASSE"
              value={ password }
              className="login-input"
              onChange={ ({ target: { value } }) => setPassword(value) }
            />
            <PasswordInput
              id="password2"
              type="password"
              placeholder="CONFIRMER LE MOT DE PASSE"
              value={ password2 }
              className="login-input"
              onChange={ ({ target: { value } }) => setPassword2(value) }
            />
            <Button
              onClick={ handleValidate }
              type="submit"
              disabled={ !valid }
              className="loginFormItem"
              variant="contained"
              color="primary"
              id="connection"
            >
              réinitialiser mon mot de passe
            </Button>
            <Link to="/login">
              <p id="connect">se connecter</p>
            </Link>
          </form>
        </div>
      </Zoom>
    </ThemeProvider>
  );
}

ResetPassword.propTypes = {
  code: PropTypes.string,
};

export default ResetPassword;

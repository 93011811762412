import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Button } from '../ui';

/**
 * React component to upload an image
 * @param {function} onAddImages callback on image added
 * @returns {render} upload image react component
 */
const AddImage = ({ onAddImages }) => {
  const [isAddingFile, setIsAddingFile] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsAddingFile(acceptedFiles.length > 0);
      if (onAddImages) onAddImages(acceptedFiles);
      setIsAddingFile(false);
    },
    [onAddImages],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpg, image/jpeg, application/pdf',
  });

  return (
    <div { ...getRootProps() }>
      <input className="add-image-dropzone-input" { ...getInputProps() } />
      <Button color="primary" variant="contained">
        { isAddingFile ? <div>En cours</div> : <div>Ajouter</div> }
      </Button>
    </div>
  );
};

AddImage.propTypes = {
  onAddImages: PropTypes.func.isRequired,
};

export default AddImage;

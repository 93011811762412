import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as icButtonEmpty } from '../../assets/ic_button_empty.svg';
import { ReactComponent as icButtonFilled } from '../../assets/ic_button_filled.svg';
import { ReactComponent as icButton } from '../../assets/ic_button.svg';
import { ReactComponent as icCheckNo } from '../../assets/ic_check_no.svg';
import { ReactComponent as icCheckYes } from '../../assets/ic_check_yes.svg';
import { ReactComponent as icDragAndDrop } from '../../assets/ic_drag_and_drop_hand.svg';
import { ReactComponent as icLeak } from '../../assets/ic_leak.svg';
import { ReactComponent as icPower } from '../../assets/ic_power.svg';
import { ReactComponent as icRadiator } from '../../assets/ic_radiators.svg';
import { ReactComponent as icWorker } from '../../assets/ic_worker.svg';
import { ReactComponent as icPerson } from '../../assets/ic_person.svg';
import { ReactComponent as icClose } from '../../assets/ic_close.svg';
import { ReactComponent as icPDF } from '../../assets/ic_pdf.svg';
import { ReactComponent as icCalendar } from '../../assets/ic_calendar.svg';
import { ReactComponent as icBill } from '../../assets/ic_bill.svg';
import { ReactComponent as icEye } from '../../assets/ic_eye.svg';
import { ReactComponent as icEyeClosed } from '../../assets/ic_eye_closed.svg';
import { ReactComponent as icUser } from '../../assets/ic_user.svg';
import { ReactComponent as icTrash } from '../../assets/ic_trash.svg';
import { ReactComponent as icClock } from '../../assets/ic_clock.svg';
import { ReactComponent as icTime } from '../../assets/ic_time.svg';
import { ReactComponent as icCheckmark } from '../../assets/ic_checkmark.svg';
import { ReactComponent as icLabel } from '../../assets/ic_label.svg';
import { ReactComponent as icPlus } from '../../assets/ic_plus.svg';

const pictoSrc = {
  icButtonEmpty,
  icButtonFilled,
  icButton,
  icCheckNo,
  icCheckYes,
  icDragAndDrop,
  icLeak,
  icPower,
  icRadiator,
  icWorker,
  icPerson,
  icClose,
  icPDF,
  icTrash,
  icUser,
  icEye,
  icEyeClosed,
  icBill,
  icCalendar,
  icClock,
  icTime,
  icCheckmark,
  icLabel,
  icPlus,
};

/**
 * Picto react component
 * @param {object} Picto parameters
 * @returns Picto react component
 */
const Picto = ({
  type, size, style, ...other
}) => {
  if (!type) {
    return <div style={ { width: size, height: size, ...style } } { ...other } />;
  }
  const Icon = pictoSrc[type] || pictoSrc.icCube;
  return <Icon width={ size } height={ size } style={ style } { ...other } />;
};

Picto.propTypes = {
  type: PropTypes.oneOf(Object.keys(pictoSrc)).isRequired,
  size: PropTypes.number,
  fill: PropTypes.string,
  style: PropTypes.object,
};

Picto.defaultProps = {
  size: 48,
  fill: '#FFFFFF',
};
export default Picto;

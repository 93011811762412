require('dotenv').config();

const hostApi = process.env.REACT_APP_URL_BACK;
const portApi = process.env.REACT_APP_PORT_BACK;
const portFront = process.env.PORT;
const hostFront = process.env.REACT_APP_URL_FRONT;
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ''}`;

export const config = {
  hostApi,
  portApi,
  baseURLApi,
  portFront,
  hostFront,
};

export default config;

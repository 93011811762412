import User from './User';
import Color from './Color';


export const RENDER_TYPE = {
  user: User,
  color: Color,
};

export default RENDER_TYPE;

import React, { useEffect, useState } from 'react';
import {
  Link, Redirect,
  useParams,
} from 'react-router-dom';
import {
  Typography,
  Zoom,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import logo from '../../assets/logo.svg';
import { Button, InfoIcon } from '../../components/ui';
import './Login.scss';
import Picto from '../../components/ui/Picto';
import PasswordInput from './PasswordInput';
import colors from '../../styles/colors.scss';
import { useSnack } from '../../hooks/useSnack';
import { useApi } from '../../hooks/useApi';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        'border-radius': 41,
        height: 41,
        margin: 20,
        width: 350,
      },
      containedPrimary: {
        backgroundColor: 'white',
        color: '#007CC0',
        '&:hover': {
          backgroundColor: '#007CC0',
          color: 'white',
        },
      },
      text: {
        color: 'white',
      },
    },
  },
});

const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#=?_.,+;€{}/\\-$%^&*])(?=.{6,})',
);

function ResetPassword() {
  const [mounted, setMounted] = useState(false);
  const [unMounted, setUnMounted] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const { addSnack } = useSnack();
  const params = useParams();

  const { post } = useApi();

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const handleValidate = async (e) => {
    try {
      e.preventDefault();
      const { userId, token } = params;
      await post('reset-password/set-password', {
        userId,
        token,
        password,
      });
      addSnack({
        message: 'mot de passe modifié',
        severity: 'success',
      });
      setMounted(false);
      setUnMounted(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      addSnack({
        message: 'une erreur est survenue',
        severity: 'error',
      });
    }
  };


  const valid = password
  && password === password2
  && password
  && passwordRegex.test(password);

  if (unMounted) {
    return <Redirect to="/" />;
  }

  return (
    <ThemeProvider theme={ theme }>
      <div className="page login-page">
        <img alt="logo" className="login-logo" src={ logo } />
        <Zoom in={ mounted }>
          <div className="login-card-container">
            <form className="login-form">
              <div className="login-picto-group">
                <Picto size={ 64 } type="icLeak" />
                <Picto size={ 128 } type="icWorker" />
                <Picto size={ 64 } type="icRadiator" />
              </div>
              <InfoIcon
                color={ colors.white }
                title={ (
                  <>
                    <Typography color="inherit">
                      Sécurité du mot de passe
                    </Typography>
                    <ul>
                      <li>6 caractères minimum</li>
                      <li>Un caractère en majuscule</li>
                      <li>Un caractère en minuscule</li>
                      <li>Un caractère spécial</li>
                    </ul>
                  </>
                ) }
              />
              <PasswordInput
                id="password"
                type="password"
                placeholder="NOUVEAU MOT DE PASSE"
                value={ password }
                className="login-input"
                onChange={ ({ target: { value } }) => setPassword(value) }
              />
              <PasswordInput
                id="password2"
                type="password"
                placeholder="CONFIRMER LE MOT DE PASSE"
                value={ password2 }
                className="login-input"
                onChange={ ({ target: { value } }) => setPassword2(value) }
              />
              <Button
                onClick={ handleValidate }
                type="submit"
                disabled={ !valid }
                className="loginFormItem"
                variant="contained"
                color="primary"
                id="connection"
              >
                réinitialiser mon mot de passe
              </Button>
              <Link to="/login">
                <p id="connect">se connecter</p>
              </Link>
            </form>
          </div>
        </Zoom>
      </div>
    </ThemeProvider>
  );
}

ResetPassword.propTypes = {
};

export default ResetPassword;

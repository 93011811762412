import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from '../components/ConfirmationDialog';

const ConfirmationDialogContext = React.createContext({});

/**
 * ConfirmationDialogProvider react component
 * @param {object} ConfirmationDialogProvider parameters
 * @returns ConfirmationDialogProvider react component
 */
export const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});

  /**
   * Open confirmation dialog
   * @param {object} param containing title, message and action callback
   */
  const openDialog = ({ title, message, actionCallback }) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  /**
   * Reset dialog content
   */
  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  /**
   * Callback on dialog confirm
   */
  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };
  /**
   * Callback on dialog dismiss
   */
  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={ { openDialog } }>
      <ConfirmationDialog
        open={ dialogOpen }
        title={ dialogConfig?.title }
        message={ dialogConfig?.message }
        onConfirm={ onConfirm }
        onDismiss={ onDismiss }
      />
      { children }
    </ConfirmationDialogContext.Provider>
  );
};

ConfirmationDialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) => new Promise((resolve) => {
    openDialog({ actionCallback: resolve, ...options });
  });

  return { getConfirmation };
};

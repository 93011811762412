"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.distance = distance;
exports["default"] = void 0;

/**
 * Calculates the distance between two geocoordinates
 * @param {float} lat1 latitude 1  
 * @param {float} lon1 longitude 1
 * @param {float} lat2 latitude 2
 * @param {float} lon2 longitude 2
 * @param {float} unit unit to mesure
 * @returns {float} the distance between the two geocoordinates 
 */
function distance(lat1, lon1, lat2, lon2) {
  var unit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'K';

  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  var radlat1 = Math.PI * lat1 / 180;
  var radlat2 = Math.PI * lat2 / 180;
  var theta = lon1 - lon2;
  var radtheta = Math.PI * theta / 180;
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === 'K') {
    dist *= 1.609344;
  }

  if (unit === 'N') {
    dist *= 0.8684;
  }

  return dist;
}

var _default = {
  distance: distance
};
exports["default"] = _default;
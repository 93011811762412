import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '../../components/ui';

/**
 * ErrorBadCode react component
 * @returns ErrorBadCode react component
 */
function ErrorBadCode() {
  return (
    <div
      style={ {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '2rem',
      } }
    >
      <Link to="/login">
        <p id="connect">se connecter</p>
      </Link>
      <Alert severity="error">code invalide</Alert>
    </div>
  );
}

ErrorBadCode.propTypes = {};

export default ErrorBadCode;

import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import WatchLaterOutlined from '@material-ui/icons/WatchLaterOutlined';
import DateFnsUtils from '@date-io/date-fns';

// eslint-disable-next-line no-unused-vars
import { fr } from 'date-fns/locale';

/**
 * TimeField react component
 * @param {object} TimeField parameters
 * @returns TimeField react component
 */
function TimeField({ variant, ...props }) {
  return (
    <div className="container">
      <MuiPickersUtilsProvider
        locale={ fr }
        utils={ DateFnsUtils }
      >
        <KeyboardTimePicker
          autoOk
          size="small"
          inputVariant={ variant }
          label="Time picker inline"
          minutesStep={ 5 }
          ampm={ false }
          keyboardIcon={ <WatchLaterOutlined /> }
          { ...props }
          variant="inline"
          KeyboardButtonProps={ {
            'aria-label': 'change time',
          } }
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

TimeField.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
};
TimeField.defaultProps = {
  color: 'secondary',
  variant: 'filled',
};
export default TimeField;

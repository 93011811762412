import React, { useEffect, useState } from 'react';
import { dataManager as dm } from '@ferrero/comon';

import { Select } from '../ui';
/**
 * LessorSelect react component
 * @param {object} LessorSelect parameters
 * @returns LessorSelect react component
 */
function LessorSelect(props) {
  const [options, setOptions] = useState([]);
  const {
    data: [lessors],
  } = dm.useLessors();

  useEffect(
    () => {
      setOptions(
        (lessors || []).map((lessor) => ({
          value: `lessor/${lessor.id}`,
          label: `${lessor.name}`,
        })),
      );
    },
    [lessors],
  );
  return <Select options={ options } { ...props } />;
}

LessorSelect.propTypes = {};

export default LessorSelect;

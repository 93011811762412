import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

/**
 * PageHeader react component
 * @param {object} PageHeader parameters
 * @returns PageHeader react component
 */
function PageHeader({
  title, subtitle, backButton, children,
}) {
  const history = useHistory();

  return (
    <div className="page-header">
      <p className="page-header-title">
        { backButton && (
          <IconButton onClick={ () => history.goBack() }>
            <ArrowBackIcon />

          </IconButton>
        ) }
        { title }
      </p>
      <p className="page-header-subtitle">{ subtitle }</p>
      { children }
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backButton: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageHeader;

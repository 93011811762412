import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, BrowserRouter } from 'react-router-dom';
import { dataManager as dm } from '@ferrero/comon';
import Login from './pages/Login';
import AppLayout from './AppLayout';
import { Loader } from './components/ui';
import ActionHandler from './pages/ActionHandler';
import ResetPassword from './pages/Login/ResetPassword';

function PrivateRoute({ children, ...rest }) {
  const { user } = dm.useAuth();

  return (
    <Route
      { ...rest }
      render={ ({ location }) => (user
        ? children
        : (
          <Redirect to={ {
            pathname: '/login',
            state: { from: location },
          } }
          />
        )) }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const Router = () => {
  const { initializing } = dm.useAuth();
  if (initializing) {
    return (
      <Loader style={ { margin: 'auto' } } />
    );
  }
  return (
    <BrowserRouter>
      <Route path="/" exact>
        <Redirect to="/app" />
      </Route>
      <PrivateRoute path="/app">
        <AppLayout />
      </PrivateRoute>
      <Route path="/login" exact component={ Login } />
      <Route path="/reset-password/:userId/:token" exact component={ ResetPassword } />
      <Route path="/actions" exact component={ ActionHandler } />
    </BrowserRouter>
  );
};


export default Router;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useAuth = void 0;

var _react = require("react");

var _Provider = require("../Provider");

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-cycle

/**
 * Hook to get the authentication context
 * @returns {object} authentication context
 */
var useAuth = function useAuth() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  if (!ctx) {
    throw Error('The `useAuth` hook must be called from a descendent of the `DataProvider`.');
  }

  return ctx.auth;
};

exports.useAuth = useAuth;
var _default = useAuth;
exports["default"] = _default;
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import classnames from 'classnames';
import { dataManager as dm } from '@ferrero/comon';
import { Badge } from '@material-ui/core';
import logo from '../../assets/logo.svg';
import Picto from '../ui/Picto';

const routes = {
  Référentiel: 'referential',
  Planning: 'planning',
  'Notes de frais': 'expense',
  'Heures Sup.': 'extra_hours',
  Congés: 'holidays',
  Utilisateurs: 'accounts',
  Notifications: 'notifications',
  Configuration: 'configuration',
};
/**
 * Menu react component
 * @returns Menu react component
 */
function Menu() {
  const { user, logout } = dm.useAuth();
  const { path } = useRouteMatch();
  const [mounted, setMounted] = useState(false);
  const match = useRouteMatch({
    path: `${path}/:item`,
    sensitive: true,
  });
  const { unReadCount } = dm.useNotifications();
  const badges = { Notifications: unReadCount };
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <Slide direction="right" in={ mounted }>
      <div className="menu">
        <img alt="logo" className="menu-logo" src={ logo } />
        <div className="menu-picto-group">
          <Picto size={ 48 } type="icLeak" />
          <Picto size={ 96 } type="icWorker" />
          <Picto size={ 48 } type="icRadiator" />
        </div>
        <div className="menuItem">
          <Picto fill="#FFFF" size={ 24 } type="icPerson" />
          { `${user.first_name ? user.first_name.toUpperCase() : ''} ${
            user.last_name ? user.last_name.toUpperCase() : ''
          }` }
        </div>
        { Object.entries(routes).map(([label, route]) => (
          <Link key={ route } to={ `${path}/${route}` }>
            <div
              className={ classnames('menuItem', {
                'menuItem-selected': match && route === match.params.item,
              }) }
            >
              <Badge
                badgeContent={ badges[label] }
                color="error"
                style={ { paddingRight: 10 } }
              >
                <Picto
                  size={ 24 }
                  type={
                    match && route === match.params.item
                      ? 'icCheckYes'
                      : 'icCheckNo'
                  }
                />
                { `${label}` }
              </Badge>
            </div>
          </Link>
        )) }
        <Link to="/" onClick={ logout }>
          <div className="menuItem clickable">
            <Picto size={ 24 } type="icPower" />
            Déconnexion
          </div>
        </Link>
      </div>
    </Slide>
  );
}

Menu.propTypes = {};

export default Menu;

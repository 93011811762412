import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import randomColor from 'randomcolor';
import IconButton from './IconButton';

/**
 * ColorPicker react component
 * @param {object} ColorPicker parameters
 * @returns ColorPicker react component
 */
function ColorPicker({
  value, onChange, style, ...other
}) {
  const [open, setOpen] = useState(false);
  const [presetColor, setPresetColor] = useState(randomColor({
    count: 16,
    luminosity: 'dark',
  }));
  const ref = useRef();

  /**
   * Callback when picker value changes
   * @param {object} color selected color 
   */
  const handleChangeComplete = (color) => {
    onChange(color.hex);
  };

  /**
   * Callback when color button clicked
   */
  const handleClick = () => {
    setPresetColor(randomColor({
      count: 16,
      luminosity: 'dark',
    }));
    setOpen(true);
  };
  return (
    <>
      <IconButton variant="contained" color="primary" onClick={ handleClick }>
        <div
          ref={ ref }
          style={ {
            height: 30, width: 30, backgroundColor: value, ...style,
          } }
          { ...other }
        />
      </IconButton>
      <Popover
        open={ open }
        anchorEl={ ref.current }
        onClose={ () => setOpen(false) }
      >
        <SketchPicker
          color={ value }
          onChangeComplete={ handleChangeComplete }
          disableAlpha
          presetColors={ presetColor }
        />
      </Popover>

    </>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default ColorPicker;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm, constants } from '@ferrero/comon';
import randomColor from 'randomcolor';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  PasswordField,
  Select,
  TextField,
  Switch,
} from '../../components/ui';
import './AccountForm.scss';
import { config } from '../../config';
import ColorPicker from '../../components/ui/ColorPicker';
import { StyledButton } from '../../components/ui/Button';
import { useSnack } from '../../hooks/useSnack';
import { useApi } from '../../hooks/useApi';
import { useConfirmationDialog } from '../../hooks/ConfirmDialog';

const ACCOUNT_TYPE_OTIONS = Object.entries(
  constants.ACCOUNT_TYPE,
).map(([key, value]) => ({ value: key, label: value.label }));

const FB_ERROR = {
  'auth/email-already-exists': 'cet email est déjas utilisé',
  'auth/invalid-email': 'email non valide',
  'auth/invalid-password': 'Le mot de passe doit faire au moins  6 caratères',
};

/**
 * AccountForm react component
 * @param {object} AccountForm parameters
 * @returns AccountForm react component
 */
function AccountForm({ data }) {
  const { getConfirmation } = useConfirmationDialog();
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState(data.first_name || '');
  const [lastName, setLastName] = useState(data.last_name || '');
  const [email, setEmail] = useState(data.email || '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [phone, setPhone] = useState(data.phone || '');
  const [accountType, setAccountType] = useState(data.account_type || '0');
  const [address, setAddress] = useState(data.address || '');
  const [zipCode, setZipCode] = useState(data.zip_code || '');
  const [city, setCity] = useState(data.city || '');
  const [daysOffCount, setDaysOffCount] = useState(data.days_off_count || '');
  const { user: currentUser } = dm.useAuth();

  const [status, setStatus] = useState(data.status || 1);
  const [color, setColor] = useState(
    data.color
      || randomColor({
        luminosity: 'dark',
      }),
  );
  const [changePassword, setChangePassword] = useState(!data.id);
  const { upsert } = dm.useUsers();
  const history = useHistory();
  const { addSnack } = useSnack();
  const { post } = useApi();

  useEffect(() => {
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setEmail(data.email);
    setPhone(data.phone);
    setAccountType(data.account_type);
    setAddress(data.address);
    setZipCode(data.zip_code);
    setCity(data.city);
    setDaysOffCount(data.days_off_count);
    setStatus(data.status ?? 1);
    setColor(data.color);
    setChangePassword(!data.id);
    setErrors({});
    if (!data.id) {
      setColor(
        randomColor({
          luminosity: 'dark',
        }),
      );
    }
  }, [data]);

  /**
   * Validate the account form
   * @returns {object} form errors
   */
  const validate = () => {
    const err = {
      email: !email && "l'email est obligatoire.",
      firstName: !firstName && 'le prénom est obligatoire.',
      lastName: !lastName && "l'email est obligatoire.",
      accountType: !accountType && 'le type de compte est obligatoire',
      password:
        changePassword && !password && 'le mot de passe est obligatoire.',
      passwordConfirm:
        changePassword
        && password !== passwordConfirm
        && 'les mot de passe ne correspondent pas.',
    };
    setErrors(err);
    return err;
  };
  useEffect(() => {
    setErrors(null);
  }, [email, firstName, lastName, changePassword, password, passwordConfirm]);

  /**
   * Check if the account form is valid
   * @param {array} err errors when exists 
   * @returns {boolean} true when the account form is valid
   */
  const isValide = (err = errors) => Object.values(err).filter((e) => !!e).length === 0;


  /**
   * Delete an account
   * @returns void
   */
  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: 'Supprimer compte!',
      message: 'Voulez-vous vraiment supprimer ce compte ?',
    });

    if (!confirmed) return;

    // TODO if user is set to disable
    // TODO check for already planned interventions
    // TODO display update existing interventions dialog
    // TODO update existing interventions

    try {
      await upsert(data.id, { ts_deleted: new Date() });
      addSnack({
        message: 'utilisateur supprimé',
        severity: 'success',
      });
      history.push('/app/accounts');
    } catch (e) {
      addSnack({
        message: 'erreur',
        severity: 'error',
      });
    }
  };

  /**
   * Insert/update an account
   * @returns void
   */
  const handleSubmit = async () => {
    const err = validate();
    if (!isValide(err)) return;
    try {
      let { id } = data;
      if (changePassword) {
        if (!data.id) {
          const r = await post('account/firebase', {
            email,
            password,
          });
          if (!r.uid) {
            addSnack({
              message: FB_ERROR[r.code] || 'erreur',
              severity: 'error',
            });

            return;
          }
          id = r.uid;
        } else if (password) {
          await post(`account/firebase/${data.id}`, {
            email,
            password,
          });
        }
      }
      const dataToSave = {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        account_type: accountType,
        address,
        zip_code: zipCode,
        city,
        days_off_count: daysOffCount,
        endorsement: data.endorsement,
        status,
        color,
      };

      // TODO if user is set to disable
      // TODO check for already planned interventions
      // TODO display update existing interventions dialog
      // TODO update existing interventions

      await upsert(data.id, dataToSave);

      addSnack({
        message: 'utilisateur enregistré',
        severity: 'success',
      });
      history.push('/app/accounts');
    } catch (e) {
      addSnack({
        message: 'erreur',
        severity: 'error',
      });
    }
  };

  return (
    <div className="account-form">
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="Prénom"
          onChange={ setFirstName }
          value={ firstName }
          error={ errors?.firstName }
          helperText={ errors?.firstName }
        />
        <TextField
          variant="filled"
          label="Nom"
          onChange={ setLastName }
          value={ lastName }
          error={ errors?.lastName }
          helperText={ errors?.lastName }
        />
      </div>
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="Téléphone"
          onChange={ setPhone }
          value={ phone }
        />
        <TextField
          variant="filled"
          label="Email"
          InputProps={ {
            readOnly: data.id,
          } }
          onChange={ setEmail }
          value={ email }
          autoComplete="off"
          error={ errors?.email }
          helperText={ errors?.email }
        />
      </div>
      <div className="account-form-row">
        { changePassword ? (
          <>
            <PasswordField
              variant="filled"
              label="Mot de passe"
              onChange={ setPassword }
              value={ password }
              autoComplete="new-password"
              error={ errors?.password }
              helperText={ errors?.password }
            />
            <PasswordField
              variant="filled"
              label="Confirmer le mot de passe"
              onChange={ setPasswordConfirm }
              value={ passwordConfirm }
              autoComplete="new-password"
              error={ errors?.passwordConfirm }
              helperText={ errors?.passwordConfirm }
            />
          </>
        ) : (
          <Button onClick={ () => setChangePassword(true) }>
            Modifier le mot de passe
          </Button>
        ) }
      </div>
      <Select
        options={ ACCOUNT_TYPE_OTIONS }
        variant="filled"
        label="Type de compte"
        onChange={ setAccountType }
        value={ accountType }
        error={ errors?.accountType }
        helperText={ errors?.accountType }
      />
      <TextField
        variant="filled"
        label="Addresse"
        onChange={ setAddress }
        value={ address }
      />
      <div className="account-form-row">
        <TextField
          variant="filled"
          label="Ville"
          onChange={ setCity }
          value={ city }
        />
        <TextField
          variant="filled"
          label="Code postal"
          onChange={ setZipCode }
          value={ zipCode }
        />
      </div>
      <div className="account-form-row">
        <TextField
          variant="filled"
          type="number"
          InputLabelProps={ {
            shrink: true,
          } }
          label="Jours de congés"
          onChange={ setDaysOffCount }
          value={ daysOffCount }
        />
        <Link className="holiday-detail" to={ `${data.id}/vacations` }>Détail congés</Link>
      </div>
      { data.endorsment ? (
        <img
          alt="signature"
          className="account-form-endorsment"
          src={ `${config.baseURLApi}/media/${data.endorsment}` }
        />
      ) : (
        <div className="account-form-endorsment">Pas de signature</div>
      ) }
      <div className="account-form-row">
        <Switch
          className="account-form-row-item"
          checked={ status }
          label={ constants.ACCOUNT_STATUS[status || 0]?.label }
          onChange={ ({ target: { checked } }) => setStatus(checked ? 1 : 0) }
        />
        <div className="account-form-row-item">
          <ColorPicker value={ color } onChange={ setColor } />
        </div>
      </div>
      <div className="account-form-row">
        <StyledButton
          variant="contained"
          color="red"
          onClick={ handleDelete }
          disabled={ !data.id || data.id === currentUser.id }
        >
          Supprimer
        </StyledButton>
        <Button variant="contained" color="primary" onClick={ handleSubmit }>
          Enregistrer
        </Button>
      </div>
    </div>
  );
}

AccountForm.propTypes = {
  data: PropTypes.object,
};

export default AccountForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm } from '@ferrero/comon';
import InputAdornment from '@material-ui/core/InputAdornment';
import PlaceOutlined from '@material-ui/icons/PlaceOutlined';
import { useSnack } from '../../hooks/useSnack';
import PdfParser from '../PdfParser';
import UserSelect from '../UserSelect';
import LessorSelect from '../LessorSelect';
import {
  Button, DateField, TimeField, TextField, AddressField,
} from '../ui';
import colors from '../styles/colors.scss';

/**
 * JobForm react component
 * @param {object} JobForm parameters
 * @returns JobForm react component
 */
function JobForm({ values, onChange }) {
  const { addSnack } = useSnack();

  /* =====================
    STATE GETTERS / SETTERS
  ======================== */

  const [title, setTitle] = useState(values.title || '');
  const [assigned, setAssigned] = useState(values.assigned || '');
  const [description, setDescription] = useState(values.description || '');
  const [lessor, setLessor] = useState(values.lessor || '');
  const [program, setProgram] = useState(values.program || '');
  const [tranche, setTranche] = useState(values.tranche || '');
  const [ensemble, setEnsemble] = useState(values.ensemble || '');
  const [escalier, setEscalier] = useState(values.escalier || '');
  const [logement, setLogement] = useState(values.logement || '');
  const [etage, setEtage] = useState(values.etage || '');
  const [module, setModule] = useState(values.module || '');
  const [workOrderId, setWorkOrderId] = useState(values.workOrderId || '');
  const [interventionId, setInterventionId] = useState(
    values.interventionId || '',
  );
  const [occupantName, setOccupantName] = useState(values.occupantName || '');
  const [occupantPhone, setOccupantPhone] = useState(
    values.occupantPhone || '',
  );
  const [report, setReport] = useState(values.report || '');

  /**
   * callback when start date changes
   * @param {string} startDate new start date
   */
  const handleChangeStartDate = (startDate) => {
    setStartDate(startDate);
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setendDate(new Date(startDate).addHours(1));
    }
  };
  const [startDate, setStartDate] = useState(
    // eslint-disable-next-line no-nested-ternary
    values.startDate && values.startDate.toDate
      ? values.startDate.toDate()
      : values.startDate
        ? values.startDate
        : new Date(),
  );
  const [endDate, setendDate] = useState(
    // eslint-disable-next-line no-nested-ternary
    values.endDate && values.endDate.toDate
      ? values.endDate.toDate()
      : values.endDate
        ? values.endDate
        : new Date().addHours(1),
  );

  const [startAddress, setStartAddress] = useState({
    display_name: values.startAddress ? values.startAddress.display_name : '',
    address: {
      postcode: values.startPostcode ? values.startPostcode : '',
      municipality: values.interventionPostcode
        ? values.interventionPostcode
        : '',
    },
  });
  /**
   * callback when start address changes
   * @param {string} address new start address
   */
  const handleChangeStartAddress = (address) => {
    setStartAddress(address);
    setStartPostcode(address.address.postcode);
    setStartMunicipality(address.address.municipality);
  };
  const [startMunicipality, setStartMunicipality] = useState(
    values.startMunicipality || '',
  );
  const [startPostcode, setStartPostcode] = useState(
    values.startPostcode || '',
  );

  const [interventionAddress, setInterventionAddress] = useState({
    display_name: values.interventionAddress
      ? values.interventionAddress.display_name
      : '',
    address: {
      postcode: values.interventionPostcode ? values.interventionPostcode : '',
      municipality: values.interventionMunicipality
        ? values.interventionMunicipality
        : '',
    },
  });
  /**
   * callback when intervention address changes
   * @param {string} address new intervention address
   */
  const handleChangeInterventionAddress = (address) => {
    setInterventionAddress({ ...address });
    setLatitude('');
    setLongitude('');
  };
  const [interventionPostcode, setInterventionPostcode] = useState(
    values.interventionPostcode || '',
  );
  const [interventionMunicipality, setInterventionMunicipality] = useState(
    values.interventionMunicipality || '',
  );
  const [latitude, setLatitude] = useState(values.latitude || '');
  const [longitude, setLongitude] = useState(values.longitude || '');

  /* =====================
      SCAN BT 13 HABITAT
  ======================== */
  /**
   * Callback when scan BT is completed, sets the states
   * @param {object} res the scan result
   * @param {file} fileScanned the scanned file
   * @returns {void}
   */
  const scanComplete = (res, fileScanned) => {
    if (!res) return;
    const { groups } = res;
    const {
      adress1,
      zipcode,
      city,
      lib,
      name,
      telMob,
      telPers,
      telPro,
      id,
      ensemble: ensembleId,
      escalier: escalierId,
      etage: etageId,
      logement: logementId,
      module: moduleId,
      programId,
      programName,
      trancheId,
    } = res;

    if (id === workOrderId) return;

    setOccupantName(name || '');
    setOccupantPhone(telMob || telPers || telPro || '');
    setDescription(lib || '');
    handleChangeInterventionAddress({
      display_name: `${adress1}` || '',
      address: {
        postcode: `${zipcode}` || '',
        municipality: `${city}` || '',
      },
    });
    setInterventionPostcode(zipcode || '');
    setInterventionMunicipality(city || '');
    setProgram(`${programId} ${programName}`);
    setTranche(trancheId);
    setEnsemble(ensembleId);
    setEscalier(escalierId);
    setLogement(logementId);
    setEtage(etageId);
    setModule(moduleId);
    findGPS(adress1, zipcode, city);
    setWorkOrderId(id || '');
  };

  /* =====================
  INTERVENTION GPS COORDINATES
  ======================== */
  /**
   * Set lat/lng states using the address data
   * @param {string} address intervention address
   * @param {string} zipcode intervention zipcode
   * @param {string} city intervention city
   */
  const findGPS = (address = null, zipcode = null, city = null) => {
    if (
      (interventionAddress.display_name
        && interventionPostcode
        && interventionMunicipality)
      || (address && zipcode && city)
    ) {
      let apiUrl = 'https://nominatim.openstreetmap.org/?addressdetails=1&street=';
      if (address != null) {
        apiUrl += `${address}&postalcode=${zipcode}&city=${city}`;
      } else {
        // eslint-disable-next-line max-len
        apiUrl += `${interventionAddress.display_name}&postalcode=${interventionPostcode}&city=${interventionMunicipality}`;
      }
      apiUrl
        += '&format=json&limit=10&accept-language=fr&countrycodes=fr&viewbox=4.52225,43.70247,6.47232,43.09982';
      fetch(apiUrl).then(async (res) => {
        const data = await res.json();
        if (data.length) {
          setLatitude(data[0].lat);
          setLongitude(data[0].lon);
        } else {
          addSnack({
            message: 'Aucun résultat trouvé pour cette adresse.',
            severity: 'error',
            duration: 5000,
          });
        }
      });
    } else {
      addSnack({
        message: 'Veuillez remplir adresse, ville et code postal.',
        severity: 'warning',
        duration: 5000,
      });
    }
  };

  /* =====================
      FIREBASE LESSORS
  ======================== */
  const {
    data: [lessors],
  } = dm.useLessors();

  /* =====================
      NOTIFY DATA CHANGED
  ======================== */
  useEffect(() => {
    const data = {
      title,
      startDate,
      endDate,
      startAddress,
      startMunicipality,
      startPostcode,
      assigned,
      description,
      lessor,
      workOrderId,
      interventionId,
      interventionAddress,
      interventionMunicipality,
      interventionPostcode,
      occupantName,
      occupantPhone,
      report,
      latitude,
      longitude,
      program,
      tranche,
      ensemble,
      escalier,
      logement,
      etage,
      module,
      status: values.status,
      resolved: values.resolved,
      quotationDetail: values.quotationDetail,
    };
    data.medias = values.medias;
    data.furnitures = values.furnitures;
    onChange(data);
  }, [
    title,
    startDate,
    endDate,
    startAddress,
    startMunicipality,
    startPostcode,
    assigned,
    description,
    lessor,
    workOrderId,
    interventionId,
    interventionAddress,
    interventionMunicipality,
    interventionPostcode,
    occupantName,
    occupantPhone,
    report,
    latitude,
    longitude,
    program,
    tranche,
    ensemble,
    escalier,
    logement,
    etage,
    module,
    onChange,
    values.status,
    values.medias,
    values.furnitures,
  ]);

  /* =====================
            RENDER
  ======================== */
  return (
    <div className="editEvent-tab-container">
      <form className="jobForm-form">
        <div className="jobForm-form-column">
          <div className="separator">
            <TextField
              id="title"
              variant="filled"
              label="Titre de l'intervention *"
              fullWidth
              onChange={ setTitle }
              value={ title }
            />
            <LessorSelect
              id="lessor"
              variant="filled"
              label="Bailleur"
              onChange={ setLessor }
              value={ lessor }
            />
          </div>
          <div className="separator">
            <AddressField
              id="startAddress"
              variant="filled"
              label="Adresse de départ"
              InputProps={ {
                endAdornment: (
                  <InputAdornment position="end">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              } }
              onChange={ handleChangeStartAddress }
              initialValue={ startAddress }
            />
            <div className="form-row">
              <TextField
                id="startCity"
                variant="filled"
                label="Ville"
                fullWidth
                onChange={ setStartMunicipality }
                value={ startMunicipality }
              />
              <TextField
                id="startZipcode"
                variant="filled"
                label="Code postal"
                fullWidth
                onChange={ setStartPostcode }
                value={ startPostcode }
              />
            </div>
          </div>
          <div className="separator">
            <UserSelect
              id="assigned"
              label="Affectation *"
              variant="filled"
              onChange={ setAssigned }
              value={ assigned }
              requestAvailability={ { start: startDate, end: endDate } }
            />
          </div>
          <div className="separator">
            <div className="form-row">
              <DateField
                id="startDate"
                variant="filled"
                label="Date de début *"
                onChange={ handleChangeStartDate }
                value={ startDate }
              />
              <TimeField
                id="startTime"
                variant="filled"
                label="Heure de début *"
                onChange={ handleChangeStartDate }
                value={ startDate }
              />
            </div>
            <div className="form-row">
              <DateField
                id="endDate"
                variant="filled"
                label="Date de fin"
                onChange={ setendDate }
                value={ endDate }
              />
              <TimeField
                id="endTime"
                variant="filled"
                label="Heure de fin"
                onChange={ setendDate }
                value={ endDate }
              />
            </div>
          </div>

          <div className="separator">
            <div className="form-row">
              <TextField
                id="occupantName"
                variant="filled"
                label="Occupant *"
                onChange={ setOccupantName }
                value={ occupantName }
              />
              <TextField
                id="occupantPhone"
                variant="filled"
                label="Téléphone *"
                onChange={ setOccupantPhone }
                value={ occupantPhone }
              />
            </div>
          </div>
          <div className="separator">
            <TextField
              id="description"
              variant="filled"
              multiline
              rows={ 5 }
              label="Description de l'intervention *"
              fullWidth
              onChange={ setDescription }
              value={ description }
            />
          </div>
        </div>
        <div className="jobForm-form-column">
          <div className="separator">
            <div className="form-row">
              <PdfParser
                onComplete={ scanComplete }
                disabled={
                  `lessor/${
                    (lessors || []).find((elt) => elt.name === '13 Habitat')?.id
                  }` !== lessor
                }
                style={ {
                  backgroundColor:
                    `lessor/${
                      lessors.find((elt) => elt.name === '13 Habitat')?.id
                    }` === lessor
                      ? colors.blue
                      : colors.grey,
                  height: '48px',
                } }
              />
            </div>
            <div className="form-row">
              <TextField
                id="workOrderId"
                variant="filled"
                label="N° BT"
                onChange={ setWorkOrderId }
                value={ workOrderId }
              />
              <TextField
                id="interventionId"
                label="N° Intervention"
                variant="filled"
                InputProps={ {
                  readOnly: true,
                } }
                onChange={ setInterventionId }
                value={ interventionId }
              />
            </div>
          </div>
          <div className="separator">
            <AddressField
              id="interventionAddress"
              variant="filled"
              label="Adresse de destination *"
              onChange={ handleChangeInterventionAddress }
              initialValue={ interventionAddress }
              InputProps={ {
                endAdornment: (
                  <InputAdornment position="end">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              } }
            />
            <div className="form-row">
              <TextField
                id="interventionCity"
                variant="filled"
                label="Ville *"
                fullWidth
                onChange={ setInterventionMunicipality }
                value={ interventionMunicipality }
              />
              <TextField
                id="interventionZipcode"
                variant="filled"
                label="Code postal *"
                fullWidth
                onChange={ setInterventionPostcode }
                value={ interventionPostcode }
              />
            </div>
          </div>
          <div className="separator">
            <TextField
              id="program"
              variant="filled"
              label="Programme"
              onChange={ setProgram }
              value={ program }
            />
          </div>
          <div className="separator">
            <div className="form-row">
              <TextField
                id="tranche"
                variant="filled"
                label="Tranche"
                onChange={ setTranche }
                value={ tranche }
              />
              <TextField
                id="ensemble"
                variant="filled"
                label="Ensemble"
                onChange={ setEnsemble }
                value={ ensemble }
              />
              <TextField
                id="escalier"
                variant="filled"
                label="Escalier"
                onChange={ setEscalier }
                value={ escalier }
              />
            </div>
            <div className="form-row">
              <TextField
                id="logement"
                variant="filled"
                label="Logement"
                onChange={ setLogement }
                value={ logement }
              />
              <TextField
                id="etage"
                variant="filled"
                label="Etage"
                onChange={ setEtage }
                value={ etage }
              />
              <TextField
                id="module"
                variant="filled"
                label="Module"
                onChange={ setModule }
                value={ module }
              />
            </div>
          </div>
          <div className="separator">
            <div className="form-row">
              <Button
                color="primary"
                className="find-gps"
                variant="contained"
                onClick={ () => findGPS() }
              >
                GPS
              </Button>
              <TextField
                id="latitude"
                variant="filled"
                label="Latitude"
                onChange={ setLatitude }
                value={ latitude }
              />
              <TextField
                id="longitude"
                variant="filled"
                label="Longitude"
                onChange={ setLongitude }
                value={ longitude }
              />
            </div>
          </div>
          <div className="separator">
            <TextField
              id="report"
              multiline
              rows={ 5 }
              variant="filled"
              label="Compte rendu de l'intervention"
              fullWidth
              onChange={ setReport }
              value={ report }
            />
          </div>
        </div>
      </form>
    </div>
  );
}

JobForm.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
};

export default JobForm;

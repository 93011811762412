import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * ListHeader react component
 * @param {array} columns table columns list
 * @returns ListHeader react component
 */
function ListHeader({ columns }) {
  return (
    <div className="list-header-container">
      { columns.map((col) => (
        <div key={ col.title } className="list-header-element noselect" style={ col.style }>
          <p>{ col.title }</p>
        </div>
      )) }
    </div>
  );
}

ListHeader.propTypes = {
  columns: PropTypes.array,
};

export default ListHeader;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button as MatButton, CircularProgress } from '@material-ui/core';
import { fade as alpha } from '@material-ui/core/styles/colorManipulator';
import withStyles from '@material-ui/core/styles/withStyles';
import './Button.scss';

export const styles = (theme) => ({
  text: {
    color: (props) => theme.palette[props.color].main,
    '&:hover': {
      backgroundColor: (props) => alpha(theme.palette[props.color].main, theme.palette.action.hoverOpacity),
    },
  },

  outlined: {
    color: (props) => theme.palette[props.color].main,
    border: (props) => `1px solid ${alpha(theme.palette[props.color].main, 0.5)}`,
    '&:hover': {
      border: (props) => `1px solid ${theme.palette[props.color].main}`,
      backgroundColor: (props) => alpha(theme.palette[props.color].main, theme.palette.action.hoverOpacity),
    },
  },

  contained: {
    color: (props) => theme.palette[props.color].contrastText,
    backgroundColor: (props) => theme.palette[props.color].main,
    '&:hover': {
      backgroundColor: (props) => theme.palette[props.color].dark,
    },
  },

});
/**
 * Button react component
 * @param {object} Button parameters
 * @returns Button react component
 */
function Button({
  onClick, children, disabled, ...props
}) {
  const [loading, setLoading] = useState(false);
  const handleClick = async (e) => {
    if (onClick) {
      try {
        setLoading(true);
        await onClick(e);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <MatButton onClick={ handleClick } disabled={ loading || disabled } { ...props }>
      <>
        { children }
        { loading && <CircularProgress className="buttonLoader" size={ 20 } /> }
      </>
    </MatButton>

  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

export const StyledButton = withStyles(styles)(Button);

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Menu from './Menu';
import colors from '../styles/colors.scss';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  layout: {
    minWidth: 0,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: '1em',
    position: 'absolute',
    top: 0,
    left: 12,

    color: colors.orange,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
      margin: '1rem 2rem',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'visible',

  },
  content: {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    height: '100%',
    flexDirection: 'column',
    boxSizing: 'border-box',

  },
  appBar: {
    position: 'relative',
    background: colors.white,
  },
  pageContent: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    minHeight: 0,
    boxSizing: 'border-box',
  },
}));
/**
 * ResponsiveDrawer react component
 * @param {object} ResponsiveDrawer parameters
 * @returns ResponsiveDrawer react component
 */
function ResponsiveDrawer({ headerRef, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  /**
   * Updates open/close drawer state
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={ classes.layout }>
      <nav className={ classes.drawer } aria-label="menu">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={ theme.direction === 'rtl' ? 'right' : 'left' }
            open={ mobileOpen }
            onClose={ handleDrawerToggle }
            classes={ {
              paper: classes.drawerPaper,
            } }
            ModalProps={ {
              keepMounted: true, // Better open performance on mobile.
            } }
          >
            <Menu />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={ {
              paper: classes.drawerPaper,
            } }
            variant="permanent"
            open
          >
            <Menu />
          </Drawer>
        </Hidden>
      </nav>
      <div className={ classes.content }>
        <div className={ classes.appBar }>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={ handleDrawerToggle }
            className={ classes.menuButton }
          >
            <MenuIcon />
          </IconButton>
          <div ref={ headerRef } />
        </div>
        <div className={ classes.pageContent }>{ children }</div>
      </div>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  headerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ResponsiveDrawer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm } from '@ferrero/comon';
import { Select } from '../ui';
import { startOf } from 'react-big-calendar/lib/utils/dates';
import { endOfDay, startOfDay } from 'date-fns';

/**
 * UserSelect react component
 * @param {object} UserSelect parameters
 * @returns UserSelect react component
 */
function UserSelect({
  requestAvailability, value, onChange, ...props
}) {
  const [options, setOptions] = useState();
  const {
    data: [users],
  } = dm.useUsers();
  const {
    data: [holidays],
  } = dm.useHolidays();
  useEffect(() => {
    setOptions(
      (users || [])
        .map((user) => {
          const { start, end } = requestAvailability ?? {};
          const isInHoliday = requestAvailability
            && holidays.filter(
              (h) => h.ref_user.id === user.id
                && h.accepted === 1
                && startOfDay(h.date_start.toDate()) <= end
                && endOfDay( h.date_end.toDate()) >= start,
            )[0];
          return {
            value: `user/${user.id}`,
            label: `${user.first_name} ${user.last_name}`,
            disabled: isInHoliday,
          };
        })
        .filter((u) => !!u),
    );
  }, [users, holidays, requestAvailability]);
  useEffect(() => {
    if (options && value) {
      const exist = options.find((o) => !o.disabled && o.value === value);
      if (!exist) onChange(undefined);
    }
  }, [options, value]);
  return (
    <Select
      options={ options || [] }
      value={ value }
      onChange={ onChange }
      { ...props }
    />
  );
}

UserSelect.propTypes = {
  requestAvailability: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default UserSelect;

import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm } from '@ferrero/comon';
import { config } from '../config';


const NetworkContext = React.createContext();
const { Provider } = NetworkContext;

/**
 * ApiProvider react component
 * @param {object} ApiProvider parameters
 * @returns ApiProvider react component
 */
export const ApiProvider = ({ children }) => {
  const [networkError, setNetworkError] = useState(false);
  const { token } = dm.useAuth();
  const fetchUrl = useCallback((endpoint, options) => fetch(`${config.baseURLApi}/${endpoint}`, options)
    .then((response) => {
      setNetworkError(false);
      return response.json();
    })
    .catch((error) => {
      setNetworkError(error);
      throw error;
    }), []);

  const getHeader = useCallback(() => ({
    'Content-Type': 'application/json',
    'x-access-token': token,
  }), [token]);

  /*================================

             API METHODS

  ================================*/

  const post = useCallback((endpoint, data) => fetchUrl(endpoint, {
    method: 'POST',
    headers: getHeader(),
    body: JSON.stringify(data),
  }), [getHeader, fetchUrl]);

  const put = useCallback((endpoint, data) => fetchUrl(endpoint, {
    method: 'PUT',
    headers: getHeader(),
    body: JSON.stringify(data),
  }), [getHeader, fetchUrl]);

  const patch = useCallback((endpoint, data) => fetchUrl(endpoint, {
    method: 'PATCH',
    headers: getHeader(),
    body: JSON.stringify(data),
  }), [getHeader, fetchUrl]);

  const del = useCallback((endpoint) => fetchUrl(endpoint, {
    method: 'DELETE',
    headers: getHeader(),
  }), [getHeader, fetchUrl]);

  const get = useCallback((endpoint, options) => fetchUrl(endpoint, {
    method: 'GET',
    headers: getHeader(),
    ...options,
  }), [getHeader, fetchUrl]);

  const uploadWithProgress = (endpoint, file, onProgress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    try {
      const formData = new FormData();
      formData.set('file', file, file.name);

      xhr.open('POST', `${config.baseURLApi}/${endpoint}`);
      xhr.setRequestHeader('x-access-token', token);
      xhr.upload.addEventListener('progress', (e) => {
        const percentCompleted = (e.loaded / e.total) * 100;
        if (onProgress) onProgress(percentCompleted);
      });
      xhr.addEventListener('error', reject);
      xhr.addEventListener('abort', reject);
      xhr.addEventListener('load', () => {
        if (xhr.status !== 200) return reject(new Error(xhr.response));
        return resolve(JSON.parse(xhr.response));
      });
      xhr.send(formData);
    } catch (error) {
      reject(error);
    }
  });

  return (
    <Provider value={ {
      networkError,
      post,
      put,
      get,
      del,
      patch,
      setNetworkError,
      uploadWithProgress,
    } }
    >
      { children }
    </Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


export const useApi = () => {
  const ctx = useContext(NetworkContext);
  if (!ctx) {
    throw Error('The `useApi` hook must be called from a descendent of the `ApiProvider`.');
  }

  return {
    networkError: ctx.networkError,
    setNetworkError: ctx.setNetworkError,
    post: ctx.post,
    put: ctx.put,
    patch: ctx.patch,
    get: ctx.get,
    del: ctx.del,
    uploadWithProgress: ctx.uploadWithProgress,
  };
};

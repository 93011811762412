import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Collapse,
  Zoom,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { Button } from '../../components/ui';
import logo from '../../assets/logo.svg';
import './Login.scss';
import Picto from '../../components/ui/Picto';
import PasswordInput from './PasswordInput';
import { useSnack } from '../../hooks/useSnack';
import { useApi } from '../../hooks/useApi';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        'border-radius': 41,
        height: 41,
        margin: 20,
        width: 350,
      },
      containedPrimary: {
        backgroundColor: 'white',
        color: '#007CC0',
        '&:hover': {
          backgroundColor: '#007CC0',
          color: 'white',
        },
      },
      text: {
        color: 'white',
      },
    },
  },
});

/**
 * Login react component
 * @returns Login react component
 */
function Login() {
  const [mounted, setMounted] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetingPassword, setResetingPassword] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { state } = useLocation();
  const { post } = useApi();
  const {
    login, user, loginError,
  } = dm.useAuth();
  const [loading, setLoading] = useState(false);

  const { addSnack } = useSnack();

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (loginError) {
      addSnack({
        message: loginError,
        severity: 'error',
      });
    }
  }, [loginError]);
  useEffect(() => {
    if (user && mounted) {
      setMounted(false);
      addSnack({
        message: 'connexion réussie',
        severity: 'success',
      });
      setTimeout(() => setRedirectToReferrer(true), 500);
    }
  }, [user, addSnack]);

  /**
   * Process user login into app
   * @param {event} event click event 
   */
  const doLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await login(email, password);
    } catch (e) {
      addSnack({
        message: 'erreur de connexion',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  /**
   * Send reset password email to user when btn clicked
   * @param {event} e click event 
   */
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await post('reset-password', { email });
      // await sendPasswordResetEmail(email);
      addSnack({
        message: 'email envoyé',
        severity: 'success',
      });
      setResetingPassword(false);
    } catch (error) {
      addSnack({
        message: error.message,
        severity: 'error',
      });
    }
    setLoading(false);
  };

  if (redirectToReferrer === true) {
    return <Redirect to={ state?.from || '/' } />;
  }

  return (
    <ThemeProvider theme={ theme }>
      <div className="page login-page">
        <img alt="logo" className="login-logo" src={ logo } />
        <Zoom in={ mounted }>
          <div className="login-card-container">
            <form className="login-form">
              <div className="login-picto-group">
                <Picto size={ 64 } type="icLeak" />
                <Picto size={ 128 } type="icWorker" />
                <Picto size={ 64 } type="icRadiator" />
              </div>
              <input
                id="email"
                placeholder="ADRESSE EMAIL"
                value={ email }
                className="login-input"
                onChange={ ({ target: { value } }) => setEmail(value) }
              />

              <Collapse in={ !resetingPassword }>
                <PasswordInput
                  id="password"
                  type="password"
                  placeholder="MOT DE PASSE"
                  value={ password }
                  className="login-input"
                  onChange={ ({ target: { value } }) => setPassword(value) }
                />
              </Collapse>

              <Button
                onClick={ resetingPassword ? handleResetPassword : doLogin }
                type="submit"
                className="loginFormItem"
                disabled={ loading }
                variant="contained"
                color="primary"
                id="connection"
              >
                { resetingPassword
                  ? 'réinitialiser mon mot de passe'
                  : 'CONNEXION' }
              </Button>
              <p
                id="connect"
                onClick={ () => setResetingPassword((cur) => !cur) }
              >
                { resetingPassword ? 'se connecter' : 'mot de passe oublié' }
              </p>
            </form>
          </div>
        </Zoom>
      </div>
    </ThemeProvider>
  );
}

Login.propTypes = {};

export default Login;

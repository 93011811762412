import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Tooltip } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { TextField } from './ui';
import ButtonWithModal from './ButtonWithModal';
/**
 * ButtonWithreason react component
 * @param {object} ButtonWithreason parameters
 * @returns ButtonWithreason react component
 */
function ButtonWithreason({
  onClick,
  title,
  text,
  value,
  children,
  ...otherProps
}) {
  const [reason, setReason] = useState(value);

  useEffect(() => {
    setReason(value);
  }, [value]);

  const handleClose = () => {
    setReason(value);
  };

  const handleValidatee = () => {
    onClick(reason);
  };
  return (
    <>
      <ButtonWithModal
        onClick={ handleValidatee }
        onClose={ handleClose }
        DialogContent={ ({ valideDialog, closeDialog }) => (
          <>
            { title && <DialogTitle id="form-dialog-title">{ title }</DialogTitle> }
            <DialogContent>
              { text && <DialogContentText>{ text }</DialogContentText> }
              <TextField
                autoFocus
                id="reason"
                label="Motif"
                fullWidth
                onChange={ setReason }
                value={ reason }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={ closeDialog } color="primary">
                Annuler
              </Button>
              <Button onClick={ valideDialog } color="primary" disabled={ !reason }>
                Valider
              </Button>
            </DialogActions>
          </>
        ) }
        { ...otherProps }
      >
        { children }
      </ButtonWithModal>
      { value && (
        <Tooltip title={ reason }>
          <IconButton>
            <ChatIcon />
          </IconButton>
        </Tooltip>
      ) }
    </>
  );
}

ButtonWithreason.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonWithreason;

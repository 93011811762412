/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Fade, Dialog, Button } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { format } from 'date-fns';
import TopMenu from '../components/TopMenu';
import Picto from '../components/ui/Picto';
import './Expense.scss';
import { config } from '../config';

import colors from '../components/styles/colors.scss';
import ButtonWithreason from '../components/ButtonWithreason';
import DataList from '../components/ui/List';
import { useNotification } from '../hooks/useNotification';

/**
 * Expense react component
 * @returns Expense react component
 */
function Expense() {
  const [show, setSow] = useState(false);
  const [showDetailId, setShowDetailId] = useState(null);
  const [, setLastFilter] = useState(null);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [idItemToDelete, setIdItemToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mediaSelected, setMediaSelected] = useState(null);
  const [expenseList, setExpenseList] = useState([]);
  const [initialList, setInitialList] = useState([]);
  const { upsert } = dm.useExpense();
  // GET VALUES FROM DB
  const {
    data: [expense],
  } = dm.useExpense();
  const {
    data: [users],
  } = dm.useUsers();
  const { sendNotification } = useNotification();
  // INITIALIZE LISTS
  useEffect(() => {
    const list = (expense || []).map((curr) => ({
      id: `expense/${curr.id}`,
      ref_user: `user/${curr.ref_user.id}`,
      userId: curr.ref_user.id,
      user_name: `${
        users.find((elt) => elt.id === curr.ref_user.id)?.first_name
      } ${users.find((elt) => elt.id === curr.ref_user.id)?.last_name}`,
      date_formatted: `${format(
        new Date(curr.date.seconds * 1000),
        'dd/MM/yyyy',
      )}`,
      date: curr.date,
      amount: curr.amount,
      accepted: curr.accepted,
      refuse_reason: curr.refuse_reason,
      media_path: curr.media_path,
      mediaSrc: `${config.baseURLApi}/media/${curr?.media_path}`,
    }));
    setInitialList(list);
    setExpenseList(list);
  }, [expense, users]);

  useEffect(() => {
    setSow(true);
    return () => {
      setSow(false);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  // 
  /**
   * ON FILTER CHANGE, FILTER DISPLAY LIST
   * @param {object} filters current filters data 
   */
  const onFilterChange = (filters) => {
    if (filters != null) {
      setLastFilter(filters);
      const filteredList = initialList.filter((elt) => {
        let isValid = true;
        if (filters.yes_no !== null && filters.yes_no !== 2) {
          isValid
            &= filters.yes_no === 1 ? elt.accepted === 1 : elt.accepted === 0;
        }
        if (filters.date_start !== null) {
          filters.date_start.setHours(0);
          filters.date_start.setMinutes(0);
          isValid &= filters.date_start.getTime() <= elt.date.seconds * 1000;
        }
        if (filters.date_end !== null) {
          filters.date_end.setHours(23);
          filters.date_end.setMinutes(59);
          isValid &= filters.date_end.getTime() >= elt.date.seconds * 1000;
        }
        if (filters.employee !== null && filters.employee !== '') {
          isValid &= filters.employee === elt.ref_user;
        }
        return isValid;
      });
      setExpenseList(filteredList);
    }
  };

  
  /**
   * DATABASE UPDATE when accepted/refused
   * @param {integer} itemId expense id in db
   * @param {boolean} isAccepted true when accepted, else false
   * @param {string} refuseReason when not accepted, the reason message
   */
  const onItemAcceptedChange = async (itemId, isAccepted, refuseReason) => {
    const curr = expenseList.find((elt) => elt.id === itemId);
    const formatedData = {
      ...curr,
      accepted: isAccepted,
      refuse_reason: isAccepted ? null : refuseReason,
    };
    await upsert(itemId.split('/')[1], formatedData);
    sendNotification(
      { userId: curr.userId },
      `votre note de frais du ${curr.date_formatted} a été ${
        isAccepted ? 'accéptée' : 'refusée'
      }`,
    );
  };

  /**
   * Delete expense in db
   */
  const onItemDelete = () => {
    const curr = expenseList.find((elt) => elt.id === idItemToDelete);
    const formatedData = { ...curr, ts_deleted: new Date().getTime() };
    upsert(idItemToDelete.split('/')[1], formatedData).then(() => {
      closeDeleteModal();
    });
  };

  /**
   * Delete the expense in db
   * @param {integer} id element id in db 
   */
  const showDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setIdItemToDelete(id);
  };

  /**
   * Close delete modal
   */
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIdItemToDelete(null);
  };

  // MEDIA
  /**
   * Show media detail modal
   * @param {object} mediaToShow the media to display 
   */
  const onItemMediaClicked = (mediaToShow) => {
    setIsMediaModalOpen(true);
    setMediaSelected(mediaToShow);
  };

  /**
   * Close media modal
   */
  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
    setMediaSelected(null);
  };

  const renderDetail = (row) => {
    const showImage = row.id === showDetailId;
    return (
      <img
        className="noselect"
        alt="ticket"
        style={ {
          display: showImage ? 'block' : 'none',
          maxHeight: 200,
          width: 'auto',
          objectFit: 'contain',
        } }
        src={ row.mediaSrc }
        onClick={ () => onItemMediaClicked(row.mediaSrc) }
      />
    );
  };
  const renderStatus = (accepted, row) => (
    <>
      <Button
        className="addhours-row-item-btn-yes"
        style={ {
          backgroundColor: accepted === 1 ? colors.green : colors.white,
          color: accepted === 1 ? colors.white : colors.black,
        } }
        variant="contained"
        onClick={ () => onItemAcceptedChange(row.id, 1) }
      >
        OUI
      </Button>
      <ButtonWithreason
        className="addhours-row-item-btn-no"
        style={ {
          backgroundColor: accepted === 0 ? colors.red : colors.white,
          color: accepted === 0 ? colors.white : colors.black,
        } }
        variant="contained"
        onClick={ (reason) => onItemAcceptedChange(row.id, 0, reason) }
        title="Refuser la note de frais"
        value={ row.refuse_reason }
      >
        NON
      </ButtonWithreason>
    </>
  );
  const renderAction = (_, row) => {
    const showImage = row.id === showDetailId;
    return (
      <>
        <Picto
          size={ isMobile ? 20 : 30 }
          type={ showImage ? 'icEyeClosed' : 'icEye' }
          fill={ colors.blueDark }
          onClick={ () => setShowDetailId((cur) => (cur === row.id ? null : row.id)) }
        />
        <Picto
          size={ isMobile ? 20 : 30 }
          type="icTrash"
          fill={ colors.red }
          onClick={ () => showDeleteModal(row.id) }
        />
      </>
    );
  };
  // RENDER PAGE
  return (
    <Fade in={ show }>
      <div className="page referential-page">
        { isMediaModalOpen && (
          <Dialog className="image-dialog" open onClick={ closeMediaModal }>
            <div className="overlay" role="button" tabIndex={ 0 }>
              <Picto size={ 22 } type="icClose" fill={ colors.red } />
            </div>
            <img
              className="image-full-size"
              src={ mediaSelected }
              alt="no media"
            />
          </Dialog>
        ) }
        { isDeleteModalOpen && (
          <Dialog className="delete-dialog" open onClick={ closeDeleteModal }>
            <div className="delete-dialog-content">
              <p className="delete-dialog-title">Supprimer note de frais.</p>
              <p className="delete-dialog-subtitle">
                Voulez-vous vraiment supprimer cette note de frais ?
              </p>
              <div className="delete-dialog-buttons">
                <Button
                  className="delete-dialog-button-no"
                  variant="contained"
                  onClick={ closeDeleteModal }
                >
                  NON
                </Button>
                <Button
                  className="delete-dialog-button-yes"
                  variant="contained"
                  color="primary"
                  onClick={ onItemDelete }
                >
                  OUI
                </Button>
              </div>
            </div>
          </Dialog>
        ) }
        <TopMenu
          title="Notes de frais"
          subtitle="Liste des notes de frais déclarées par les employés"
          yesnoTitle="Remboursé"
          onFilterChange={ onFilterChange }
        />
        <div className="page-content">
          <DataList
            data={ expenseList }
            className="additionalhours-list"
            columns={ [
              {
                title: 'Employé',
                key: 'user_name',
                style: { flex: 1 },
                dataType: 'user',
              },
              {
                title: 'Date',
                key: 'date_formatted',
                picto: 'icCalendar',
                style: { width: 160 },
              },
              {
                title: 'Montant',
                key: 'amount',
                picto: 'icBill',
                style: { width: 150 },
              },
              {
                title: 'Accepté',
                key: 'accepted',
                style: { width: isMobile ? 250 : 180 },
                render: renderStatus,
              },
              {
                title: 'Actions',
                style: { width: isMobile ? 140 : 80 },
                render: renderAction,
              },
            ] }
            renderDetail={ renderDetail }
          />
        </div>
      </div>
    </Fade>
  );
}

Expense.propTypes = {};

export default Expense;

import React, {
  useState, useEffect, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm, constants } from '@ferrero/comon';
import { CircularProgress } from '@material-ui/core';
import './styles.scss';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import localeFr from 'date-fns/locale/fr';
import { startOfMonth } from 'date-fns';
import { MonthEvent, defaultEvent } from './Event';
import CalendarFilters from './CalendarFilters';
import AgendaView from './AgendaView';

const locales = {
  fr: localeFr,
};

const NOT_ASSIGNED = {
  id: 'not_assigned',
  color: 'red',
  first_name: 'NON ASSIGNÉ',
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

/**
 * React JobItem component
 * @param {object} param JobItem parameters (job object and onclick callback)
 * @returns {render} JobItem react component
 */
const JobItem = ({ job, onClick }) => (
  <div
    className="calendar-job-item"
    role="button"
    tabIndex={ 0 }
    onClick={ onClick }
  >
    <span>{ job.id }</span>
    <span>{ job.title }</span>
    <span>{ `${job.date_start.toDate()}` }</span>
    <span>{ `${job.date_end.toDate()}` }</span>
    <span>{ job.description }</span>
  </div>
);
JobItem.propTypes = {
  job: PropTypes.object,
  onClick: PropTypes.func,
};

/**
 * React Calendar component
 * @param {function} onOpenEvent callback to call when event is opened
 * @returns {render} Calendar react component
 */
function Calendar({ onOpenEvent }) {
  const { data } = dm.useJobs();
  const [jobList, loading, error] = data;
  const [currentView, setCurrentView] = useState('day');
  const [date, setDate] = useState(new Date());
  const [filters, setFilters] = useState({});
  const [events, setEvents] = useState([]);
  const [filteredEvent, setFilteredEvents] = useState(jobList || []);
  const { setMinDate } = useContext(dm.DataContext);
  const {
    data: [holidays],
  } = dm.useHolidays();
  const {
    data: [users],
  } = dm.useUsers();
  const [width, setWidth] = useState(window.innerWidth);
  /**
   *
   */
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    setEvents([
      ...holidays
        .filter(({ accepted }) => accepted !== 0)
        .map((holiday) => ({
          title: `congé ${holiday.accepted ? 'validés' : ''} du ${format(
            holiday.date_start.toDate(),
            'dd/MM',
          )} au ${format(holiday.date_end.toDate(), 'dd/MM')}`,
          type: constants.EVENT_TYPE.holiday,
          start: startOfDay(holiday.date_start.toDate()),
          end: endOfDay(holiday.date_end.toDate()),
          allDay: true,
          holiday,
          user: usersMap[holiday.ref_user.id],
        })),
      ...jobList.map((job) => ({
        title: job.title,
        type: constants.EVENT_TYPE.job,
        start: job.date_start.toDate(),
        end: job.date_end.toDate(),
        job,
        user: usersMap[job?.ref_user?.id] ?? NOT_ASSIGNED,
      })),
    ]);
  }, [holidays, jobList]);

  useEffect(() => {
    const {
      users: filterUsers,
      status: filterStatus,
      holidays: filterHolidays,
    } = filters;
    let res = events || [];
    if (filterUsers) {
      res = res.filter((evt) => filterUsers.includes(evt.user?.id ?? NOT_ASSIGNED.id));
    }
    if (filterStatus) {
      res = res.filter(
        (evt) => !evt.job || filterStatus.includes(evt?.job?.status),
      );
    }
    if (filterHolidays) {
      res = res.filter(
        (evt) => !evt.holiday || filterHolidays.includes(evt?.holiday?.accepted ?? -1),
      );
    }
    setFilteredEvents(res);
  }, [filters, events]);

  const usersMap = useMemo(
    () => users.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    ),
    [users],
  );

  useEffect(() => {
    const getMinDate = () => {
      if (currentView === 'month') return startOfMonth(date);
      if (currentView === 'week') return startOfWeek(date);
      return startOfDay(date);
    };
    setMinDate(getMinDate());
  }, [date]);

  if (loading) return <CircularProgress />;

  if (error) return <CircularProgress value={ 75 } color="secondary" />;

  /**
   * Get event prop styles
   * @param {object} event event to get props
   * @returns {object} event styles
   */
  const getEventProps = (event) => {
    const { user: { color } = {} } = event;
    const opacity = (event.job || event?.holiday?.accepted) ?? false ? 1 : 0.4;
    if (currentView === 'month') {
      return {
        style: {
          backgroundColor:
            event.type === constants.EVENT_TYPE.job ? 'transparent' : color,
          opacity,
        },
        className: 'calendar-event-container',
      };
    }
    if (currentView === 'agenda') {
      return {
        style: { backgroundColor: color, margin: 10, opacity },
        className: 'calendar-agenda-event-container',
      };
    }
    return {
      style: { backgroundColor: color, opacity },
      className: 'calendar-event-container',
    };
  };

  const handleRangeChange = (range, view) => {
    console.log('rrr', range, view);
    setMinDate(startOfDay(range.start ? range.start : range[0]));
  };


  return (
    <div>
      <div className="calendar-container">
        <CalendarFilters
          createEvent={ () => onOpenEvent({ job: {} }) }
          date={ date }
          onNavigate={ setDate }
          onChange={ (f) => setFilters((cur) => ({ ...cur, ...f })) }
        />
        <BigCalendar
          className="calendar-calendar"
          defaultView={ currentView }
          date={ date }
          onNavigate={ setDate }
          onView={ setCurrentView }
          view={ currentView }
          tooltipAccessor={ null }
          onRangeChange={ handleRangeChange }
          views={
            isMobile
              ? {
                day: true,
                agenda: AgendaView,
              }
              : {
                month: true,
                week: true,
                day: true,
                agenda: AgendaView,
              }
          }
          min={ new Date(0, 0, 0, 8, 0, 0) }
          max={ new Date(0, 0, 0, 18, 0, 0) }
          popup
          formats={ {
            // eslint-disable-next-line max-len
            eventTimeRangeFormat: ({ start, end }, _, loc) => `${loc.format(start, 'HH:mm')}-${loc.format(end, 'HH:mm')}`,
            eventTimeRangeStartFormat: ({ start }, _, loc) => loc.format(start, 'HH:mm'),
            eventTimeRangeEndFormat: ({ end }, _, loc) => loc.format(end, 'HH:mm'),
          } }
          resizable
          components={ {
            event: defaultEvent,
            month: {
              event: MonthEvent,
            },
          } }
          localizer={ localizer }
          events={ filteredEvent }
          startAccessor="start"
          endAccessor="end"
          showMultiDayTimes
          onSelectEvent={ (event) => onOpenEvent(event) }
          culture="fr"
          showAllEvents
          eventPropGetter={ getEventProps }
          messages={ {
            date: 'Date',
            time: 'Heure',
            event: 'Intervention',
            allDay: 'Toute la journée',
            week: 'Semaine',
            work_week: 'Semaine de travail',
            day: 'Jour',
            month: 'Mois',
            previous: '<',
            next: '>',
            yesterday: 'Hier',
            tomorrow: 'Demain',
            today: "Aujourd'hui",
            agenda: 'Agenda',
            noEventsInRange: "Pas d'évènement dans la période.",
            showMore(e) {
              return `+${e} de plus`;
            },
          } }
        />
      </div>
    </div>
  );
}

Calendar.propTypes = {
  onOpenEvent: PropTypes.func,
};

export default Calendar;

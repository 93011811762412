import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import './AutocompleteTextField.scss';
import { debounce } from '../../utils';

/**
 * AutocompleteTextField react component
 * @param {object} AutocompleteTextField parameters
 * @returns AutocompleteTextField react component
 */
function AutocompleteTextField({
  // displayKey,
  // searchKey,
  // url,
  initialValue,
  onChange,
  ...other
}) {
  const [activeOption, setActiveOption] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [initial, setInitial] = useState(initialValue);
  useEffect(() => {
    if (initial != null) {
      setUserInput(initial);
    }
  }, [initial]);

  const getData = useCallback(
    debounce((txt) => {
      // const apiUrl = `${url}?${searchKey}=${txt}`;
      // get(apiUrl).then((data) => {
      //   const retNames = [];
      //   for (let i = 0; i < data.length; i++) {
      //     retNames.push(data[i][displayKey]);
      //   }
      //   setFilteredOptions(retNames);
      //   setActiveOption(0);
      //   setShowOptions(true);
      //   onChange(txt);
      // });
    }, 500),
    [onChange],
  );

  const onTextChange = useCallback((e) => {
    setInitial(null);
    const txt = e.currentTarget.value;
    setUserInput(txt);
    getData(txt);
  }, [getData]);

  /**
   * Updates states when autocomplete answer selected
   * @param {event} e click result event 
   */
  const onClickResult = (e) => {
    const txt = e.currentTarget.innerText;
    setUserInput(txt);
    setFilteredOptions([]);
    setActiveOption(0);
    setShowOptions(false);
    onChange(txt);
  };

  /**
   * Close autocomplete suggestion list
   * @param {event} e close event 
   */
  // eslint-disable-next-line no-unused-vars
  const closeSuggestion = (e) => {
    setActiveOption(0);
    setShowOptions(false);
  };

  /**
   * Perform action on key down depending on code acquiered
   * @param {event} e key down event 
   * @returns void
   */
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter
      setActiveOption(0);
      setShowOptions(false);
      setUserInput(filteredOptions[activeOption]);
      onChange(filteredOptions[activeOption]);
    } else if (e.keyCode === 38) {
      // up arrow
      if (activeOption === 0) {
        return;
      }
      setActiveOption(activeOption - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      setActiveOption(activeOption + 1);
    }
  };

  let optionList;
  if (showOptions && userInput) {
    if (filteredOptions.length) {
      optionList = (
        <div className="options">
          { filteredOptions.map((optionName, index) => {
            let className;
            if (index === activeOption) {
              className = 'option-active';
            }
            return (
              <div role="button" tabIndex={ 0 } className={ className } key={ optionName } onClick={ onClickResult }>
                { optionName }
              </div>
            );
          }) }
        </div>
      );
    }
  }

  return (
    <div className="autocomplete-container">
      <div className="search">
        <TextField
          type="text"
          className="search-box"
          onChange={ onTextChange }
          onKeyDown={ onKeyDown }
          value={ userInput }
          { ...other }
        />
      </div>
      { optionList }
    </div>
  );
}

AutocompleteTextField.propTypes = {
  displayKey: PropTypes.string,
  searchKey: PropTypes.string,
  url: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
};

AutocompleteTextField.defaultProps = {
  displayKey: '',
  searchKey: '',
  url: '',
  initialValue: '',
  onChange: null,
};

export default AutocompleteTextField;

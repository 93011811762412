import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Snack from '../components/ui/Snack';

const SnackContext = React.createContext();
const { Provider } = SnackContext;


/**
 * SnackProvider react component
 * @param {object} SnackProvider parameters
 * @returns SnackProvider react component
 */
export const SnackProvider = ({ children }) => {
  const [snackList, setSnackList] = useState([]);
  const add = (snack) => setSnackList((cur)=>[...cur, { ...snack, id: uuidv4() }]);

  const remove = (snack) => {
    setSnackList((cur)=> [...cur.filter((s) => s.id !== snack.id)]);
  };

  return (
    <Provider value={ { add } }>
      { children }
      { snackList.map((snack, index) => (
        <Snack
          key={ snack.id }
          style={ { bottom: 48 + 58 * index } }
          snack={ snack }
          onClose={ () => remove(snack) }
          duration={ snack.duration }
        />
      )) }
    </Provider>
  );
};
SnackProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export const useSnack = () => {
  const ctx = useContext(SnackContext);

  if (!ctx) {
    throw Error('The `useSnack` hook must be called from a descendent of the `SnackProvider`.');
  }

  return {
    addSnack: ctx.add,
  };
};

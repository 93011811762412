import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';
import { makeStyles } from '@material-ui/core/styles';
import UserSelect from './UserSelect';
import Button from '../ui/Button';
import StatusSelect from './StatusSelect';
import HolidayStatusSelect from './HolidayStatusSelect';


const useStyles = makeStyles({
  muiPickersDay: {
    day: {
      width: '24px',
      height: '24px',
    },
  },
});


/**
 * React CalendarFilters component
 * @param {object} param object containing the CalendarFilters requirements 
 * @returns {render} CalendarFilters react component
 */
function CalendarFilters({
  createEvent, date, onNavigate, onChange,
}) {
  const classes = useStyles();
  return (
    <div className="calendar-filters-container">
      <Button
        variant="contained"
        className="calendar-new-event"
        color="primary"
        onClick={ createEvent }
      >
        Nouveau
      </Button>
      <MuiPickersUtilsProvider locale={ fr } utils={ DateFnsUtils }>
        <DatePicker
          autoOk
          classes={ classes }
          size="small"
          orientation="portrait"
          variant="static"
          disableToolbar
          openTo="date"
          value={ date }
          onChange={ onNavigate }
        />
      </MuiPickersUtilsProvider>

      <UserSelect onChange={ (u) => onChange({ users: u }) } />
      <StatusSelect onChange={ (s) => onChange({ status: s }) } />
      <HolidayStatusSelect onChange={ (s) => onChange({ holidays: s }) } />
    </div>
  );
}

CalendarFilters.propTypes = {
  date: PropTypes.instanceOf(Date),
  onNavigate: PropTypes.func,
  createEvent: PropTypes.func,
  onChange: PropTypes.func,
};

export default CalendarFilters;

import React, { useEffect, useState } from 'react';
import { Fade, Modal } from '@material-ui/core';
import './Planning.scss';
import Calendar from '../components/Calendar';
import EditEvent from '../components/EditEvent';

/**
 * Panning react component
 * @returns Panning react component
 */
function Panning() {
  const [currentEvent, setCurrentevent] = useState();
  const [show, setSow] = useState(false);

  useEffect(() => {
    setSow(true);
    return () => {
      setSow(false);
    };
  }, []);
  return (
    <Fade in={ show }>
      <div className="page referential-page">
        <Calendar onOpenEvent={ setCurrentevent } />
        <Modal open={ !!currentEvent } disableBackdropClick onClose={ () => setCurrentevent(null) }>
          <EditEvent event={ currentEvent } onClose={ () => setCurrentevent(null) } />
        </Modal>
      </div>
    </Fade>

  );
}

Panning.propTypes = {};

export default Panning;

/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import XLSX from 'xlsx';
import Fade from '@material-ui/core/Fade';
import Uploader from '../components/Uploader';
import ImportReferential from '../components/ImportReferential';
import Button from '../components/ui/Button';
import './Referential.scss';
import { useApi } from '../hooks/useApi';
import { useSnack } from '../hooks/useSnack';
/**
 * Referential react component
 * @returns Referential react component
 */
function Referential() {
  const [show, setSow] = useState(false);
  useEffect(() => {
    setSow(true);
    return () => {
      setSow(false);
    };
  }, []);
  const XLSColumnsTitles = [
    'CODE FOURNISSEUR',
    'REFERENCE PRODUIT',
    'PRIX UNITAIRE HT',
  ];
  const { get, put } = useApi();
  const { addSnack } = useSnack();

  /**
   * Format current date for display
   * @returns {string} formatted time
   */
  const getFormattedTime = () => {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const h = today.getHours();
    const mi = today.getMinutes();
    const s = today.getSeconds();
    return `${y}-${m}-${d}-${h}-${mi}-${s}`;
  };

  /**
   * Parse the xls file and prepare the insert
   * @param {array} xlsData the data extracted from excel file 
   */
  const parseXLS = async (xlsData) => {
    let headerIndex = null;
    for (let i = 0; i < xlsData.length; i++) {
      const elt = xlsData[i];
      if (elt.length <= XLSColumnsTitles.length) {
        let sameCols = true;
        for (let j = 0; j < elt.length; j++) {
          sameCols &= elt[j] === XLSColumnsTitles[j];
        }
        if (sameCols) {
          headerIndex = i;
          break;
        }
      }
    }
    if (headerIndex === null) {
      addSnack({
        message: "Le fichier n'a pas le bon format.",
        severity: 'error',
      });
    } else {
      let startDataIndex = headerIndex + 1;
      if (
        JSON.stringify(xlsData[startDataIndex])
        === JSON.stringify(XLSColumnsTitles)
      ) {
        startDataIndex++;
      }
      const dataToInsert = xlsData
        .splice(startDataIndex)
        .filter((arr) => arr.length);

      const errorRows = [];
      for (let i = 0; i < dataToInsert.length; i++) {
        const elt = dataToInsert[i];
        if (elt[0] !== 'VIT' && elt[0] !== 'ARE' && elt[0] !== 'RIC') {
          errorRows.push(i + startDataIndex + 1);
        } else if (
          elt[1] == null
          || elt[1].length === 0
          || elt[2] == null
          || elt[2].length === 0
        ) {
          errorRows.push(i + startDataIndex + 1);
        } else if (Number.isNaN(elt[2])) {
          try {
            elt[2] = parseFloat(elt[2]);
            if (Number.isNaN(elt[2])) {
              errorRows.push(i + startDataIndex + 1);
            }
          } catch (e) {
            errorRows.push(i + startDataIndex + 1);
          }
        }
      }

      if (errorRows.length) {
        addSnack({
          message:
            `Les lignes ${
              errorRows.join(',')
            } ne sont pas correctes, elles seront ignorées.`,
          severity: 'warning',
          duration: 10000,
        });

        for (let i = errorRows.length - 1; i >= 0; i--) {
          dataToInsert.splice(errorRows[i] - startDataIndex - 1, 1);
        }
      }

      if (dataToInsert.length === 0) {
        addSnack({
          message: 'Aucun changement détecté dans le référentiel.',
          severity: 'warning',
        });
      } else {
        await put('referential', { dataToInsert });
        addSnack({
          message: 'Référentiel produit mis à jour.',
          severity: 'success',
        });
      }
    }
  };

  /**
   * Process Download generated XLS file from extracted data  
   */
  const downloadXLS = async () => {
    let res = await get('referential');
    const wb = XLSX.utils.book_new();

    const header = [
      ['CODES FOURNISSEURS'],
      ['VIT', 'MICHEL VITALIS'],
      ['ARE', 'AREDIS'],
      ['RIC', 'RICHARDSON'],
      ['', '', ''],
      ['CODE FOURNISSEUR', 'REFERENCE PRODUIT', 'PRIX UNITAIRE HT'],
    ];
    const ws = XLSX.utils.aoa_to_sheet(header);

    res = res.map((elt) => ({ ...elt, price: parseFloat(elt.price) }));

    XLSX.utils.sheet_add_json(ws, res, {
      header: ['supplier_code', 'reference', 'price'],
      skipHeader: true,
      origin: -1,
    });

    const fmt = '0.00 €';
    for (let R = header.length + 1; R <= header.length + res.length; ++R) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: 2 })];
      // eslint-disable-next-line no-continue
      if (!cell) continue;
      cell.z = fmt;
    }

    XLSX.utils.book_append_sheet(wb, ws, 'produits');

    XLSX.writeFile(wb, `produits_${getFormattedTime()}.xlsx`);
    addSnack({
      message: 'Le référentiel produit a été téléchargé.',
      severity: 'success',
    });
  };

  return (
    <Fade in={ show }>
      <div className="page referential-page">
        <div className="referential-content">
          <div className="referential-drop">
            Déposer le référentiel produit
            <ImportReferential onAddXLS={ parseXLS } />
            <div id="download-referential">
              <p className="download-referential-info">Cliquer ici pour télécharger le référentiel produit à jour</p>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={ downloadXLS }
              >
                Télécharger
              </Button>
            </div>
          </div>
          <div className="referential-drop">
            Déposer les factures pour analyses
            <Uploader />
          </div>
        </div>
      </div>
    </Fade>
  );
}

Referential.propTypes = {};

export default Referential;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MaSwitch, withStyles } from '@material-ui/core';
import colors from '../styles/colors.scss';


const AntSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.grey[500],
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: colors.green,
        borderColor: colors.green,
      },
    },
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(MaSwitch);

/**
 * Switch react component
 * @param {object} Switch parameters
 * @returns Switch react component
 */
export const Switch = ({
  checked, label, onChange, className,
}) => (
  <div
    className={ className }
    style={ { color: checked ? colors.green : colors.grey } }
  >
    <AntSwitch
      checked={ checked }
      onChange={ onChange }
    />
    { label }
  </div>
);

Switch.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Switch;

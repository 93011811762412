import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
//import stylePropType from 'react-style-proptype';
import Alert from './Alert';

/**
 * Snack react component
 * @param {object} Snack parameters
 * @returns Snack react component
 */
const Snack = ({
  snack, onClose, style, duration = 2000
}) => {
  const handleClose = (e, reason) => reason !== 'clickaway' && onClose();
  return (
    <Snackbar
      open
      style={ style }
      autoHideDuration={ duration }
      onClose={ handleClose }
      message={ snack.message }

    >
      <Alert
        severity={ snack.severity }
        onClose={ onClose }
        action={ snack.action && (
          <Button color="inherit" size="small" onClick={ snack.action.onClick }>
            { snack.action.label }
          </Button>
        ) }
      >
        { snack.message }
      </Alert>
    </Snackbar>
  );
};
Snack.propTypes = {
  snack: PropTypes.shape({
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    action: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
//  style: stylePropType,
};
export default Snack;

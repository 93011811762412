import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import colors from "../../components/styles/colors.scss";
import "./Login.scss";
/**
 * PasswordInput react component
 * @param {props} PasswordInput props
 * @returns PasswordInput react component
 */
function PasswordInput(props) {
  const [hidden, setHidden] = useState(true);

  const Icon = hidden ? FaEyeSlash : FaEye;
  return (
    <span className="passwordInputContainer">
      <input {...props} type={hidden ? "password" : "text"} />
      <Icon
        className="hideIcon"
        onMouseEnter={() => setHidden(false)}
        onMouseLeave={() => setHidden(true)}
        fill={colors.white}
      />
    </span>
  );
}

PasswordInput.propTypes = {};

export default PasswordInput;

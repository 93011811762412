"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useJobs = void 0;

var _react = require("react");

var _differenceInMinutes = _interopRequireDefault(require("date-fns/differenceInMinutes"));

var _addMinutes = _interopRequireDefault(require("date-fns/addMinutes"));

var _getYear = _interopRequireDefault(require("date-fns/getYear"));

var _Provider = require("../Provider");

var _geolocUtils = require("../../utils/geolocUtils");

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("../index"));

var _useNotifications2 = require("./useNotifications");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
 * Format a media object
 * @param {object} object media passed as parameter to be formatted
 * @returns {object} data formatted
 */
var formatMedia = function formatMedia(_ref) {
  var _ref$file_path = _ref.file_path,
      file_path = _ref$file_path === void 0 ? null : _ref$file_path,
      _ref$is_signature = _ref.is_signature,
      is_signature = _ref$is_signature === void 0 ? null : _ref$is_signature,
      _ref$is_quotation = _ref.is_quotation,
      is_quotation = _ref$is_quotation === void 0 ? null : _ref$is_quotation,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? null : _ref$name,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? null : _ref$size,
      _ref$ts_deleted = _ref.ts_deleted,
      ts_deleted = _ref$ts_deleted === void 0 ? null : _ref$ts_deleted,
      _ref$ts_inserted = _ref.ts_inserted,
      ts_inserted = _ref$ts_inserted === void 0 ? null : _ref$ts_inserted,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? null : _ref$id,
      _ref$extension = _ref.extension,
      extension = _ref$extension === void 0 ? null : _ref$extension,
      _ref$gps = _ref.gps,
      gps = _ref$gps === void 0 ? null : _ref$gps,
      _ref$is_scan = _ref.is_scan,
      is_scan = _ref$is_scan === void 0 ? null : _ref$is_scan;
  return {
    file_path: file_path,
    is_signature: is_signature,
    is_quotation: is_quotation,
    name: name,
    size: size,
    ts_deleted: ts_deleted,
    ts_inserted: ts_inserted,
    id: id,
    extension: extension,
    gps: gps,
    is_scan: is_scan
  };
};
/**
 * Hook to manage jobs
 * @returns {object} jobs data and functions
 */


var useJobs = function useJobs() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  if (!ctx) {
    throw Error('The `useJob` hook must be called from a descendent of the `DataProvider`.');
  }

  var _useNotifications = (0, _useNotifications2.useNotifications)(),
      addNotification = _useNotifications.add;
  /**
   * Reset a job status updating its status to 0
   * @param {integer} jobId job id in DB
   * @returns {object} the update result
   */


  var resetJob = function resetJob(jobId) {
    return _index["default"].firestore.collection('event').doc(jobId).update({
      status: 0
    });
  };
  /**
   * Generate a unique identifier for a job
   * @returns {string} new generated unique identifier for a job
   */


  var getNewId = function getNewId() {
    var _ctx$jobs = _slicedToArray(ctx.jobs, 1),
        jobList = _ctx$jobs[0];

    var lastEven = jobList.sort(function (e1, e2) {
      var _e2$ts_inserted, _e1$ts_inserted;

      return ((_e2$ts_inserted = e2.ts_inserted) !== null && _e2$ts_inserted !== void 0 ? _e2$ts_inserted : 0) - ((_e1$ts_inserted = e1.ts_inserted) !== null && _e1$ts_inserted !== void 0 ? _e1$ts_inserted : 0);
    })[0];
    var lastNo = lastEven === null || lastEven === void 0 ? void 0 : lastEven.intervention_no;
    var currentPrefix = "".concat((0, _getYear["default"])(Date.now()));
    if (!lastNo) return "".concat(currentPrefix, "-0001");

    var _lastNo$split = lastNo.split('-'),
        _lastNo$split2 = _slicedToArray(_lastNo$split, 2),
        lastNoPrefix = _lastNo$split2[0],
        lastNoId = _lastNo$split2[1];

    if (currentPrefix !== lastNoPrefix) return "".concat(currentPrefix, "-0001");
    return "".concat(currentPrefix, "-").concat("0000".concat(parseInt(lastNoId, 10) + 1).slice(-4));
  };
  /**
   * Start a job updating its status to 1
   * @param {integer} jobId job id in DB
   * @param {object} currentPosition user position when job starts (lat/lng)
   * @param {boolean} auto if true starts automatically
   * @returns {object} firebase update result
   */


  var startJob = function startJob(jobId, currentPosition, auto) {
    var _ctx$jobs2 = _slicedToArray(ctx.jobs, 1),
        jobList = _ctx$jobs2[0];

    var startedJob = jobList.filter(function (j) {
      return j.id !== jobId && j.status === 1;
    });
    var job = jobList.find(function (_ref2) {
      var id = _ref2.id;
      return id === jobId;
    });

    if (startedJob.length !== 0) {
      // eslint-disable-next-line no-throw-literal
      throw {
        code: 1,
        message: 'Job in progress'
      };
    } // eslint-disable-next-line no-throw-literal


    if (!currentPosition) throw {
      code: 2,
      message: 'no gps'
    };

    if (currentPosition && job.latitude && job.longitude) {
      var dist = (0, _geolocUtils.distance)(job.latitude, job.longitude, currentPosition.latitude, currentPosition.longitude) * 1000; // eslint-disable-next-line no-throw-literal

      if (dist > _constants.AREA_RADIUS) throw {
        code: 3,
        message: 'too far'
      };
    } //check is same day or not when auto and close enough


    if (auto) {
      var startDate = job.date_start.toDate();
      var currentDate = new Date();

      if (startDate.getFullYear() === currentDate.getFullYear() && startDate.getMonth() === currentDate.getMonth() && startDate.getDate() === currentDate.getDate()) {
        console.log('-----------');
        console.log('Start', startDate);
        console.log('End', currentDate);
        console.log('SAME DAY');
        console.log('-----------');
      } else {
        console.log('-----------');
        console.log('Start', startDate);
        console.log('End', currentDate);
        console.log('NOT SAME DAY');
        console.log('-----------');
        throw {
          code: 3,
          message: 'Event is tomorrow'
        };
      }
    }

    var duration = (0, _differenceInMinutes["default"])(job.date_end.toDate(), job.date_start.toDate());
    var newStart = new Date();
    var newEnd = (0, _addMinutes["default"])(newStart, duration);
    return _index["default"].firestore.collection('event').doc(jobId).update({
      status: 1,
      date_start: newStart,
      date_end: newEnd
    }).then(function () {
      addNotification({
        body: "Intervention ".concat(job.address_end, " (BT ").concat(job.lessor_document_no, ") d\xE9marr\xE9e ").concat(auto ? 'automatiquement' : 'manuellement'),
        ref: "event/".concat(jobId),
        ref_user: job.ref_user
      });
    });
  };
  /**
   * End a job status updating its status to 0 and updating the job data
   * @param {integer} jobId job id in DB
   * @param {object} data job data
   * @returns {object} firebase update result
   */


  var endJob = function endJob(jobId, data) {
    var _ctx$jobs3 = _slicedToArray(ctx.jobs, 1),
        jobList = _ctx$jobs3[0];

    var job = jobList.find(function (_ref3) {
      var id = _ref3.id;
      return id === jobId;
    });
    return _index["default"].firestore.collection('event').doc(jobId).update(_objectSpread(_objectSpread({}, data), {}, {
      status: 2
    })).then(function () {
      addNotification({
        body: "Intervention ".concat(job.address_end, " (BT ").concat(job.lessor_document_no, ") termin\xE9e"),
        ref: "event/".concat(jobId),
        ref_user: job.ref_user
      });
    });
  };
  /**
   * Insert or update (when existing) a job
   * @param {integer} id the job id in DB
   * @param {object} data job data
   * @returns {object} insert or update result
   */


  var upsert = function upsert(id, data) {
    // validate(data);
    var dataWithRefs = _objectSpread({}, data);

    if (dataWithRefs.medias) {
      dataWithRefs.medias = dataWithRefs.medias.map(function (m) {
        return formatMedia(m);
      });
    }

    if (data.ref_lessor && typeof data.ref_lessor === 'string') {
      dataWithRefs.ref_lessor = _index["default"].firestore.collection('lessor').doc(data.ref_lessor.split('/')[1]);
    }

    if (data.ref_user && typeof data.ref_user === 'string') {
      dataWithRefs.ref_user = _index["default"].firestore.collection('user').doc(data.ref_user.split('/')[1]);
    }

    var noNullData = dataWithRefs;
    Object.keys(noNullData).forEach(function (key) {
      return noNullData[key] === undefined && delete noNullData[key];
    });

    if (id) {
      return _index["default"].firestore.collection('event').doc(id).update(dataWithRefs);
    } // Send SMS  https://docs.ovh.com/fr/sms/envoyer_des_sms_depuis_une_url_-_http2sms/


    if (dataWithRefs.occupant_phone && isPhoneNumberValid(dataWithRefs.occupant_phone)) {
      try {
        var smsMessage = "https://www.ovh.com/cgi-bin/sms/http2sms.cgi?&account=sms-dg261624-1&login=SRVGAIA&password=Pw2q6kE3&from=FERRERO&to=DEST_KEY&message=Je vous confirme votre RDV de la société Ferrero plomberie du DATE_KEY, en cas d'empêchement veuillez appeler au 0491483447.";
        smsMessage = smsMessage.replace('DEST_KEY', formatPhoneNumber(dataWithRefs.occupant_phone));
        smsMessage = smsMessage.replace('DATE_KEY', formatDate(dataWithRefs.date_start));
        fetch(smsMessage); // https
        //   .get(smsMessage, (resp) => {
        //     console.log(`SUCCESS: ${resp}`);
        //   })
        //   .on('error', (err) => {
        //     console.log(`Error: ${err.message}`);
        //   });
      } catch (e) {
        console.log("Error: ".concat(e));
      }
    }

    return _index["default"].firestore.collection('event').add(_objectSpread(_objectSpread({}, noNullData), {}, {
      ts_inserted: Date.now()
    }));
    return _index["default"].firestore.collection('event').add(_objectSpread(_objectSpread({}, noNullData), {}, {
      ts_inserted: Date.now()
    }));
  };
  /**
   * Check if a phone number is valid
   * @param {string} phone phone number to check
   * @returns {boolean} true if phone passed as parameter is valid, else false
   */


  var isPhoneNumberValid = function isPhoneNumberValid(phone) {
    return phone.startsWith('06') || phone.startsWith('07') || phone.startsWith('+336') || phone.startsWith('+337') || phone.startsWith('00336') || phone.startsWith('00337');
  };
  /**
   * Format an unformatted phone number
   * @param {string} phone phone number to check
   * @returns {string} phone number formatted
   */


  var formatPhoneNumber = function formatPhoneNumber(phone) {
    var newPhoneNumber = phone;

    if (phone.startsWith('06') || phone.startsWith('07')) {
      newPhoneNumber = "0033".concat(newPhoneNumber.substring(1));
    } else if (phone.startsWith('+336') || phone.startsWith('+337')) {
      newPhoneNumber.replace('+33', '0033');
    }

    return newPhoneNumber;
  };
  /**
   * Format a date passed as parameter
   * @param {data} date the date to format
   * @returns {string} formatted date
   */


  var formatDate = function formatDate(date) {
    var dateStr = "".concat("00".concat(date.getDate()).slice(-2), "/").concat("00".concat(date.getMonth() + 1).slice(-2), "/").concat(date.getFullYear(), " \xE0 ").concat("00".concat(date.getHours()).slice(-2), "h").concat("00".concat(date.getMinutes()).slice(-2));
    return dateStr;
  };

  return {
    data: ctx.jobs,
    upsert: upsert,
    resetJob: resetJob,
    startJob: startJob,
    endJob: endJob,
    getNewId: getNewId
  };
};

exports.useJobs = useJobs;
var _default = useJobs;
exports["default"] = _default;
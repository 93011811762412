import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { Button, Dialog } from '@material-ui/core';


/**
 * React ConfirmationDialog component 
 * @param {object} param component mandatory parameters
 * @returns {render} ConfirmationDialog react component
 */
export function ConfirmationDialog({
  open, title, message, onConfirm, onDismiss,
}) {
  return (
    <Dialog className="confirm-dialog" open={ open } onClick={ onDismiss }>
      <div className="confirm-dialog-content">
        <p className="confirm-dialog-title">{ title }</p>
        <p className="confirm-dialog-subtitle">{ message }</p>
        <div className="confirm-dialog-buttons">
          <Button
            className="confirm-dialog-button-no"
            variant="contained"
            onClick={ onDismiss }
          >
            NON
          </Button>
          <Button
            className="confirm-dialog-button-yes"
            variant="contained"
            color="primary"
            onClick={ onConfirm }
          >
            OUI
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
};

export default ConfirmationDialog;

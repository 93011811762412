/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Fade, IconButton } from '@material-ui/core';
import { dataManager as dm } from '@ferrero/comon';
import { format, differenceInDays } from 'date-fns';
import TopMenu from '../../components/TopMenu';
import DataList from '../../components/ui/List';
import { useHolidays } from '../../hooks/useHolidays';
import NewHolidayButton from './NewHolidayButton';
import HolidayParamsButton from './HolidayParamsButton';
import LeaveValidator from '../../components/LeaveValidator';
import Picto from '../../components/ui/Picto';
import colors from '../../components/styles/colors.scss';
import { useConfirmationDialog } from '../../hooks/ConfirmDialog';
/**
 * Holidays react component
 * @returns Holidays react component
 */
function Holidays() {
  const [mounted, setMounted] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState();
  const [dataList, setDataList] = useState([]);
  const { getConfirmation } = useConfirmationDialog();

  const {
    data: [holiday],
    deleteHoliday,
  } = useHolidays();
  const {
    data: [users],
  } = dm.useUsers();

  useEffect(() => {
    const list = (holiday || []).map((curr) => ({
      id: `${curr.id}`,
      ref_user: `user/${curr.ref_user.id}`,
      userId: curr.ref_user.id,
      user_name: `${
        users.find((elt) => elt.id === curr.ref_user.id)?.first_name
      } ${users.find((elt) => elt.id === curr.ref_user.id)?.last_name}`,
      date_request_formatted: `${format(
        new Date(curr.date_request.seconds * 1000),
        'dd/MM/yyyy',
      )}`,
      date_request: curr.date_request,
      date_formatted: `${format(
        new Date(curr.date_start.seconds * 1000),
        'dd/MM/yyyy',
      )} ${format(new Date(curr.date_end.seconds * 1000), 'dd/MM/yyyy')}`,
      date_start: curr.date_start,
      date_end: curr.date_end,
      duration_formated: `${
        differenceInDays(curr.date_end.toDate(), curr.date_start.toDate()) + 1
      } jours`,
      accepted_date: curr.accepted_date,
      accepted_date_formated:
        curr.accepted_date
        && format(new Date(curr.accepted_date.seconds * 1000), 'dd/MM/yyyy'),
      reason: curr.reason,
      accepted: curr.accepted,
      refuse_reason: curr.refuse_reason,
    }));
    setDataList(list);
  }, [holiday, users]);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const onFilterChange = (f) => {
    setFilters(f);
  };

  useEffect(() => {
    if (filters != null) {
      const filtered = dataList.filter((elt) => {
        let isValid = true;
        if (filters.yes_no !== null && filters.yes_no !== 2) {
          isValid
            &= filters.yes_no === 1 ? elt.accepted === 1 : elt.accepted === 0;
        }
        if (filters.date_start !== null) {
          filters.date_start.setHours(0);
          filters.date_start.setMinutes(0);
          isValid
            &= filters.date_start.getTime() <= elt.date_request.seconds * 1000;
        }
        if (filters.date_end !== null) {
          filters.date_end.setHours(23);
          filters.date_end.setMinutes(59);
          isValid
            &= filters.date_end.getTime() >= elt.date_request.seconds * 1000;
        }
        if (filters.employee !== null && filters.employee !== '') {
          isValid &= filters.employee === elt.ref_user;
        }
        return isValid;
      });
      setFilteredList(filtered);
    } else setFilteredList(dataList);
  }, [filters, dataList]);

  /**
   * Delete holiday element
   * @param {object} row holiday row to delete 
   * @returns void
   */
  const handleDelete = async (row) => {
    const confirmed = await getConfirmation({
      title: 'Supprimer',
      message: `supprimer le congé de ${row.user_name} du ${format(
        row.date_start.toDate(),
        'dd/MM/yyyy',
      )} au ${format(row.date_end.toDate(), 'dd/MM/yyyy')} ?`,
    });
    if (!confirmed) return;
    deleteHoliday(row);
  };

  const renderStatus = (_, row) => (
    <>
      <LeaveValidator
        holiday={ row }
        style={ {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        } }
      />
      <IconButton
        disabled={ !row.accepted || row.date_start.toDate() < Date.now() }
        onClick={ () => handleDelete(row) }
      >
        <Picto
          size={ 30 }
          type="icTrash"
          fill={ colors.red }
          style={ {
            opacity:
              !row.accepted || row.date_start.toDate() < Date.now() ? 0.2 : 1,
          } }
        />
      </IconButton>
    </>
  );

  return (
    <Fade in={ mounted }>
      <div className="page holidays-page">
        <TopMenu
          title="Demande de congés"
          subtitle="Liste des demandes de congés des employés"
          yesnoTitle="Acceptée"
          onFilterChange={ onFilterChange }
        >
          <div className="top-menu-filters-filter">
            <div className="top-menu-filters-label"> </div>
            <NewHolidayButton />
          </div>
          <div className="top-menu-filters-filter">
            <div className="top-menu-filters-label"> </div>
            <HolidayParamsButton />
          </div>
        </TopMenu>
        <div className="page-content">
          <DataList
            data={ filteredList }
            className="holidays-list"
            columns={ [
              {
                title: 'Employé',
                key: 'user_name',
                style: { flex: 1 },
                dataType: 'user',
              },
              {
                title: 'Date demande',
                key: 'date_request_formatted',
                picto: 'icCalendar',
                style: { width: isMobile ? 250 : 160 },
              },
              {
                title: 'Date congé',
                key: 'date_formatted',
                picto: 'icCalendar',
                style: { width: isMobile ? 250 : 150 },
              },
              {
                title: 'Durée',
                key: 'duration_formated',
                picto: 'icTime',
                style: { width: 150 },
              },
              {
                title: 'Motif',
                key: 'reason',
                picto: 'icLabel',
                style: { width: isMobile ? 250 : 150 },
              },
              {
                title: 'Accepté',
                key: 'accepted',
                style: { width: isMobile ? 295 : 260 },
                render: renderStatus,
              },
            ] }
          />
        </div>
      </div>
    </Fade>
  );
}

Holidays.propTypes = {};

export default Holidays;

/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Fade, IconButton } from '@material-ui/core';
import { dataManager as dm, constants } from '@ferrero/comon';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Picto from '../components/ui/Picto';
import './Accounts.scss';
import colors from '../components/styles/colors.scss';
import PageHeader from '../components/PageHeader';
import { Button } from '../components/ui';
import DataList from '../components/ui/List';
import { useSnack } from '../hooks/useSnack';
import { useConfirmationDialog } from '../hooks/ConfirmDialog';

/**
 * Accounts react component
 * @returns Accounts react component
 */
function Accounts() {
  const [mounted, setMounted] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const { user: currentUser } = dm.useAuth();
  const history = useHistory();
  const {
    data: [users],
    upsert,
  } = dm.useUsers();
  const { addSnack } = useSnack();
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    setAccountList(
      (users || []).map((u) => ({
        id: u.id,
        fullName: `${u.first_name} ${u.last_name}`,
        statusLabel: constants.ACCOUNT_STATUS[u.status || 0]?.label,
        accountTypeLabel: constants.ACCOUNT_TYPE[u.account_type]?.label,
        daysOffCount: u.days_off_count || 0,
        daysOfLeaveTaken: u.days_of_leave_taken || 0,
        daysOfBalance: (u.days_off_count || 0) - (u.days_of_leave_taken || 0),
        color: u.color,
      })),
    );
  }, [users]);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  const handleEdit = (id) => {
    history.push(`/app/accounts/${id}`);
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  /**
   * Delete a user account
   * @param {integer} id account id  
   * @returns void
   */
  const handleDelete = async (id) => {
    const confirmed = await getConfirmation({
      title: 'Supprimer compte!',
      message: 'Voulez-vous vraiment supprimer ce compte ?',
    });

    if (!confirmed) return;

    try {
      await upsert(id, { ts_deleted: new Date() });
      addSnack({
        message: 'utilisateur supprimé',
        severity: 'success',
      });
    } catch (e) {
      addSnack({
        message: 'erreur',
        severity: 'error',
      });
    }
  };

  const renderAction = (cellData, row) => (
    <>
      <IconButton onClick={ () => handleEdit(row.id) }>
        <EditIcon size={ 30 } style={ { color: colors.blueDark } } />
      </IconButton>
      <IconButton
        disabled={ row.id === currentUser.id }
        onClick={ () => handleDelete(row.id) }
      >
        <Picto
          size={ 30 }
          type="icTrash"
          fill={ colors.red }
          style={ { opacity: row.id === currentUser.id ? 0.2 : 1 } }
        />
      </IconButton>
    </>
  );

  return (
    <Fade in={ mounted }>
      <div className="page account-page">
        <PageHeader
          title="Comptes utilisateurs"
          subtitle="Liste des comptes utilisateurs"
        >
          <Button
            className="accounts-add-button"
            color="primary"
            variant="contained"
            onClick={ () => history.push('/app/accounts/new') }
          >
            Nouveau
          </Button>
        </PageHeader>
        <div className="page-content">
          <DataList
            data={ accountList }
            className="accounts-list"
            columns={ [
              {
                title: 'Employé',
                key: 'fullName',
                style: { flex: 1 },
                dataType: 'user',
              },
              {
                title: 'Etat',
                key: 'statusLabel',
                style: { width: isMobile ? 200 : 120 },
              },
              {
                title: 'Type de compte',
                key: 'accountTypeLabel',
                style: { width: isMobile ? 250 : 150 },
              },
              {
                title: 'Solde congés',
                key: 'daysOfBalance',
                style: {
                  width: isMobile ? 200 : 150,
                  justifyContent: isMobile ? 'flex-start' : 'center',
                },
              },
              {
                title: 'Couleur',
                key: 'color',
                style: {
                  width: isMobile ? 200 : 100,
                  justifyContent: isMobile ? 'flex-start' : 'center',
                },
                dataType: 'color',
              },
              {
                title: 'Actions',
                style: { width: isMobile ? 200 : 100 },
                render: renderAction,
              },
            ] }
          />
        </div>
      </div>
    </Fade>
  );
}

Accounts.propTypes = {};

export default Accounts;

import React from 'react';
import PropTypes from 'prop-types';
import Media from './Media';

/**
 * MediaManagment react component
 * @param {object} MediaManagment parameters
 * @returns MediaManagment react component
 */
function MediaManagment({ medias, onChange, showFullSize }) {
  const mediaClicked = (media) => {
    showFullSize(media);
  };

  return (
    <div className="editEvent-tab-container event-media-gallery">
      { medias
        && medias
          .filter(
            (media) => media.ts_deleted === undefined
              || media.ts_deleted === null
              || media.ts_deleted === '',
          )
          .map((media) => (
            <Media
              key={ media.id || media.internalId }
              media={ media }
              onClick={ () => mediaClicked(media) }
              onDelete={ () => onChange(media) }
            />
          )) }
    </div>
  );
}

MediaManagment.propTypes = {
  medias: PropTypes.array,
  onChange: PropTypes.func,
  showFullSize: PropTypes.func,
};

export default MediaManagment;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.AREA_RADIUS = exports.ACCOUNT_TYPE = exports.ACCOUNT_STATUS = exports.EVENT_TYPE = exports.HOLIDAY_STATUS = exports.JOB_STATUS = void 0;
var JOB_STATUS = {
  0: {
    label: 'A venir',
    color: '#b3b3b3'
  },
  1: {
    label: 'En cours',
    color: '#FFA726'
  },
  2: {
    label: 'Fini',
    color: '#00AA8D'
  }
};
exports.JOB_STATUS = JOB_STATUS;
var HOLIDAY_STATUS = {
  '-1': {
    label: 'En Attente',
    color: '#b3b3b3'
  },
  0: {
    label: 'Refusé',
    color: '#FFA726'
  },
  1: {
    label: 'Accepté',
    color: '#00AA8D'
  }
};
exports.HOLIDAY_STATUS = HOLIDAY_STATUS;
var EVENT_TYPE = {
  job: 'job',
  holiday: 'holiday'
};
exports.EVENT_TYPE = EVENT_TYPE;
var ACCOUNT_STATUS = {
  0: {
    label: 'SUSPENDU'
  },
  1: {
    label: 'ACTIF'
  }
};
exports.ACCOUNT_STATUS = ACCOUNT_STATUS;
var ACCOUNT_TYPE = {
  0: {
    label: 'STANDARD'
  },
  1: {
    label: 'ADMINISTRATEUR'
  }
};
exports.ACCOUNT_TYPE = ACCOUNT_TYPE;
var AREA_RADIUS = 300;
exports.AREA_RADIUS = AREA_RADIUS;
var _default = JOB_STATUS;
exports["default"] = _default;
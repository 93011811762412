import React from 'react';
import PropTypes from 'prop-types';
import { useHolidays } from '../../hooks/useHolidays';
import LeaveValidationModal from './LeaveValidationModal';
import ButtonWithreason from '../ButtonWithreason';
import ButtonWithModal from '../ButtonWithModal';
import colors from '../styles/colors.scss';

/**
 * LeaveValidator react component
 * @param {object} LeaveValidator parameters
 * @returns LeaveValidator react component
 */
function LeaveValidator({ style, holiday }) {
  const {
    data: [list],
    acceptHoliday,
  } = useHolidays();

  /**
   * Updates the accepted holidays 
   * @param {integer} daysCount number of accepted holidays
   */
  const accept = (daysCount) => {
    const cur = list.find((elt) => elt.id === holiday.id);
    acceptHoliday(cur, 1, null, daysCount);
  };
  /**
   * Refuse a hoiliday
   * @param {string} reason the reason  
   */
  const refuse = (reason) => {
    const cur = list.find((elt) => elt.id === holiday.id);
    acceptHoliday(cur, 0, reason);
  };
  const disabled = (holiday.accepted ?? -1) >= 0;
  return (
    <div style={ style }>
      <ButtonWithModal
        onClick={ accept }
        DialogContent={ (props) => (
          <LeaveValidationModal { ...props } holiday={ holiday } />
        ) }
        disabled={ disabled }
        style={ {
          backgroundColor: holiday.accepted === 1 ? colors.green : colors.white,
          color: holiday.accepted === 1 ? colors.white : colors.black,
        } }
        variant="contained"
      >
        OUI
      </ButtonWithModal>
      <ButtonWithreason
        className="addhours-row-item-btn-no"
        disabled={ disabled }
        style={ {
          backgroundColor: holiday.accepted === 0 ? colors.red : colors.white,
          color: holiday.accepted === 0 ? colors.white : colors.black,
        } }
        variant="contained"
        onClick={ refuse }
        title="Refuser la demande de congé"
        value={ holiday.refuse_reason }
      >
        NON
      </ButtonWithreason>
    </div>
  );
}

LeaveValidator.propTypes = {
  style: PropTypes.object,
  holiday: PropTypes.object,
};

export default LeaveValidator;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { pdfjs, Document, Page } from 'react-pdf';
import { CircularProgress, useTheme } from '@material-ui/core';

import Picto from '../ui/Picto';
import { useSnack } from '../../hooks/useSnack';
import colors from '../../styles/colors.scss';
import './styles.scss';
import { parse, processOcrPDF } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * PdfParser react component
 * @param {object} PdfParser parameters
 * @returns PdfParser react component
 */
function PdfParser({ onComplete, style, disabled }) {
  const theme = useTheme();
  const [isAddingFile, setIsAddingFile] = useState(false);
  // const [file, setFile] = useState();
  // const [doc, setDoc] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [text, setText] = useState([]);
  // const [decodeComplete, setDecodeComplete] = useState([]);
  const { addSnack } = useSnack();

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsAddingFile(true);
    try {
      const file = acceptedFiles[0];
      const txt = await processOcrPDF(file);
      const parsed = parse(txt);
      if (onComplete) onComplete(parsed, file);
    } finally {
      setIsAddingFile(false);
    }

    // setCurrentPage(1);
    // setText([]);
    // setDecodeComplete(false);
    // console.log(acceptedFiles[0]);
    // setFile(acceptedFiles[0]);
  }, []);

  // const parseText = useCallback(() => {
  //   setDecodeComplete(false);
  //   let lastX = 0;
  //   let lastLeft = 0;
  //   const fullText = text
  //     .map((i) => {
  //       const [, , , , x] = i.transform || [];
  //       const { str } = i;
  //       const nexLine = x < lastX;
  //       const tab = x - lastLeft > 50;
  //       lastX = x;
  //       lastLeft = x + i.width;
  //       return (nexLine ? '\n' : '') + (tab ? '\t' : '') + str;
  //     })
  //     .join('');
  //   const parsed = parse(fullText);

  //   setIsAddingFile(false);
  //   if (onComplete) onComplete(parsed, file);
  // }, [text, onComplete]);

  // useEffect(() => {
  //   if (decodeComplete) parseText();
  // }, [decodeComplete, parseText]);

  // const documentLoaded = useCallback((pdf) => {
  //   setText([]);
  //   console.log('PDF', pdf);
  //   setDoc(pdf);
  // }, []);

  // const textSuccess = useCallback(
  //   (items) => {
  //     processOcrPDF(file).then((t) => {

  //     });
  //     setText((cur) => {
  //       const newText = [...cur, { str: '-\n-----PAGE------\n' }, ...items];
  //       return newText;
  //     });
  //     if (currentPage < doc.numPages) setCurrentPage((cur) => cur + 1);
  //     else setDecodeComplete(true);
  //   },
  //   [doc, currentPage]
  // );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: '.pdf',
  });

  const appliedStyle = {
    borderRadius: theme.shape.borderRadius,
    backgroundcolor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...style,
  };

  const handleMouseDown = () => {
    if (disabled) {
      addSnack({
        message: 'Veuillez choisir le bailleur 13 Habitat.',
        severity: 'warning',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        {...getRootProps()}
        className="pdfParser-container"
        style={appliedStyle}
        onMouseDown={() => handleMouseDown()}
      >
        <input
          className="pdfParser-input"
          disabled={isAddingFile || disabled}
          {...getInputProps()}
        />
        {isAddingFile ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Picto
              size={36}
              type="icDragAndDrop"
              color={isDragActive ? colors.blue : colors.white}
            />
            <span>Scan BT</span>
          </>
        )}
      </div>
      {/* <Document
        className="pdf-doc"
        file={file}
        renderMode="none"
        noData=""
        loading=""
        onLoadSuccess={documentLoaded}
      >
        <Page pageNumber={currentPage} onGetTextSuccess={textSuccess} />
      </Document> */}
    </>
  );
}

PdfParser.propTypes = {
  onComplete: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default PdfParser;

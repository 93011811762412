"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useParams = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Provider = require("../Provider");

var _index = _interopRequireDefault(require("../index"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/* eslint-disable import/no-unresolved */

/* eslint-disable import/no-cycle */
// eslint-disable-next-line no-unused-vars

/**
 * Hook to manage parameters
 * @returns {object} parameters data and functions
 */
var useParams = function useParams() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  if (!ctx) {
    throw Error('The `useParams` hook must be called from a descendent of the `DataProvider`.');
  }
  /**
   * Update a parameter value
   * @param {object} data parameter value 
   * @returns {object} firebase update result
   */


  var upsert = function upsert(data) {
    return _index["default"].firestore.collection('params').doc(data.id).set(data);
  };

  return {
    data: ctx.params,
    upsert: upsert
  };
};

exports.useParams = useParams;
var _default = useParams;
exports["default"] = _default;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, TextField } from '../ui';
import Media from './Media';
import './Quitus.scss';

/**
 * Quitus react component
 * @param {object} Quitus parameters
 * @returns Quitus react component
 */
function Quitus({ values, onChange, showFullSize }) {
  const [resolved, setResolved] = useState(!!values?.resolved);
  const [quotationDetail, setQuotationDetail] = useState(
    values?.quotationDetail || '',
  );
  const [quotationMedias, setQuotationMedias] = useState(
    values?.medias?.filter((m) => m.is_quotation) || [],
  );

  useEffect(() => {
    setQuotationMedias(values?.medias?.filter((m) => m.is_quotation) || []);
    setResolved(!!values?.resolved);
    setQuotationDetail(values?.quotationDetail || '');
  }, [values]);

  useEffect(() => {
    onChange({ ...values, resolved, quotationDetail });
  }, [resolved, quotationDetail]);
  return (
    <div className="quitus-container">
      <div className="quitus-column">
        <Switch
          checked={ resolved }
          onChange={ ({ target: { checked } }) => setResolved(checked) }
          label={ values?.resolved ? 'Problème résolu' : 'Problème non résolu' }
        />
        <TextField
          id="report"
          multiline
          rows={ 5 }
          variant="filled"
          label="Description du nouveau devis"
          fullWidth
          onChange={ setQuotationDetail }
          value={ quotationDetail }
        />
      </div>
      <div className="quitus-column">
        <div className="quitus-medias-list">
          { quotationMedias?.map((media) => (
            <Media
              key={ media.id }
              media={ media }
              onClick={ () => showFullSize(media) }
              onDelete={ () => {
                onChange({
                  ...values,
                  medias: values?.medias?.filter((m) => m.id !== media.id),
                });
              } }
            />
          )) }
        </div>
      </div>
    </div>
  );
}

Quitus.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  showFullSize: PropTypes.func,
};

export default Quitus;

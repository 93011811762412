"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useNotifications = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Provider = require("../Provider");

var _index = _interopRequireDefault(require("../index"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/* eslint-disable import/no-cycle */
// eslint-disable-next-line no-unused-vars, import/no-unresolved
// eslint-disable-next-line import/no-cycle

/**
 * Get the formated reference user
 * @param {blob} ref the reference user to format
 * @returns {object} firebase object formatted reference user
 */
var formatRef = function formatRef(ref) {
  if (!ref) return null;

  if (typeof ref === 'string') {
    return _index["default"].firestore.collection(ref.split('/')[0]).doc(ref.split('/')[1]);
  }

  return ref;
};
/**
 * Hook to manage notifications
 * @returns {object} notifications data and functions
 */


var useNotifications = function useNotifications() {
  var ctx = (0, _react.useContext)(_Provider.DataContext);

  if (!ctx) {
    throw Error('The `useNotifications` hook must be called from a descendent of the `DataProvider`.');
  }

  var unReadCount = (ctx.notifications[0] || []).filter(function (_ref) {
    var read = _ref.read;
    return !read;
  }).length;
  /**
   * Insert a new notification
   * @param {object} notification data to add
   * @returns {object} firebase insert result
   */

  var add = function add(notification) {
    var data = {
      ref_user: formatRef(notification.ref_user),
      body: notification.body,
      read: false,
      ref: formatRef(notification.ref),
      date: new Date()
    };
    return _index["default"].firestore.collection('notification').add(data);
  };
  /**
   * Updates the "read" status to a notification
   * @param {integer} id the notification id in firebase DB 
   * @returns {object} firebase update result
   */


  var setRead = function setRead(_ref2) {
    var id = _ref2.id;
    return _index["default"].firestore.collection('notification').doc(id).update({
      read: true
    });
  };

  return {
    data: ctx.notifications,
    add: add,
    unReadCount: unReadCount,
    setRead: setRead
  };
};

exports.useNotifications = useNotifications;
var _default = useNotifications;
exports["default"] = _default;
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { dataManager } from '@ferrero/comon';
import { Zoom } from '@material-ui/core';
import { Loader } from '../../components/ui';
import ErrorBadCode from './Error';

import logo from '../../assets/logo.svg';
import '../Login/Login.scss';
import ResetPassword from './ResetPassword';

/**
 * ActionHandler react component
 * @param {props} ActionHandler props
 * @returns ActionHandler react component
 */
function ActionHandler(props) {
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [action, setAction] = useState(false);
  const [mode, setMode] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setMode(params.get('mode'));
    setCode(params.get('oobCode'));
    setMounted(true);
  }, [location]);

  useEffect(() => {
    if (code) {
      dataManager.firebaseAuth
        .verifyPasswordResetCode(code)
        .then((mail) => {
          setEmail(mail);
          setAction(mode);
        })
        .catch(() => {
          setAction('errorBadCode');
        });
    }
  }, [code, mode]);

  const renderContent = () => {
    if (action === 'errorBadCode') return <ErrorBadCode />;
    if (action === 'resetPassword') {
      return <ResetPassword code={ code } email={ email } />;
    }
    return null;
  };

  return (
    <div className="page login-page">
      <img alt="logo" className="login-logo" src={ logo } />
      <Zoom in={ mounted }>
        <div className="login-card-container">{ renderContent() }</div>
      </Zoom>
    </div>
  );
}

ActionHandler.propTypes = {};

export default ActionHandler;

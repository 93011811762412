import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import colors from "../../styles/colors.scss";
import "./styles.scss";
import Picto from "../ui/Picto";
/**
 * ImportReferential react component
 * @param {object} ImportReferential parameters
 * @returns ImportReferential react component
 */
const ImportReferential = ({ onAddXLS, isActivated = true }) => {
  const [isAddingFile, setIsAddingFile] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsAddingFile(acceptedFiles.length > 0);
      if (!isActivated) return;
      const f = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = async (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (onAddXLS) await onAddXLS(data);
        setIsAddingFile(false);
      };
      reader.readAsBinaryString(f);
    },
    [onAddXLS, isActivated]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "text/csv, .xls, .xlsx, .ods",
  });

  return (
    <div {...getRootProps()} className="add-xls-container">
      <input
        className="add-xls-dropzone-input"
        {...getInputProps()}
        disabled={!onAddXLS}
      />
      <div id="add-xls-add-icon">
        {!isAddingFile ? (
          <div className="global-center">
            <Picto
              size={90}
              type="icDragAndDrop"
              color={isDragActive ? colors.blue : colors.white}
            />
            <p>Déposer le fichier excel</p>
          </div>
        ) : (
          <div>Mise à jour du référentiel produit...</div>
        )}
      </div>
    </div>
  );
};

ImportReferential.propTypes = {
  onAddXLS: PropTypes.func.isRequired,
  isActivated: PropTypes.bool,
};

export default ImportReferential;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dataManager as dm } from '@ferrero/comon';
import { differenceInDays } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { useHolidays } from '../hooks/useHolidays';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '&> div': {
      flex: 1,
      padding: '1rem',
      textAlign: 'center',
    },
  },
  listContainer: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '&> div': {
      flex: 1,
      padding: '1rem',
    },
  },
}));

/**
 * LeaveSumary react component
 * @param {object} LeaveSumary parameters
 * @returns LeaveSumary react component
 */
function LeaveSumary({ style, userId }) {
  const classes = useStyles();
  const [sumary, setSumary] = useState({});
  const {
    data: [holidays],
  } = useHolidays();

  const {
    data: [params],
  } = dm.useParams();

  useEffect(() => {
    const aggregated = holidays
      .filter((h) => h.accepted && h.ref_user.id === userId)
      .reduce((acc, cur) => {
        const duration = differenceInDays(cur.date_end.toDate(), cur.date_start.toDate()) + 1;
        return {
          ...acc,
          [cur.reason]: (acc[cur.reason] ?? 0) + duration,
        };
      }, {});
    const formated = params.vacancy.leave_reason.reduce(
      (acc, cur) => ({ ...acc, [cur.label]: 0 }),
      {},
    );
    setSumary({ ...formated, ...aggregated });
  }, [holidays, userId]);
  return (
    <div style={ style } className={ classes.container }>
      <div className={ classes.header }>
        <div>Type</div>
        <div>Nombre de jours pris</div>
      </div>
      <div className={ classes.listContainer }>
        { Object.entries(sumary).map(([key, value]) => (
          <div className={ classes.row }>
            <div>{ key }</div>
            <div>{ value }</div>
          </div>
        )) }
      </div>
    </div>
  );
}

LeaveSumary.propTypes = {
  userId: PropTypes.string,
  style: PropTypes.object,
};

export default LeaveSumary;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import colors from '../styles/colors.scss';

import { TextField } from '../ui';
import './FurnirureManagment.scss';

const useStyles = makeStyles(() => ({
  input: {
    margin: 0,
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: 6,
    },
  },
}));

/**
 * Furniture managment row react component
 * @param {object} Furniture managment row parameters
 * @returns Furniture managment row react component
 */
const Row = ({ data = {}, onChange, onDelete }) => {
  const [description, setDescription] = useState(data.description || '');
  const [amount, setAmount] = useState(data.amount || '');
  const classes = useStyles();
  useEffect(() => {
    setDescription(data.description || '');
    setAmount(data.amount || '');
  }, [data]);

  /**
   * Callback when furniture managment row changes 
   * @param {object} updatedData the new row data
   * @returns callback function call
   */
  const handleChange = (updatedData) => onChange(updatedData);

  /**
   * Callback when furniture managment row description changes 
   * @param {string} val the new row description value
   */
  const handleChangeDescription = (val) => {
    setDescription(val);
    handleChange({ description: val, amount });
  };

  /**
   * Callback when furniture managment row amount changes 
   * @param {string} val the new row description amount
   */
  const handleChangeAmount = (val) => {
    setAmount(val);
    handleChange({ description, amount: val });
  };
  return (
    <div className="row">
      <TextField
        variant="filled"
        size="small"
        value={ description }
        onChange={ handleChangeDescription }
        className={ classes.input }
      />
      <TextField
        variant="filled"
        type="number"
        size="small"
        InputProps={ {
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        } }
        value={ amount }
        onChange={ handleChangeAmount }
        className={ classes.input }
      />
      <IconButton
        onClick={ onDelete }
        aria-label="delete"
        size="small"
        style={ {
          color: colors.red,
        } }
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

Row.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Furniture managment react component
 * @param {object} Furniture managment parameters
 * @returns Furniture managment react component
 */
function FurnitureManagment({ furnitures = [], onChange }) {
  const handleChange = (index) => (data) => {
    const newData = [...furnitures];
    newData[index] = data;
    onChange(newData);
  };

  /**
   * Callback when element is deleted
   * @param {integer} index index of the element to delete
   */
  const handleDelete = (index) => {
    const newData = [...furnitures];
    onChange(newData.filter((e, idx) => idx !== index));
  };
  return (
    <div className="furnitureContainer">
      <div className="furnitureList">
        <div className="furnitureListHeader">
          <div>Détail</div>
          <div>Montant TTC</div>
          <div style={ { width: 30, maxWidth: 30 } } />
        </div>
        { furnitures.map((row, index) => (
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={ index }
            data={ row }
            onChange={ handleChange(index) }
            onDelete={ () => handleDelete(index) }
          />
        )) }
      </div>
    </div>
  );
}

FurnitureManagment.propTypes = {
  furnitures: PropTypes.array,
  onChange: PropTypes.func,
};

export default FurnitureManagment;

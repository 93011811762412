import React from 'react';
import { IconButton as MatIconButton } from '@material-ui/core';

/**
 * IconButton react component
 * @param {props} IconButton props
 * @returns IconButton react component
 */
function IconButton(props) {
  return (
    <MatIconButton { ...props } />
  );
}

IconButton.propTypes = {

};

export default IconButton;

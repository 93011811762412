import React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import Referential from './pages/Referential';
import Planning from './pages/Planning';
import Expense from './pages/Expense';
import Holidays from './pages/Holidays';
import AdditionalHours from './pages/AdditionalHours';
import Accounts from './pages/Accounts';
import Notifications from './pages/Notifications';
import Account from './pages/Account';
import Menu from './components/Menu';
import VacationDetails from './pages/Account/VacationDetails';
import Configuration from './pages/Configuration';

/**
 * Route mapping for the entire application
 * @returns {render} Menu with its routes to the different pages
 */
function AppLayout() {
  const { path } = useRouteMatch();

  return (
    <>
      <Menu />
      <Route path={ `${path}/referential` }>
        <Referential />
      </Route>
      <Route path={ `${path}/planning` }>
        <Planning />
      </Route>
      <Route path={ `${path}/notifications` }>
        <Notifications />
      </Route>
      <Route path={ `${path}/expense` }>
        <Expense />
      </Route>
      <Route path={ `${path}/holidays` }>
        <Holidays />
      </Route>
      <Route path={ `${path}/extra_hours` }>
        <AdditionalHours />
      </Route>
      <Route exact path={ `${path}/accounts` }>
        <Accounts />
      </Route>
      <Route exact path={ `${path}/configuration` }>
        <Configuration />
      </Route>
      <Route path={ `${path}/accounts/:id` } exact>
        <Account />
      </Route>
      <Route path={ `${path}/accounts/:id/vacations` }>
        <VacationDetails />
      </Route>
      <Route path={ path } exact>
        <Redirect to={ `${path}/referential` } />
      </Route>
    </>
  );
}

AppLayout.propTypes = {};

export default AppLayout;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Get the default state object
 * @param {integer} defaultValue 
 * @returns {object} default state object
 */
var defaultState = function defaultState(defaultValue) {
  return {
    loading: defaultValue === undefined || defaultValue === null,
    value: defaultValue
  };
};
/**
 * Reduce a state with its action
 * @returns {object} reduced state data
 */


var reducer = function reducer() {
  return function (state, action) {
    switch (action.type) {
      case 'error':
        return _objectSpread(_objectSpread({}, state), {}, {
          error: action.error,
          loading: false,
          value: undefined
        });

      case 'reset':
        return defaultState(action.defaultValue);

      case 'value':
        return _objectSpread(_objectSpread({}, state), {}, {
          error: undefined,
          loading: false,
          value: action.value
        });

      default:
        return state;
    }
  };
};
/**
 * Hook to manage default loading values
 * @returns {object} default loading values data and functions
 */


var _default = function _default(getDefaultValue) {
  var defaultValue = getDefaultValue ? getDefaultValue() : undefined;

  var _useReducer = (0, _react.useReducer)(reducer(), defaultState(defaultValue)),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];
  /**
   * Reser loading value
   */


  var reset = function reset() {
    var defaultValue = getDefaultValue ? getDefaultValue() : undefined;
    dispatch({
      type: 'reset',
      defaultValue: defaultValue
    });
  };
  /**
   * Set loading value error
   * @param {*} error current error
   */


  var setError = function setError(error) {
    dispatch({
      type: 'error',
      error: error
    });
  };
  /**
   * Set loading value value
   * @param {*} value current value
   */


  var setValue = function setValue(value) {
    dispatch({
      type: 'value',
      value: value
    });
  };

  return (0, _react.useMemo)(function () {
    return {
      error: state.error,
      loading: state.loading,
      reset: reset,
      setError: setError,
      setValue: setValue,
      value: state.value
    };
  }, [state.error, state.loading, reset, setError, setValue, state.value]);
};

exports["default"] = _default;